(function() {
  'use strict';

  angular
    .module('medsquire.interns')
    .config(internsTermsRoutes);

  function internsTermsRoutes($stateProvider, $urlRouterProvider) {
    $stateProvider.state('intterms', {
      url: '/intterms',
      cache: false,
      templateUrl: 'interns/terms/terms.html',
      controller: 'InternsTermsController',
      controllerAs: 'vm'
    })
  }
})();
