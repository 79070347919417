(function () {
  'use strict';

  angular
    .module('medsquire.auth.confirm')
    .controller('AuthConfirmController', AuthConfirmController);

  /* @ngInject */
  function AuthConfirmController($state, $sessionStorage, FirstRunService, MdsqreAuth, $ionicPopup, $log, blockUI) {
    var vm = this;
    vm.title = 'Confirme seu Cadastro';

    vm.user = {};

    vm.login = login;

    activate();

    ////////////////

    function activate() {
      vm.user = {};
    }

    function login() {
      if (vm.aceitoTermos) {
        blockUI.start({
          message: 'Autenticando...'
        });
        var credentials = {
          email: vm.user.email,
          password: vm.user.password,
          confirm_tos: true
        }
        MdsqreAuth.confirm(credentials)
          .then(function (response) {
            FirstRunService.setup();
            $state.go('*.home');
          })
          .catch(function (error) {
            $ionicPopup.alert({
              title: 'Email e/ou senha incorretos',
              template: "Verifique o email e a senha digitados e tente novamente"
            });
            $log.log(error);
          })
          .finally(function () {
            blockUI.stop();
          });
      }
    }
  }
})();
