(function() {
    'use strict';

    angular
        .module('medsquire.queue')
        .factory('TaskQueueService', TaskQueueService);

    /* @ngInject */
    function TaskQueueService($queueFactory, $log) {

        var TaskQueueService = {
          _queue: null
        };

        TaskQueueService.init = init;
        TaskQueueService.add = add;

        return TaskQueueService;

        ////////////////

        function init() {
          this._queue = $queueFactory(3, true); // three concurrent jobs with deffered;
          $log.log(this._queue);
        }

        function add(task) {
          this._queue.enqueue(task);
          $log.warn(this._queue);
        }
    }
})();
