(function() {
    'use strict';

    angular
        .module('medsquire.settings.help')
        .controller('HelpController', HelpController);

    /* @ngInject */
    function HelpController() {
        var vm = this;
        vm.title = 'Ajuda';

        activate();

        ////////////////

        function activate() {
        }
    }
})();
