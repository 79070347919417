(function() {
    'use strict';

    angular
        .module('medsquire.medics')
        .factory('MedicModel', MedicModel);

    /* @ngInject */
    function MedicModel(_Model) {

        return MedicModel;

        ////////////////

        function MedicModel(medic) {
          _Model.call(this);
          this._medic = medic;

          this.setName = function(nameObject) {
            this.firstName = nameObject.first;
            this.lastName = nameObject.last;
          }

          this.setPrefix = function(prefix) {
            this.prefix = prefix;
          }

          this.setDob = function(date) {
            this.dob = moment(date);
          }

          this.setEmail = function(email) {
            this.email = email;
          }

          this.setCpf = function(cpf) {
            this.cpf = cpf;
          }

          this.setCnpj = function(cnpj) {
            this.cnpj = cnpj;
          }

          this.setCrm = function(crm) {
            this.crm = crm;
          }

          this.setPhone = function(phone) {
            this.phone = phone;
          }

          this.setNotify = function(notify) {
            this.pushNotify = notify.push;
            this.emailNotify = notify.email;
          }

          this.setDevices = function(devices) {
            this.devices = devices;
          }

          this.setBadges = function(badges) {
            this.badges = badges;
          }

          this.setLastLogin = function(time) {
            this.lastLogin = moment(time);
          }

          this.setAddress = function(addressObject) {
            var address = {
              street: addressObject.street,
              number: addressObject.number,
              district: addressObject.district,
              complement: addressObject.complement,
              zip: addressObject.zip,
              state: addressObject.state,
              country: addressObject.country
            }
            this.address = address;
          }

          this.setManagerId = function(managerId) {
            this.managerId = managerId;
          }

          this.getFullName = function() {
            return this.firstName + ' ' + this.lastName;
          }


          this.api = function() {
            if (typeof this._medic === 'undefined') {
              $log.error('must set user property');
              return;
            }
            var user = this._medic;
            this.setId(user.id);
            this.setUuid(user.uuid);
            this.setName({ first: user.nome, last: user.sobrenome });
            this.setPrefix(user.tratamento);
            this.setEmail(user.email);
            this.setPhone(user.telefone1);
            this.setNotify({ push: user.pushNotify, email: user.emailNotify });
            this.setManagerId(user.managerID);
            this.setLastLogin(user.lastLogin);
            this.setAddress({
              street: user.endereco.rua,
              number: user.endereco.numero,
              district: user.endereco.bairro,
              complement: user.endereco.complemento,
              zip: user.endereco.cep,
              state: user.endereco.estado,
              country: user.endereco.pais
            });
            this.setDevices(user.devices);
            this.setDob(user.dataNascimento);
            this.setCrm(user.crm);
            this.setCreated(user.createdDate);
            this.setCpf(user.cpf);
            this.setCnpj(user.cnpj);
            this.setBadges(user.badges);
          }

          this.local = function() {
            if (typeof this._medic === 'undefined') {
              $log.error('must set user property');
              return;
            }
            var user = this._medic;
            this.setId(user.id);
            this.setUuid(user.uuid);
            this.setName({ first: user.firstName, last: user.lastName });
            this.setPrefix(user.prefix);
            this.setEmail(user.email);
            this.setPhone(user.phone);
            this.setNotify({ push: user.pushNotify, email: user.emailNotify });
            this.setManagerId(user.managerId);
            this.setLastLogin(user.lastLogin);
            this.setAddress({
              street: user.address.street,
              number: user.address.number,
              district: user.address.district,
              complement: user.address.complement,
              zip: user.address.zip,
              state: user.address.state,
              country: user.address.country
            });
            this.setDevices(user.devices);
            this.setDob(user.dob);
            this.setCrm(user.crm);
            this.setCreated(user.createdAt);
            this.setCpf(user.cpf);
            this.setCnpj(user.cnpj);
            this.setBadges(user.badges);
          }
        }
    }
})();
