(function() {
  'use strict';

  angular
    .module('medsquire.interns')
    .controller('InternsController', InternsController);

  /* @ngInject */
  function InternsController(BadgesService) {
    var vm = this;
    vm.title = 'InternsController';
    vm.badges = {};

    vm.badges = BadgesService;

    activate();

    ////////////////

    function activate() {
    }

  }
})();
