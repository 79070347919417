(function() {
  'use strict';

  angular
    .module('medsquire.events.list')
    .factory('EventsListService', EventsListService);

  /* @ngInject */
  function EventsListService($q, $log, MdsqreHelpers, MdsqreApiMe, MdsqreAuth, MdsqreApiEvents) {
    var service = {
      currentFilter: {},

      setCurrentFilter: setCurrentFilter,
      getCurrentFilter: getCurrentFilter,
      getFilter: getFilter,

      getAllowedFilters: getAllowedFilters,

      fetchMonitorEvents: fetchMonitorEvents,
      fetchAuditorEvents: fetchAuditorEvents,
      fetchAttendantEvents: fetchAttendantEvents,
      fetchSuperAuditorEvents: fetchSuperAuditorEvents,

      filterEventsList: filterEventsList,
      filterMonthEvents: filterMonthEvents,
      filterDayEvents: filterDayEvents,
      filterScheduledEvents: filterScheduledEvents,
      filterFinishedEvents: filterFinishedEvents,

      buildEventsQuery: buildEventsQuery,

      reset: reset
    };
    return service;

    ////////////////

    function setCurrentFilter(filterType) {
      this.currentFilter = getFilter(filterType);
      console.log('######SETCURRENT######', this.currentFilter);
      return this.currentFilter;
    }

    function getCurrentFilter() {
      console.log('######GETCURRENT######', this.currentFilter);
      return this.currentFilter;
    }

    function getFilter(filterType) {
      var filter = getAllowedFilters().find(function (filter) {
        return filter.type === filterType;
      });
      return filter;
    }

    function getAllowedFilters() {
      var filters = [{ name: 'Minha Agenda', type: 'attendant' }];

      if (MdsqreHelpers.getContext().short == 'pro') {
        if (MdsqreAuth.getUser().hasRole('auditor')) {
          filters.push({ name: 'Contestados', type: 'auditor_contested' });
          filters.push({ name: 'Auditor', type: 'auditor' });
        }
        if (MdsqreAuth.getUser().hasRole('monitor')) {
          filters.push({ name: 'Contestados', type: 'monitor_contested' });
          filters.push({ name: 'Gestor', type: 'monitor' });
        }
        if (MdsqreAuth.getUser().hasRole('auditor:super') || MdsqreAuth.getUser().hasRole('manager')) {
          filters.push({ name: 'Contestados', type: 'super_auditor_contested' });
          filters.push({ name: 'Todos', type: 'super_auditor' });
        }
      }

      if (MdsqreHelpers.getContext().short == 'int' || MdsqreHelpers.getContext().short == 'uni') {
        if (MdsqreAuth.getUser().hasRole('auditor')) {
          filters.push({ name: 'Contestados', type: 'auditor_contested' });
          filters.push({ name: 'Coordenador', type: 'auditor' });
        }
        if (MdsqreAuth.getUser().hasRole('monitor')) {
          filters.push({ name: 'Preceptor', type: 'monitor' });
        }
        if (MdsqreAuth.getUser().hasRole('auditor:super') || MdsqreAuth.getUser().hasRole('manager')) {
          filters.push({ name: 'Contestados', type: 'super_auditor_contested' });
          filters.push({ name: 'Todos', type: 'super_auditor' });
        }
      }

      if (MdsqreAuth.getUser().hasRole('medic')) {
        filters.push({ name: 'Contestados', type: 'attendant_contested' });
      }

      return filters;
    }

    function fetchMonitorEvents(options) {
      var deferred = $q.defer();
      var query = {};
      if (options) {
        query = this.buildEventsQuery(options);
      }
      query.event_role = 'monitor';
      $log.log(query);
      MdsqreApiMe.events(query)
        .then(function(eventsResponse) {
          deferred.resolve(eventsResponse.data);
        })
        .catch(function(error) {
          $log.error(error);
          return deferred.reject(error);
        });
      return deferred.promise;
    }

    function fetchAuditorEvents(options) {
      var deferred = $q.defer();
      var query = {};
      if (options) {
        query = this.buildEventsQuery(options);
      }
      query.event_role = 'auditor';
      $log.log(query);
      MdsqreApiMe.events(query)
        .then(function(eventsResponse) {
          deferred.resolve(eventsResponse.data)
        })
        .catch(function(error) {
          $log.error(error);
          deferred.reject(error);
        });
      return deferred.promise;
    }

    function fetchAttendantEvents(options) {
      var deferred = $q.defer();
      var query = {};
      if (options) {
        query = this.buildEventsQuery(options);
      }
      query.event_role = 'attendant';
      if (options.offerable) {
        query.offerable = true;
        query.from = moment().format('YYYY-MM-DD HH:mm:ss');
      }
      $log.log(query);
      MdsqreApiMe.events(query)
        .then(function(eventsResponse) {
          deferred.resolve(eventsResponse.data);
        })
        .catch(function(error) {
          $log.error(error);
          return deferred.reject(error);
        });
      return deferred.promise;
    }

    function fetchSuperAuditorEvents(options) {
      var deferred = $q.defer();
      var user = MdsqreAuth.getUser();
      var query = {};
      if (options) {''
        query = this.buildEventsQuery(options);
      }
      query.byManager = user.getRole('auditor:super').name;
      $log.log(query);
      MdsqreApiEvents.list(query)
        .then(function(eventsResponse) {
          deferred.resolve(eventsResponse.data)
        })
        .catch(function(error) {
          $log.error(error);
          deferred.reject(error);
        });
      return deferred.promise;
    }

    function filterScheduledEvents(events) {
      var filtered = [];
      events.forEach(function(event) {
        if (!event.hasFinished() && !event.hasCheckout()) {
          filtered.push(event);
        }
      });
      return filtered;
    }

    function filterFinishedEvents(events) {
      var filtered = [];
      events.forEach(function(event) {
        if (event.hasFinished() || event.hasCheckout()) {
          filtered.push(event);
        }
      });
      return filtered;
    }

    function filterMonthEvents(events, date) {
      var filtered = [];
      var date = moment(date);
      events.forEach(function(event) {
        var start = moment(event.start);
        var end = moment(event.end);
        if (start.format('YYYY-MM') == date.format('YYYY-MM')) {
          filtered.push(event);
        }
      });
      return filtered;
    }

    function filterDayEvents(events, date) {
      var filtered = [];
      var date = moment(date);
      events.forEach(function(event) {
        var start = moment(event.start);
        var end = moment(event.end);

        var eventStartDay = moment(event.start).startOf('day');
        var eventEndDay = moment(event.end).startOf('day');
        var calendarDate = moment(date).startOf('day');

        //if (eventStartDay <= calendarDate && calendarDate <= eventEndDay) {
        if (start.format('YYYY-MM-DD') == date.format('YYYY-MM-DD')) {
          filtered.push(event);
        }
      });
      return filtered;
    }

    function filterEventsList(events, filter) {
      if (!filter) filter = {};
      var events = events;
      var filtered = { scheduled: [], finished: [] };
      if (filter.month) {
        events = this.filterMonthEvents(events, filter.month);
      }
      if (filter.day) {
        events = this.filterDayEvents(events, filter.day);
      }
      filtered.finished = this.filterFinishedEvents(events);
      filtered.scheduled = this.filterScheduledEvents(events);
      return filtered;
    }

    function buildEventsQuery(options) {
      var query = {};
      if (options.month) {
        var selectedMonth = moment(options.month).set({ hours: 0, minutes: 0, seconds: 0 });
        query.from = moment(selectedMonth).startOf('month').subtract({ weeks: 1 }).format('YYYY-MM-DD HH:mm:ss');
        query.to = moment(selectedMonth).endOf('month').add({ weeks: 1 }).format('YYYY-MM-DD HH:mm:ss');
      }
      if (options.limit) {
        query.limit = options.limit;
      }
      if (options.contested) {
        query.contested = true;
      }
      if (options.location) {
        query.location = options.location;
      }
      return query;
    }

    function reset() {
      this.currentFilter = {};
    }

  }
})();
