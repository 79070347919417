(function() {
    'use strict';

    angular
        .module('medsquire.core.events')
        .constant('coreEvents', {
          bootstrap: 'medsquire:bootstrap',
          ready: 'medsquire:ready',
          mobileDevice: 'medsquire:mobileDevice',
          heartbeat: 'medsquire:heartbeat,'
        });
})();
