(function() {
    'use strict';
  
    angular
      .module('medsquire.directives.medsquire-event-finances')
      .directive('medsquireEventFinances', medsquireEventFinances);
  
    function medsquireEventFinances($filter, $state) {
      var directive = {
  
        link: link,
        restrict: 'E',
        replace: true,
        controller: controller,
        scope: {
          event: '=',
          options: '=?',
        },
        templateUrl: 'shared/directives/event-view/event-finances/medsquire-event-finances.html'
      };
      return directive;
  
      function controller($scope) {
  
        if (angular.isUndefined($scope.options)) {
          $scope.options = {};
        }
  
        $scope.opt = $scope.options;
  
      }
  
      function link(scope, element, attrs) {

      }
    }
  })();
  