(function() {
  'use strict'
  self.addEventListener('activate', function (event) {

  });

  self.addEventListener('fetch', function (event) {

  });

  self.addEventListener('push', function (event) {

  });
})();
