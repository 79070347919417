(function () {
  'use strict';

  angular
    .module('medsquire.settings.preferences')
    .controller('SettingsPreferencesController', SettingsPreferencesController);

  /* @ngInject */
  function SettingsPreferencesController($scope, $log, $q, $window, $state, FirstRunService, TipsService, MdsqreAuth, MdsqreApiUsers, MdsqreApiUserSettings, $ionicPopup) {
    var vm = this;
    vm.title = 'Preferências';

    var popupActivateWhatsApp;
    var popupActivateTelegram;

    vm.notifyBeforeChanged = notifyBeforeChanged;

    $scope.notifyByWhatsAppChanged = notifyByWhatsAppChanged;
    $scope.closePopupActivateWhatsApp = closePopupActivateWhatsApp;
    $scope.notifyByTelegramChanged = notifyByTelegramChanged;
    $scope.closePopupActivateTelegram = closePopupActivateTelegram;

    activate();

    ////////////////

    function activate() {

      FirstRunService.stepCompleted('settings-preferences');
      vm.notificationsTip = TipsService.getTip('activate-notifications');

      $window.onfocus = function() {  
        console.log('WINDOW GOT FOCUS');
        closePopupActivateTelegram();
        closePopupActivateWhatsApp();
        $state.reload();
      };

      vm.user = MdsqreAuth.getUser();
      
      fetchUserInfo(vm.user.id).then(function (userInfo) {
        $log.log('USER INFO', userInfo);
        vm.userInfo = userInfo;
      });

      fetchSettings(vm.user.id).then(function (settings) {
        $log.log('SETTINGS', settings);
        vm.preferences = settings;
        vm.notifyByEmail = vm.preferences['notifications.channels.email.enabled'] == '1';
        vm.notifyByWhatsApp = vm.preferences['notifications.channels.whatsapp.enabled'] == '1';
        vm.notifyByTelegram = vm.preferences['notifications.channels.telegram.enabled'] == '1';
        vm.preferences['notifications.events.checkin.notify_before'] ? vm.notifyBefore = true : vm.notifyBefore = false;
        vm.preferences['notifications.channels.telegram.chat_id'] ? vm.telegramActiveLabel = 'Ativado' : vm.telegramActiveLabel = '';
      });

    }

    function fetchSettings(userId) {
      var deferred = $q.defer();
      MdsqreApiUserSettings.list(userId)
      .then(function(res) {
        deferred.resolve(res.data);
      }).catch(function (error) {
        $log.log(error);
        deferred.reject(error);
      });
      return deferred.promise;
    }

    function setPreference(setting, value) {
        MdsqreApiUserSettings.toggle(vm.user.id, { 'key':setting, 'value':value });
    }

    function fetchUserInfo(userId) {
      var deferred = $q.defer();
      MdsqreApiUsers.view(userId)
        .then(function (usersResponse) {
          deferred.resolve(usersResponse.data);
        })
        .catch(function (error) {
          $log.log(error);
          deferred.reject(error);
        });
      return deferred.promise;
    }

    function notifyByWhatsAppChanged() {

      setPreference('notifications.channels.whatsapp.enabled', vm.notifyByWhatsApp);
      FirstRunService.stepCompleted('notifications-activated');

      if (vm.notifyByWhatsApp) {

        popupActivateWhatsApp = $ionicPopup.show({
          title: 'Ative seu WhatsApp para receber notificações',
          templateUrl: 'whatsapp-template.html',
          scope: $scope,
          buttons: [{
            text: 'Cancelar',
            type: 'button-stable',
            onTap: function (e) {
              vm.notifyByWhatsApp = false;
              setPreference('notifications.channels.whatsapp.enabled', vm.notifyByWhatsApp);
            }
          }, ]
        });

        popupActivateWhatsApp.then(function (res) {
          console.log('CANCELLING');
          setPreference('notifications.channels.whatsapp.enabled', null);
          FirstRunService.stepCompleted('activate-notifications');
        });

      }

    }

    function closePopupActivateWhatsApp() {
      if (popupActivateWhatsApp) {
        popupActivateWhatsApp.close();
      }
    }

    function notifyByTelegramChanged() {

      setPreference('notifications.channels.telegram.enabled', vm.notifyByTelegram);
      FirstRunService.stepCompleted('notifications-activated');

      if (vm.notifyByTelegram) {

        popupActivateTelegram = $ionicPopup.show({
          title: 'Ative seu Telegram para receber notificações',
          templateUrl: 'telegram-template.html',
          scope: $scope,
          buttons: [{
            text: 'Cancelar',
            type: 'button-stable',
            onTap: function (e) {
              vm.notifyByTelegram = false;
              setPreference('notifications.channels.telegram.enabled', vm.notifyByTelegram);
            }
          }, ]
        });

        popupActivateTelegram.then(function (res) {
          console.log('CANCELLING');
          setPreference('notifications.channels.telegram.enabled', null);
          FirstRunService.stepCompleted('activate-notifications');
        });

      }
    }

    function closePopupActivateTelegram() {
      if(popupActivateTelegram) {
        popupActivateTelegram.close();
      }
    }

    function notifyBeforeChanged() {
      var minutesInAdvance;
      vm.notifyBefore ? minutesInAdvance = 30 : minutesInAdvance = null;
      setPreference('notifications.events.checkin.notify_before', minutesInAdvance);
    }

  }
})();
