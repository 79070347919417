(function() {
    'use strict';

    angular
        .module('medsquire.settings.user')
        .controller('UserController', UserController);

    /* @ngInject */
    function UserController(CoreEnvironment, _UserModel, $log) {
        var vm = this;
        vm.title = 'Dados Pessoais';
        vm.user = {};

        activate();

        ////////////////

        function activate() {
          var user = CoreEnvironment.getUser();
          user.fullName = user.getFullName();
          $log.log(user)
          vm.user = user;
        }
    }
})();
