(function() {
    'use strict';

    angular
        .module('medsquire.events.offers', [
          'medsquire.events.offers.create',
          'medsquire.events.offers.list',
          'medsquire.events.offers.view',
          'medsquire.events.offers.viewv2'
        ]);
})();
