(function () {
  'use strict';

  angular
    .module('medsquire.interns')
    .config(internsChatRoutes)

  function internsChatRoutes($stateProvider) {
    $stateProvider.state('int.chat', {
        url: '/chat', 
        abstract: true,
        views: {
          'tab-chat': {
            template: '<ion-nav-view name="tab-chat-main"></ion-nav-view>'
          }
        }
      })
      .state('int.chat.home', {
        url: '/home',
        cache: false,
        views: {
          'tab-chat-main': {
            templateUrl: 'shared/modules/chat/chat.html',
            controller: 'ChatController',
            controllerAs: 'vm'
          }
        }
      });
  }
})();
