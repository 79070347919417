(function() {
  'use strict';

  angular
    .module('medsquire.models')
    .factory('_Model', _Model);

  /* @ngInject */
  function _Model(moment) {

    /**
     * Base _Model class
     * @param {object} data - Init model data
     */
    function _Model(data) {
      if (typeof data !== 'object') {
        throw new Error('Must init model with valid data');
      }
      this._class = '_Model';
      this._sync = false;
      this.id = data.id;
      this.resource = data.resource;
      this.uuid = data.uuid;
      this.createdAt = data.createdAt || moment().format('YYYY-MM-DD HH:mm:ss');
      this.updatedAt = data.updatedAt;
    }

    /**
     * Set model id
     * @param {int} id - id int
     * @return {object} this
     */
    _Model.prototype.setId = function(id) {
      if (!id || typeof id === 'object') throw new Error('Invalid id parameter');
      this.id = id;
      return this;
    };

    /**
     * Set model uuid
     * @param {string} uuid - uuid string
     * @return {object} this
     */
    _Model.prototype.setUuid = function(uuid) {
      if (!uuid || typeof uuid === 'object') throw new Error('Invalid uuid parameter');
      this.uuid = uuid;
      return this;
    };

    /**
     * Set model resource
     * @param {string} resource - resource string
     * @return {object} this
     */
    _Model.prototype.setResource = function(resource) {
      if (!resource || typeof resource !== 'string') throw new Error('Invalid resource parameter');
      this.resource = resource;
      return this;
    };

    /**
     * Set model createdAt
     * @param {string|date} date - date string/date object
     * @return {object} this
     */
    _Model.prototype.setCreatedAt = function(date) {
      if (!date || !moment(date).isValid()) throw new Error('Invalid created datetime');
      this.createdAt = moment(date).format('YYYY-MM-DD HH:mm:ss');
      return this;
    };

    /**
     * Set model updatedAt
     * @param {string|date} date - string/date object
     * @return {object} this
     */
    _Model.prototype.setUpdatedAt = function(date) {
      if (!date || !moment(date).isValid()) throw new Error('Invalid updated datetime');
      this.updatedAt = moment(date).format('YYYY-MM-DD HH:mm:ss');
      return this;
    };

    /**
     * Save model
     * @return {exception} - not implemented exception
     */
    _Model.prototype.save = function() {
      throw new Error("Method not implemented on " + this._class);
    };

    /**
     * Return simple model object
     * @param  {bool} stringify - output string
     * @return {string/object}
     */
    _Model.prototype.toObject = function(stringify) {
      if (!stringify) stringify = false;
      var object = {};
      for (var property in this) {
        if (typeof this[property] === 'function') continue;
        if (property.charAt(0) === '_') continue;
        object[property] = this[property];
      }
      if (stringify) object = JSON.stringify(object);
      return object;
    };

    return _Model;
  }
})();
