(function() {
    'use strict';

    angular
        .module('medsquire.directives.ionsearch')
        .directive('ionSearch', ionSearch);

    /* @ngInject */
    function ionSearch() {
        var directive = {
            link: link,
            restrict: 'E',
            replace: true,
            scope: {
              getData: '&source',
              model: '=?',
              search: '=?filter'
            },
            templateUrl: 'shared/directives/ion-search/ionsearch.html'
        };
        return directive;

        function link(scope, element, attrs) {
          attrs.minLength = attrs.minLength || 0;
          scope.placeholder = attrs.placeholder || '';
          scope.search = {value: ''};

          if (attrs.class)
              element.addClass(attrs.class);

          if (attrs.source) {
              scope.$watch('search.value', function (newValue, oldValue) {
                  if (newValue.length > attrs.minLength) {
                      scope.getData({str: newValue}).then(function (results) {
                          scope.model = results;
                      });
                  } else {
                      scope.model = [];
                  }
              });
          }

          scope.clearSearch = function() {
              scope.search.value = '';
          };
        }
    }
})();
