(function() {
  'use strict';

  angular
    .module('medsquire.events.contestation', [
      'medsquire.events.contestation.create',
      'medsquire.events.contestation.resolve',
      'medsquire.events.contestation.view',
    ]);
})();
