(function() {
    'use strict';

    angular
      .module('medsquire.queue')
      .run(initTaskQueue);

      function initTaskQueue(TaskQueueService) {
        TaskQueueService.init();
      }
})();
