(function() {
  'use strict';

  angular
    .module('medsquire.directives.ion-google-autocomplete')
    .factory('googleAutocompleteService', googleAutocompleteService);

  /* @ngInject */
  function googleAutocompleteService($q) {
    var autocompleteService = new google.maps.places.AutocompleteService();
    var detailsService = new google.maps.places.PlacesService(document.createElement("input"));

    var service = {
      searchAddress: searchAddress,
      getDetails: getDetails
    };

    return service;

    ////////////////

    function searchAddress(input, countryCode) {

      var dfd = $q.defer();

      autocompleteService.getPlacePredictions({
        input: input,
        componentRestrictions: countryCode ? { country: countryCode } : undefined
      }, function(result, status) {

        if (status == google.maps.places.PlacesServiceStatus.OK) {
          console.log(status);
          dfd.resolve(result);
        } else
          dfd.reject(status);
      });

      return dfd.promise;
    }

    function getDetails(placeId) {

      var dfd = $q.defer();

      detailsService.getDetails({ placeId: placeId }, function(result) {

        dfd.resolve(result);
      });

      return dfd.promise;
    }
  }
})();
