(function() {
    'use strict';

    angular
        .module('medsquire.user')
        .factory('_UserModel', _UserModel);

    /* @ngInject */
    function _UserModel(_Model) {

        function _UserModel(data) {
          _Model.call(this, data);
          if (!data) {
            throw 'Must init a user with data';
          }
          this._class = '_UserModel';
          this._token = data.token;
          this.firstName = data.firstName;
          this.lastName = data.lastName;
          this.role = data.role;
        }

        _UserModel.prototype = Object.create(_Model.prototype);

        _UserModel.prototype.getFullName = function() {
          return this.firstName + ' ' + this.lastName;
        }

        _UserModel.prototype.getToken = function() {
          return this._token;
        }

        return _UserModel;
    }
})();
