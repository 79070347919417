(function () {
  'use strict';

  angular
    .module('medsquire.events.view')
    .controller('EventViewController', EventViewController);

  /* @ngInject */
  function EventViewController($q, $scope, $ionicPopup, $state, $mdsqre, MdsqreApiEvents, MdsqreApiEventOffers, $log,
    $sessionStorage, MdsqreAuth, MdsqreHelpers, OffersCreateService, ExchangesAcceptService) {

    var vm = this;
    vm.title = 'Atividade';
    vm.MdsqreHelpers = MdsqreHelpers;

    vm.eventLoaded = false;
    vm.userLoaded = false;

    vm.savePresence = savePresence;
    vm.fetchEvent = fetchEvent;

    vm.getOfferTypeLabel = getOfferTypeLabel;
    vm.startOffer = startOffer;
    vm.offerBack = offerBack;

    activate();

    ////////////////

    function activate() {

      checkOffering();
      backNavigationFix();
      initSlider();

      vm.user = MdsqreAuth.getUser();
      vm.userLoaded = true;

      fetchEvent($state.params.eventId).then(
        function (response) {
          setFlags();
          vm.eventLoaded = true;
          //### PODRE ESSE TIMER ###
          setTimeout(function () {
            goToStartingSlider();
          }, 500);
        }
      );

    }

    function fetchEvent(eventId) {
      var deferred = $q.defer();
      $mdsqre.requester().events(eventId).view()
        .then(function (eventResponse) {
          vm.event = eventResponse.data;
          console.log('EVENT', vm.event);
          if (vm.event.hasPendingOffersV2()) {
              vm.offer = vm.event.getPendingOffersV2()[0];
              // ExchangesAcceptService.setOffer(vm.offer);
              $log.log('OFFER', vm.offer);
              if (vm.event.attendants.length) {
                vm.event_title = vm.event.attendants[0].name;
              } else if (vm.offer) {
                vm.event_title = vm.offer.from_user.name;
              }
              vm.event.titleLabel = vm.getOfferTypeLabel(vm.offer.type);
          }
          vm.event.getCheckinInfo();
          vm.event.getCheckoutInfo();
          vm.event.getConfirmationInfo();
          deferred.resolve(eventResponse);
        })
        .catch(function (error) {
          $log.log(error);
          deferred.reject(error);
        });
      return deferred.promise;
    }

    function getFirstWord(str) {
      var spacePosition = str.indexOf(' ');
      if (spacePosition === -1)
        return str;
      else
        return str.substr(0, spacePosition);
    };

    function setFlags() {

      //vm.showFinance = vm.event.hasPaymentInfo();
      vm.showStateLabels = !vm.showOfferStatus;
      vm.showPendingLabels = !vm.showOfferStatus;

      if (vm.offer) {

        // This is part of an offer, cannot be offered

      } else if (!vm.offeringEvent) {

        vm.showTransferButton = vm.event.canOfferTransferV2();
        vm.showExchangeButton = vm.event.canOfferExchangeV2();

      } else {

        // #### HACK - Pro lançamento na UFRGS ####
        // console.log('OFFER', ExchangesAcceptService.getOffer());
        if (vm.event.canOfferExchangeV2()) {
          if (MdsqreHelpers.getContext().short == 'uni') {
            //var offeredSector = ExchangesAcceptService.getOffer().offerable.location.sector;
            var offeredSector = ExchangesAcceptService.offer.proposals[0].entity.location.sector;
            var thisSector = vm.event.location.sector;
            console.log('OFFERED SECTOR', offeredSector);
            if ((offeredSector && thisSector) && (getFirstWord(offeredSector) == getFirstWord(thisSector))) {
              vm.showOfferThisButton = true;
            } else {
              vm.showOfferThisButton = false;
              vm.trocasRestritasMsg = 'Trocas restritas por setor';
            }
          } else {
            vm.showOfferThisButton = vm.event.canOfferExchangeV2();
          }
        }
        // #### END HACK ####

      }

    }

    function checkOffering() {
      var ongoingOffer = ExchangesAcceptService.getOffer();
      if (ongoingOffer && ongoingOffer.type == "exchange") {
        vm.offeringEvent = true;
      }
    }

    function backNavigationFix() {
      if ($state.params.action === 'checked_out') {
        $sessionStorage.customBack = {
          backSteps: -5
        };
      }
      if ($state.params.action === 'contested') {
        $sessionStorage.customBack = {
          backSteps: -5
        };
      }
      if ($state.params.action === 'created_offer') {
        $sessionStorage.customBack = {
          backSteps: -4
        };
      }
      if ($state.params.action === 'canceled_offer') {
        $sessionStorage.customBack = {
          backSteps: -4
        };
      }
    }

    function savePresence(type) {
      var presence = {
        user_id: vm.user.id,
        event_id: vm.event.id,
        time: moment().format('YYYY-MM-DD HH:mm:ss'),
        type: type,
      };

      $mdsqre.requester().events(vm.event.id).presences().save(presence)
        .then(function (presenceResponse) {
          $log.log(presenceResponse);
        })
        .catch(function (error) {
          $log.error(error);
        })
        .finally(function () {
          fetchEvent($state.params.eventId);
        });
    }

    // ############## OFFER ##############

    function startOffer(offerType) {
      OffersCreateService.reset();
      OffersCreateService.setEvent(vm.event);
      OffersCreateService.setType(offerType);
      $state.go('*.events.offers.create.step1');
    }

    function startExchangeAccept() {
      ExchangesAcceptService.reset();
      ExchangesAcceptService.setOffer(vm.offer);
      ExchangesAcceptService.setOfferedEvent(vm.event);
      $state.go('*.events.list');
    }

    function offerBack() {
          vm.offerId = ExchangesAcceptService.getOffer().id;

          $mdsqre.requester().offersv2(vm.offerId).proposals().save({
            from_user_id: vm.user.id,
            proposals: [{
              type: 'attendant_slot',
              proposable_type: 'App\\Event',
              proposable_id: vm.event.id,
              by_user_id: vm.user.id
            }],
            verifications: [{type: 'confirm'}]
          })
          .then(function(response) {
            console.log(response);
            $state.go('*.events.offers.viewv2', {
              offerId: vm.offerId,
              action: 'created_offer'
            });
          })
          .catch(function(error) {
            //
          });
    }

    function getOfferTypeLabel(type) {
      switch (type) {
        case 'transfer':
          return 'PASSA';
          break;
        case 'assign':
          if (vm.offer.visibility == 'public') {
            return 'OFERTA PÚBLICA';
          } else {
            return 'OFERTA PRIVADA';
          }
          break;
        case 'exchange':
          return 'TROCA';
          break;
      }
    }

    // ############## SLIDER ##############

    function initSlider() {
      vm.sliderOptions = {
        effect: 'slide',
        initialSlide: 0,
        speed: 500,
        onInit: function (slider) {
          vm.slider = slider;
        },
        onSlideChangeEnd: function (slider) {
          vm.activeIndex = slider.activeIndex;
        }
      };
    }

    function goToStartingSlider() {
      if (vm.event.canCheckin() || vm.event.canCheckout() || vm.event.canEvaluate() || vm.event.isContested()) {
        goToSlide(2);
      } else if (vm.event.hasPendingOffers()) {
        goToSlide(0);
      } else if (vm.event.hasConfirmedPresence()) {
        goToSlide(2);
      } else if ($state.params.action === 'checked_out') {
        goToSlide(2);
      } else if (vm.event.isContested()) {
        goToSlide(2);
      }
    }

    function goToSlide(slideNum) {
      vm.slider.slideTo(slideNum, 2000);
    }

    $scope.$on("$destroy", function () {
      if ($state.current.name.indexOf('events.list') === -1) {
        ExchangesAcceptService.reset();
      }
    });

  }
})();
