(function() {
    'use strict';

    angular
        .module('medsquire.settings', [
          'medsquire.settings.help',
          'medsquire.settings.developer',
          'medsquire.settings.preferences',
          'medsquire.settings.user'
        ]);
})();
