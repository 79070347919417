(function() {
    'use strict';

    angular
        .module('medsquire.settings.developer')
        .controller('DeveloperController', DeveloperController);

    /* @ngInject */
    function DeveloperController(LocalNotification, LocalNotifier,
      moment, CoreEnvironment, $log, $window) {
        var vm = this;
        vm.title = 'Developer';
        vm.notificationList = {};

        vm.clearTables = clearTables;
        vm.dropTables = dropTables;
        vm.createLocalNotification = createLocalNotification;
        vm.listLocalNotifications = listLocalNotifications;
        vm.cancelAllLocalNotifications = cancelAllLocalNotifications;

        activate();

        ////////////////

        function activate() {
          listLocalNotifications();
        }

        function createLocalNotification() {
          if (CoreEnvironment.getPlatform().browser) {
            $log.warn('you are not on a mobile device');
            return;
          }
          var notification = new LocalNotification();
          notification.setId(9999);
          notification.setTitle('Testando Notificação Local');
          notification.setText('testando notificação');
          notification.setTriggerTime(new Date(moment().add(5, 's')));
          notification.trigger();
          $log.log(notification.toObject());
        }

        function listLocalNotifications() {
          if (CoreEnvironment.getPlatform().browser) {
            $log.warn('you are not on a mobile device');
            return;
          }
          LocalNotifier.list()
          .then(function(notifications) {
            $log.log(notifications);
            vm.notificationList = notifications;
          })
          .catch(function(error) {
            $log.error(error);
          });
        }

        function cancelAllLocalNotifications() {
          LocalNotifier.cancelAll();
        }
    }
})();
