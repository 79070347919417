(function() {
  'use strict';

  angular
    .module('medsquire.events.comments')
    .controller('CommentsController', CommentsController);

  /* @ngInject */
  function CommentsController($scope, $q, $state, $mdsqre, $log, MdsqreAuth, MdsqreApiContestation,
    MdsqreHelpers, PresenceCheckoutService, $ionicModal) {

    var vm = this;
    vm.title = 'Comentários';

    vm.makeTextComment = makeTextComment;
    vm.uploadFile = uploadFile;
    vm.formatDate = formatDate;
    vm.openModalCheckout = openModalCheckout;
    vm.openModalQuitting = openModalQuitting;
    vm.openModalCancellation = openModalCancellation;

    activate();

    ////////////////

    $ionicModal.fromTemplateUrl('shared/modules/events/comments/emergencyCheckout.html', {
      scope: $scope,
      animation: 'slide-in-up'
    }).then(function(modal) {
      $scope.modal = modal;
    });

    $ionicModal.fromTemplateUrl('shared/modules/events/comments/emergencyQuitting.html', {
      scope: $scope,
      animation: 'slide-in-up'
    }).then(function(modal) {
      $scope.modalQuitting = modal;
    });

    $ionicModal.fromTemplateUrl('shared/modules/events/comments/emergencyCancellation.html', {
      scope: $scope,
      animation: 'slide-in-up'
    }).then(function(modal) {
      $scope.modalCancellation = modal;
    });

    function openModalCheckout() {
      $scope.modal.show();
    };

    function openModalQuitting() {
      $scope.modalQuitting.show();
    };

    function openModalCancellation() {
      $scope.modalCancellation.show();
    };

    function activate() {
      var eventId = $state.params.eventId;
      vm.user = MdsqreAuth.getUser();
      vm.context = MdsqreHelpers.getContext();

      switch (vm.context.short) {
        case 'int':
          vm.commentLegend = 'Comentários visíveis apenas para Residente e Coordenação.';
          break;

        case 'uni':
          vm.commentLegend = 'Comentários visíveis apenas para Aluno e Coordenação.';
          break;

        case 'pro':
          vm.commentLegend = 'Comentários visíveis apenas para Médico e Gestor.';
          break;
      }

      fetchEvent(eventId).then(
        function(response) {
          vm.eventLoaded = true;
        }
      );

      fetchComments(eventId).then(
        function(response) {
          vm.commentsLoaded = true;
        }
      );

      $scope.closeModal = function() {
        $scope.modal.hide();
      };

      $scope.closeModalQuitting = function() {
        $scope.modalQuitting.hide();
      };

      $scope.closeModalCancellation = function() {
        $scope.modalCancellation.hide();
      };

      //Cleanup the modal when we're done with it!
      $scope.$on('$destroy', function() {
        /*$scope.modal.remove();
        $scope.modalQuitting.remove();
        $scope.openModalCancellation.remove();*/
      });

      // Execute action on hide modal
      $scope.$on('modal.hidden', function() {
        // Execute action
      });
      // Execute action on remove modal
      $scope.$on('modal.removed', function() {
        // Execute action
      });

      $scope.emergencyQuittingClicked = function() {
        if (!vm.yesDoEmergencyQuitting) {
          return false;
        }
        var subjects = vm.event.subjects;
        var subject = subjects.filter(function(subject) {
          return subject.pivot.role === "attendant";
        });
        $mdsqre.requester().events($state.params.eventId).subjects(subject[0].id).delete()
          .then(function(response) {
            $scope.closeModalQuitting();
            $state.go('*.events.list');
          })
          .catch(function(error) {
            $log.log(error)
          });
      }

      $scope.emergencyCancellationClicked = function() { //////
        if (!vm.yesDoEmergencyCancellation) {
          return false;
        }
        var subjects = vm.event.subjects;
        subjects.forEach(function(subject) {
          $mdsqre.requester().events($state.params.eventId).subjects(subject.id).delete()
            .then(function(response) {
              $log.log(response);
              $scope.closeModalCancellation();
              $state.go('*.events.list');
            })
            .catch(function(error) {
              $log.log(error)
            });
        })
      }

      $scope.emergencyCheckoutClicked = function() {
        if (!vm.yesDoEmergencyCheckout) {
          return false;
        }
        if (vm.context.long === "university" || vm.context.long === "interns") {
          return false;
        } else {
          var monitor = getMonitor();
          if (monitor != undefined) {
            PresenceCheckoutService.setEmergencyCheckout(true);
            setMonitor(monitor);
            $scope.closeModal();
            $state.go('*.events.checkout.step3', {
              eventId: $state.params.eventId
            });
          } else {
            $state.go('*.events.checkout.step2', {
              eventId: $state.params.eventId
            });
          }
        }
      }

      function getMonitor() {
        $scope.monitor = vm.event.subjects.filter(function(subject) {
          return (subject.pivot.role === "monitor")
        });
        return $scope.monitor[0];
      }

      function setMonitor(monitor) {
        PresenceCheckoutService.setMonitor(monitor);
        $state.go('*.events.checkout.step3');
      }

    }

    function fetchEvent(eventId) {
      var deferred = $q.defer();
      $mdsqre.requester().events(eventId).view()
        .then(function(eventResponse) {
          $log.log('EVENT', eventResponse);
          vm.event = eventResponse.data;
          vm.event.getCheckinInfo();
          vm.event.getCheckoutInfo();
          vm.event.getConfirmationInfo();
          deferred.resolve(eventResponse);
        })
        .catch(function(error) {
          $log.log(error);
        });
      return deferred.promise;
    }

    function fetchComments(eventId) {
      var deferred = $q.defer();
      $mdsqre.requester().events(eventId).comments().list()
        .then(function(commentsResponse) {
          $log.log('COMMENTS', commentsResponse);
          vm.comments = commentsResponse.data;
          deferred.resolve(commentsResponse);
        })
        .catch(function(err) {
          $log.log('ERROR', err);
        });
      return deferred.promise;
    }

    function makeTextComment(comment) {
      makeComment({ body: comment, additional_info: { type: 'text' }});
    }

    function makeComment(comment) {
      $mdsqre.requester().events(vm.event.id).comments().save(comment)
        .then(function(response) {
          $log.log('COMMENT', response);
          $state.reload();
        })
        .catch(function(err) {
          $log.log('ERROR', err);
        });
    }

    function uploadFile(file) {

      $mdsqre.requester().users(vm.user.id).uploads().saveFile({
        file: file,
        folder: 'comments',
        public: true
      })
      .then(function(uploadResponse) {
        $log.log('Success ' + uploadResponse.config.data.file.name + 'uploaded. Response: ' + uploadResponse.data);
        makeComment({
            body: '',
            additional_info: {
              type: 'image',
              src: 'https://sandbox.medsquire.com/' + uploadResponse.data.data.url,
            }
          })
      }, function(error) {
        $log.error(response);
        alert('Error: ' + response.status);
      }, function(event) {
        var progressPercentage = parseInt(100.0 * event.loaded / event.total);
        $log.log('progress: ' + progressPercentage + '% ' + event.config.data.file.name);
      });
    }

    function formatDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YY HH:mm");
    }

  }
})();
