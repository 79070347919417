(function() {
  'use strict';

  angular
    .module('medsquire.events.contestation')
    .controller('ContestationResolveController', ContestationResolveController);

  /* @ngInject */
  function ContestationResolveController($state, $filter, MdsqreApiEvents, MdsqreApiShifts, $ionicPopup, blockUI, MdsqreApiContestation,
    $log, MdsqreAuth, MdsqreApiUsers) {
    var vm = this;
    vm.title = 'Contestação';
    vm.user = {};
    vm.avaliatorRate = 0;
    vm.eventRate = 0;
    vm.event = {};

    vm.resolveContestation = resolveContestation;
    vm.getEventState = getEventState;

    activate();

    ////////////////

    function activate() {
      vm.user = MdsqreAuth.getUser();

      fetchEvent($state.params.eventId);
    }

    function fetchEvent(eventId) {
      MdsqreApiEvents.view(eventId)
      .then(function(eventResponse) {
        vm.event = eventResponse.data;

        vm.contestation = vm.event.contestations.filter(function(contestation) {
            return (contestation.id.toString() === $state.params.contestId);
        });

        vm.avaliatorRate = vm.event.getPresence('checkout', true).additional_info.rating.avaliator.rate;
        vm.eventRate = vm.event.getPresence('checkout', true).additional_info.rating.event.rate;

        var checkin = vm.event.getPresence('checkin', true);
        vm.event.checkinTime = vm.event.getPresence('checkin', true).time.format('D MMM, ddd | HH:mm');

        var checkout = vm.event.getPresence('checkout', true);
        vm.event.checkoutTime = vm.event.getPresence('checkout', true).time.format('D MMM, ddd | HH:mm');
        vm.event.Activities = checkout.additional_info.tags.join(', ');

        getCheckoutInfo();
      })
      .catch(function(error) {
        $log.log(error);
      });
    }

    /*function resolveContestation() {
      var event = vm.event;
      var shift = { id: event.id, contest_solution: vm.contest_solution };
      blockUI.start();
      MdsqreApiShifts.contestResolve(shift)
      .then(function(response) {
        return confirmPresence();
      })
      .then(function(response) {
        return rateEvent();
      })
      .then(function(response) {
        return rateIntern();
      })
      .then(function(response) {
        $state.go('*.events.view', { eventId: $state.params.eventId, action: 'contested' });
      })
      .finally(function() {
        blockUI.stop();
      });
    }*/


    function resolveContestation() {
      var event = vm.event;
      var contestation_id = vm.contestation.id;  //event.contestations[event.contestations.length - 1].id;
      var contestable_id = vm.contestation.contestable_id;//event.contestations[event.contestations.length - 1].contestable_id;
      var resolve = { contestable_id: contestable_id, contestation_id: contestation_id };
      blockUI.start();
      MdsqreApiContestation.resolve(resolve)
      .then(function(response) {
        return confirmPresence();
      })
      .then(function(response) {
        return rateEvent();
      })
      .then(function(response) {
        return rateIntern();
      })
      .then(function(response) {
        $state.go('*.events.view', { eventId: $state.params.eventId, action: 'contested' });
      })
      .finally(function() {
        blockUI.stop();
      });
    }

    function getEventState() {
      if (!vm.event.eventable) {
        return { 'state': '', 'class': '' };
      }
      var filter = 'eventStateFilter';
      var state = vm.event.state;
      var css = 'state-default';
      if (vm.event.eventable.state == 'assigned' && vm.event.state != 'in_progress' && vm.event.state != 'finished') {
        filter = 'shiftStateFilter';
        state = vm.event.eventable.state;
      }
      if (vm.event.eventable.state == 'validated') {
        filter = 'eventStateFilter';
        state = vm.event.state;
        css = 'state-ok';
      }
      if (vm.event.eventable.state == 'canceled') {
        filter = 'shiftStateFilter';
        state = vm.event.eventable.state;
        css = 'state-alert';
      }
      if (vm.event.eventable.state == 'contested') {
        filter = 'shiftStateFilter';
        state = vm.event.eventable.state;
        css = 'state-alert';
      }
      return { 'state': $filter(filter)(state), 'class': css };
    }

    function getCheckoutInfo() {
      var checkout = vm.event.getPresence('checkout', true);
      vm.event.checkoutTime = vm.event.getPresence('checkout', true).time.format('D MMM, ddd | HH:mm');
      vm.event.evaluatorName = checkout.additional_info.avaliator.name;
      vm.event.attendantEvaluatorRating = checkout.additional_info.rating.avaliator.rate;
      vm.event.attendantEventRating = checkout.additional_info.rating.event.rate;
      vm.event.Activities = checkout.additional_info.tags.join(', ');
    }

    function confirmPresence() {
      var presence = {
        event_id: $state.params.eventId,
        time: moment().format('YYYY-MM-DD HH:mm:ss'),
        type: 'presence_confirmation',
      };
      presence.additional_info = {
        avaliator: vm.user.toObject(),
        rating: { intern: { rate: 1 }, event: { rate: 1 } },
        obs: vm.contest_reason,
      };
      return MdsqreApiEvents.presence(presence);
    }

    function rateEvent() {
      var rating = {};
      rating.event_id = $state.params.eventId;
      rating.ratings = [
        { type: 'event', rate: 1 },
        { type: 'intern_performance', rate: 1 },
      ];
      return MdsqreApiEvents.rating(rating);
    }

    // should do it on backend
    function rateIntern() {
      var rating = {
        type: 'intern_performance',
        rate: 1,
      };
      rating.user_id = vm.event.eventable.assigned_user;
      return MdsqreApiUsers.rating(rating);
    }

  }
})();
