(function() {
  'use strict';

  angular
    .module('medsquire.events.presence.checkin')
    .controller('EventPresenceCheckinController', EventPresenceCheckinController);

  /* @ngInject */
  function EventPresenceCheckinController($state, $ionicPopup, $mdsqre, MdsqreApiEvents, $log, MdsqreAuth) {
    var vm = this;
    vm.title = 'Checkin';
    vm.state = {};
    vm.event = {};

    activate();

    ////////////////

    function activate() {
      if (!$state.params.eventId) {
        $ionicPopup.alert({
          title: 'Erro',
          template: 'Url inválida'
        })
        .then(function(response) {
          // $state.go('*.home');
          vm.state.error = true;
          vm.state.message = "Erro ao fazer checkin: \n Url inválida.";
          vm.title = 'Erro ao fazer Checkin'
        });
        return;
      }

      savePresence($state.params.eventId, 'checkin');
    }

    function fetchEvent(eventId) {
      MdsqreApiEvents.view(eventId)
      .then(function(eventResponse) {
        vm.event = eventResponse.data;
      })
      .catch(function(error) {
        $log.log(error);
      });
    }

    function savePresence(eventId, type) {
      var attendant = MdsqreAuth.getUser();
      var presence = {
        user_id: attendant.id,
        event_id: eventId,
        time: moment().format('YYYY-MM-DD HH:mm:ss'),
        type: type,
      };

      $mdsqre.requester().events(eventId).presences().save(presence)
      .then(function(presenceResponse) {
        vm.state.success = true;
        $log.log(presenceResponse);
        fetchEvent($state.params.eventId);
      })
      .catch(function(error) {
        vm.state.error = true;
        $log.error(error);
        $ionicPopup.alert({
          title: 'Erro',
          template: 'Não foi possível fazer o checkin: ' + error.print()
        })
        .then(function(response) {
          $state.go('*.home');
        });
      });
    }
  }
})();
