(function() {
  'use strict';

  angular
    .module('medsquire.address')
    .factory('AddressDataservice', AddressDataservice);

  /* @ngInject */
  function AddressDataservice(DataService, AddressModel, helperService) {
    var AddressDataservice = Object.create(DataService);
    AddressDataservice._class = 'AddressDataservice';

    AddressDataservice.remapAddress = remapAddress;
    AddressDataservice.parseGoogleAddressComponents = parseGoogleAddressComponents;

    return AddressDataservice;

    ////////////////

    function remapAddress(addressData) {
      if (!addressData) throw new Error('Must inform valid address data');
      return new AddressModel({
        street: addressData.street,
        number: addressData.number,
        district: addressData.district,
        city: addressData.city,
        zip: addressData.zip,
        state: addressData.state,
        country: addressData.country,
      });
    }

    function parseGoogleAddressComponents(addressArray, remap) {
      if (!addressArray || addressArray.length <= 0) {
        throw new Error('Must inform valid google address components data');
      }
      var address = {};
      addressArray.forEach(function(component) {
        if (component.types.indexOf('route') > -1) {
          address['street'] = component.short_name;
        }
        if (component.types.indexOf('street_number') > -1) {
          address['number'] = component.long_name;
        }
        if (component.types.indexOf('sublocality_level_1') > -1) {
          address['district'] = component.long_name;
        }
        if (component.types.indexOf('administrative_area_level_2') > -1) {
          address['city'] = component.long_name;
        }
        if (component.types.indexOf('administrative_area_level_1') > -1) {
          address['state'] = component.short_name;
        }
        if (component.types.indexOf('country') > -1) {
          address['country'] = component.short_name;
        }
        if (component.types.indexOf('postal_code') > -1) {
          address['zip'] = component.short_name;
        }
      });
      address['zip'] = helperService.cleanZip(address['zip']);
      if (remap) {
        return this.remapAddress(address);
      }
      return address;
    }
  }
})();
