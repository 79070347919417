(function() {
  'use strict';

  angular
    .module('medsquire.events.presence.checkout.step1')
    .controller('CheckoutStep1Controller', CheckoutStep1Controller);

  /* @ngInject */
  function CheckoutStep1Controller($state, MdsqreHelpers, PresenceCheckoutService, $log, $ionicPopup) {


    var vm = this;
    vm.title = 'Checkout';
    vm.tags = [];
    vm.haveActivities = false;

    vm.setTags = setTags;
    vm.activityChanged = activityChanged;

    activate();

    ////////////////

    function activate() {
      var intTags = [{
        name: "Outra",
        id: 0
      }, {
        name: "Manejo de suspeita Covid19",
        id: 1
      }, {
        name: "WebC - Webconferência",
        id: 2
      }, {
        name: "DE - Demanda Expontânea",
        id: 3
      }, {
        name: "CAPSi - CAPS Infantil",
        id: 4
      }, {
        name: "PN - Pré natal",
        id: 5
      }, {
        name: "SM - Saúde da Mulher",
        id: 6
      }, {
        name: "PUE - Puericultura",
        id: 7
      }, {
        name: "RE - Reunião de Equipes",
        id: 8
      }, {
        name: "HAS - Hipertensão Arterial Sistemica",
        id: 9
      }, {
        name: "DM - Diabete Mellitus",
        id: 10
      }, {
        name: "VD - Visita Domiciliar",
        id: 11
      }];




      var uniTags = [  {
        name: "Outra",
        id: 0
      }, {
        name: "Manejo de suspeita Covid19",
        id: 1
      }, {
        name: "Atendimento de Intercorrência",
        id: 2
      }, {
        name: "Avaliação Clínica",
        id: 3
      }, {
        name: "Anamnese",
        id: 4
      }, {
        name: "Revisão de História Clínica",
        id: 5
      }, {
        name: "Evolução de Paciente",
        id: 6
      }, {
        name: "Discussão de Caso",
        id: 7
      }, {
        name: "Solicitação de Exames",
        id: 8
      }, {
        name: "Prescrição Médica",
        id: 9
      }, {
        name: "Análise de Imagens",
        id: 10
      }, {
        name: "Avaliação Laboratorial",
        id: 11
      }, {
        name: "Acompanhamento",
        id: 12
      }, {
        name: "Revisão de Conduta",
        id: 13
      }, {
        name: "Gasometria",
        id: 14
      }, {
        name: "Acesso Venoso Central",
        id: 15
      }, {
        name: "Toracocentese",
        id: 16
      },
      {
        name: "Paracentese",
        id: 17
      },
      {
        name: "Punção Lombar",
        id: 18
      }];

      var context = MdsqreHelpers.getContext();

      if (context.short == 'int') {
        vm.tags = intTags;
      } else if (context.short == 'uni') {
        vm.tags = uniTags;
      }

    }

    function setTags() {
      var selectedTags = [];

      vm.tags.filter(isChecked).forEach(function(tag) {
        selectedTags.push(tag.name);
      });
      if (selectedTags.length > 0) {
        PresenceCheckoutService.setTags(selectedTags);
        $state.go('*.events.checkout.step2');
      }
    }

    function activityChanged(idx) {
      console.log(idx);
      console.log(vm.tags[idx].name);
      if (vm.tags[idx].checked) {
        if (idx == 0) {
          inputOtherActivity();
        }
      } else {
        if (idx == 0) {
          vm.tags[0].name = "Outra";
        }
      }
      canIGoNowPlease();
    }

    function inputOtherActivity() {
      $ionicPopup.prompt({
        title: 'Informe a Atividade Realizada',
        inputType: 'text'
      }).then(function(activity) {
        if (activity) {
          vm.tags[0].name = activity;
          canIGoNowPlease();
        } else {
          vm.tags[0].name = "Outra";
          vm.tags[0].checked = false;
          canIGoNowPlease();
        }
      });
    }

    function canIGoNowPlease() {
        var countSelected = 0;
        vm.tags.filter(isChecked).forEach(function(tag) {
            countSelected++;
        });
        if (countSelected > 0) {
            vm.haveActivities = true;
        } else {
            vm.haveActivities = false;
        }
    }

    function isChecked(tag) {
      return tag.checked == true;
    }

  }
})();
