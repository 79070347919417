(function () {
  'use strict';

  angular
    .module('medsquire.services')
    .factory('FirstRunService', FirstRunService);

  /* @ngInject */
  function FirstRunService(BadgesService, TipsService) {

    var FirstRunService = {};

    FirstRunService.setup = setup;
    FirstRunService.stepCompleted = stepCompleted;

    return FirstRunService;

    ////////////////

    function setup() {
      setStartingBadges();
      setStartingTips();
    }

    function stepCompleted(step) {
      switch (step) {
        case 'settings-preferences':
          BadgesService.setBadge('settings', 0);
          BadgesService.setBadge('settings-preferences', 0);
          break;

        case 'notifications-activated':
          TipsService.setTip('activate-notifications', '');
          break;
      }
    }

    function setStartingBadges() {
      BadgesService.setBadge('settings', 1);
      BadgesService.setBadge('settings-preferences', 1);
      console.log('SETTING BADGES');
    }

    function setStartingTips() {
      TipsService.setTip('activate-notifications', 'Clique numa das opções acima para poder receber notificações importantes em seu WhatsApp ou Telegram.')
    }
  }
})();
