(function() {
  'use strict';

  angular
    .module('medsquire.interns')
    .config(internsPasswordResetRoutes);

  function internsPasswordResetRoutes($stateProvider, $urlRouterProvider) {
    $stateProvider.state('intpassword_reset', {
      url: '/intpassword_reset',
      cache: false,
      templateUrl: 'interns/password_reset/password_reset.html',
      controller: 'InternsPasswordResetController',
      controllerAs: 'vm'
    })
  }
})();
