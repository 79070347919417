
(function () {
  'use strict';

  angular
    .module('medsquire.events.offers.list')
    .controller('offersListController', offersListController);

  /* @ngInject */
  function offersListController($scope, $rootScope, $mdsqre, $timeout, $state, MdsqreHelpers, $log, ExchangesAcceptService, MdsqreAuth) {

    var vm = this;
    vm.title = 'Ofertas';
    vm.countOfferRequests = 0;
    vm.countOffersFetched = 0;
    vm.tabIndex = 0;

    vm.publicOffers = [];
    vm.offersToMe = [];
    vm.offersByMe = [];
    vm.offersToAuthorize = [];
    vm.globalMonthSelected = {};
    vm.selectedMonth = {};

    vm.showtabs = { "to_me": true, "by_me": true, "to_authorize": false };
    vm.hasPublicOffers = false;
    vm.displayCalendar = false;
    vm.CLEAN = true;

    vm.toggleCalendar = toggleCalendar;
    vm.slideHasChanged = slideHasChanged;
    vm.updateTabContent = updateTabContent;
    vm.filterDayOffers = filterDayOffers;
    vm.filterMonthOffers = filterMonthOffers;
    vm.changeMonth = changeMonth;
    vm.getStatus = getStatus;
    vm.updateTabContent = updateTabContent;

    activate();

    function activate() {
      vm.selectedMonth = moment();
      ExchangesAcceptService.reset();
      vm.context = MdsqreHelpers.getContext();
      vm.user = MdsqreAuth.getUser();
      if (vm.user) {
        if (vm.user.hasRole('monitor') || vm.user.hasRole('auditor') || vm.user.hasRole('auditor:super')) {
          vm.showtabs.to_authorize = true;
        }
        if (vm.context.short == 'pro') {
          getOffersAcceptableByMe();
        } else {
          getOffersToMe();
        }
        getOffersByMe();
        getOffersToAuthorize();
      } else {
        vm.countOfferRequests += 2;
        vm.showtabs.by_me = false;
        getPublicOffers();
      }
    }

    function getPublicOffers() {
      $mdsqre.requester().offersv2().list({ visibility: 'public' })
        .then(function (offersResponse) {
          vm.publicOffers = offersResponse.data.filter(function (offer) {
            if (vm.user && offer.from_user_id && offer.from_user_id == vm.user.id) {
              return false;
            }
            return true;
          });
          offersReceived();
          console.log('PUBLIC', vm.publicOffers);
        })
        .catch(function (error) {
          //
        });
    }

    function getOffersAcceptableByMe() {
      $mdsqre.requester().offersv2().list({ acceptable: vm.user.id })
        .then(function (offersResponse) {
          offersResponse.data.forEach(function(offer) {
            if (offer.isPublicOffer) {
              vm.publicOffers.push(offer);
            } else {
              vm.offersToMe.push(offer);
            }
          });
          offersReceived();
          $log.log('TO ME', vm.offersToMe);
        })
        .catch(function (error) {
          //
        });
    }

    function getOffersToMe() {
      $mdsqre.requester().offersv2().list({ offer_subject: vm.user.id + ":to_user" })
        .then(function (offersResponse) {
          vm.offersToMe = offersResponse.data;
          offersReceived();
          $log.log('TO ME', vm.offersToMe);
        })
        .catch(function (error) {
          //
        });
    }

    function getOffersByMe() {
      $mdsqre.requester().offersv2().list({ offer_subject: vm.user.id + ":from_user" })
        .then(function (offersResponse) {
          vm.offersByMe = offersResponse.data;
          offersReceived();
          $log.log('BY ME', vm.offersByMe);
        })
        .catch(function (error) {
          //
        });
    }

    function getOffersToAuthorize() {
      $mdsqre.requester().offersv2().list({ authorizable: vm.user.id })
        .then(function (offersResponse) {
          vm.offersToAuthorize = offersResponse.data;
          offersReceived();
          $log.log('TO AUTHORIZE', vm.offersToAuthorize);
        })
        .catch(function (error) {
          //
        });
    }

    function offersReceived() {
      vm.countOfferRequests++;
      console.log('COUNT', vm.countOfferRequests);

      if (vm.countOfferRequests == 3) {
        removeCalendarFilter();
        if (vm.filtteredOffersToAuthorize.length > 0) {
          vm.tabIndex = 3
        } else if (vm.filteredOffersToMe.length + vm.filteredPublicOffers.length > 0) {
          vm.tabIndex = 0;
        } else if (vm.filteredOffersByMe.length > 0) {
          vm.tabIndex = 1;
        };
        //skipEmptyTab();
        vm.tabIndex = 0;
        updateTabContent(vm.tabIndex);
        //updateCounters();
        vm.finishedFetching = true;
      }
    }

    function removeCalendarFilter() {
      clearCalendarEvents();
      vm.filteredPublicOffers = vm.publicOffers;
      vm.filteredOffersToMe = vm.offersToMe;
      vm.filteredOffersByMe = vm.offersByMe;
      vm.filtteredOffersToAuthorize = vm.offersToAuthorize;
    }

    function applyCalendarFilter() {
      clearCalendarEvents();
      vm.filteredPublicOffers = filterMonthOffers(vm.publicOffers.slice(), vm.selectedMonth);
      vm.filteredOffersToMe = filterMonthOffers(vm.offersToMe.slice(), vm.selectedMonth);
      vm.filteredOffersByMe = filterMonthOffers(vm.offersByMe.slice(), vm.selectedMonth);
      vm.filtteredOffersToAuthorize = filterMonthOffers(vm.offersToAuthorize.slice(), vm.selectedMonth);
    }

    function slideHasChanged(tabIndex) {
      vm.tabIndex = tabIndex;

      // if(vm.displayCalendar) applyCalendarFilter();
      skipEmptyTab();
      updateTabContent(tabIndex);
    }

    function skipEmptyTab(){
        if (vm.filtteredOffersToAuthorize.length > 0) {
          vm.tabIndex = 3
        } else if (vm.filteredOffersToMe.length + vm.filteredPublicOffers.length > 0) {
          vm.tabIndex = 0;
        } else if (vm.filteredOffersByMe.length > 0) {
          vm.tabIndex = 1;
        };
    }

    function updateTabContent(tabIndex) {
      if (vm.displayCalendar)
        applyCalendarFilter();

      vm.tabIndex = tabIndex;
      switch (tabIndex) {

        case 0:
          vm.calendarOffers = vm.filteredOffersToMe.concat(vm.filteredPublicOffers);
          break;

        case 1:
          vm.calendarOffers = vm.filteredOffersByMe;
          break;

        case 2:
          vm.calendarOffers = vm.filtteredOffersToAuthorize;
          break;
      }
      updateCounters();
    }

    function updateCounters(){
      vm.numToMe = vm.filteredOffersToMe.concat(vm.filteredPublicOffers).length;
      vm.numByMe = vm.filteredOffersByMe.length;
      vm.numToAuthorize = vm.filtteredOffersToAuthorize.length;
    }

    function toggleCalendar() {
      vm.displayCalendar = !vm.displayCalendar;
      if (vm.displayCalendar) {
        resetCalendarMonth();
        applyCalendarFilter();
        //skipEmptyTab();
        updateTabContent(vm.tabIndex);
      } else {
        removeCalendarFilter();
        //skipEmptyTab();
        updateTabContent(vm.tabIndex);
      }
    }

    function resetCalendarMonth() {
      vm.selectedMonth = moment();
    }

    function clearCalendarEvents() {
      vm.filteredPublicOffers = [];
      vm.filteredOffersToMe = [];
      vm.filteredOffersByMe = [];
      vm.filtteredOffersToAuthorize = [];
    }

    function changeMonth(month) {

      $timeout(function () {

          vm.selectedMonth = month;
          applyCalendarFilter();
          // $rootScope.$broadcast('MONTH_CHANGED', month);
          console.log(vm.tabIndex);
          //skipEmptyTab();
          updateTabContent(vm.tabIndex);

      }, 100);

    }

    function filterMonthOffers(offers, month) {
      var monthOffers = [];
      for (var i = 0; i < offers.length; i++) {
        var event = offers[i].getFromUserProposals()[0].proposable;
        if (event) {
          var start = moment(event.start);
          var end = moment(event.end);

          if (start.format('YYYY-MM') == month.format('YYYY-MM')) {
            monthOffers.push(offers[i]);
          }
        }
      }
      vm.selectedMonth = month;
      return monthOffers;
    }

    function filterDayOffers(offers, date) {
      vm.selectedMonthOffers = [];
      var date = moment(date);
      var offerarray = [];
      vm.filteredOffersToMe = [];
      vm.filteredOffersByMe = [];
      vm.filtteredOffersToAuthorize = [];
      vm.filteredPublicOffers = [];

      offers.forEach(function (offer) {
        var event = offer.getFromUserProposals()[0].proposable;
        var start = moment(event.start);
        var end = moment(event.end);
        var eventStartDay = moment(event.start).startOf('day');
        var eventEndDay = moment(event.end).startOf('day');
        var calendarDate = moment(date).startOf('day');

        if (eventStartDay <= calendarDate && calendarDate <= eventEndDay) {
          switch (vm.tabIndex) {
            case 0:
              offerarray.push(offer);
              vm.filteredOffersToMe = offerarray;
            break;
            case 1:
              offerarray.push(offer);
              vm.filteredOffersByMe = offerarray;
              break;
            case 2:
              offerarray.push(offer);
              vm.filtteredOffersToAuthorize = offerarray;
            break;
          }
          //vm.selectedMonthOffers.push(offer);
        }
      });
    }

    function getStatus(offer) {
      var transaction = offer.getMyTransaction();
      if (transaction.to_approved && transaction.from_approved) {
        return 'Aguarda Autorização';
      }
    }

    function cancelOfferManually(offerId) {
      $mdsqre.requester().offersv2(offerId).cancel().save()
        .then(function (cancelResponse) {
          $log.log(cancelResponse);
          $state.go('*.events.offers.list');
        })
        .catch(function (error) {
          $log.log(error);
        })
    }

  }

})();
