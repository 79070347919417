(function() {
  'use strict';

  angular
    .module('medsquire.events.presence.quarrel')
    .controller('QuarrelCreateController', QuarrelCreateController);

  /* @ngInject */
  function QuarrelCreateController($q, $state, $filter, MdsqreApiEvents, MdsqreApiShifts, $ionicPopup,
    $log, MdsqreAuth) {
    var vm = this;
    vm.title = 'Contestação';
    vm.user = {};
    vm.avaliatorRate = 0;
    vm.eventRate = 0;
    vm.event = {};

    //vm.createQuarrel = createQuarrel;
    vm.getEventState = getEventState;

    //vm.eventLoaded = false;
    //vm.userLoaded = false;

    activate();

    ////////////////

    function activate() {
      vm.user = MdsqreAuth.getUser();
      vm.userLoaded = true;
      fetchEvent($state.params.eventId).then(
        function(response) {
          vm.eventLoaded = true;
        }
      );
    }

    function fetchEvent(eventId) {
      var deferred = $q.defer();
      MdsqreApiEvents.view(eventId)
      .then(function(eventResponse) {
        vm.event = eventResponse.data;
        vm.avaliatorRate = vm.event.getPresence('checkout', true).additional_info.rating.avaliator.rate;
        vm.eventRate = vm.event.getPresence('checkout', true).additional_info.rating.event.rate;

        var checkin = vm.event.getPresence('checkin', true);
        vm.event.checkinTime = vm.event.getPresence('checkin', true).time.format('D MMM, ddd | HH:mm');

        var checkout = vm.event.getPresence('checkout', true);
        vm.event.checkoutTime = vm.event.getPresence('checkout', true).time.format('D MMM, ddd | HH:mm');
        vm.event.Activities = checkout.additional_info.tags.join(', ');

        getCheckoutInfo();

        console.log(vm.rate);
        deferred.resolve(eventResponse);
      })
      .catch(function(error) {
        $log.log(error);
      });
      return deferred.promise;
    }

    /*function createQuarrel() {
      var event = vm.event;
      var shift = { id: event.id, contest_reason: vm.contest_reason };
      MdsqreApiShifts.contest(shift)
      .then(function(contestResponse) {
        $state.go('*.events.view', { eventId: $state.params.eventId, action: 'contested' });
      })
      .catch(function(error) {
        $ionicPopup.alert({
          title: 'Aconteceu algo errado...',
          template: 'Erro: ' + error.print()
        });
        $state.go('*.events.view', { eventId: $state.params.eventId });
      });
    }*/

    function getEventState() {
      if (!vm.event.eventable) {
        return { 'state': '', 'class': '' };
      }
      var filter = 'eventStateFilter';
      var state = vm.event.state;
      var css = 'state-default';
      if (vm.event.eventable.state == 'assigned' && vm.event.state != 'in_progress' && vm.event.state != 'finished') {
        filter = 'shiftStateFilter';
        state = vm.event.eventable.state;
      }
      if (vm.event.eventable.state == 'validated') {
        filter = 'eventStateFilter';
        state = vm.event.state;
        css = 'state-ok';
      }
      if (vm.event.eventable.state == 'canceled') {
        filter = 'shiftStateFilter';
        state = vm.event.eventable.state;
        css = 'state-alert';
      }
      if (vm.event.eventable.state == 'contested') {
        filter = 'shiftStateFilter';
        state = vm.event.eventable.state;
        css = 'state-alert';
      }
      return { 'state': $filter(filter)(state), 'class': css };
    }

    function getCheckoutInfo() {
      var checkout = vm.event.getPresence('checkout', true);
      vm.event.checkoutTime = vm.event.getPresence('checkout', true).time.format('D MMM, ddd | HH:mm');
      vm.event.evaluatorName = checkout.additional_info.avaliator.name;
      vm.event.attendantEvaluatorRating = checkout.additional_info.rating.avaliator.rate;
      vm.event.attendantEventRating = checkout.additional_info.rating.event.rate;
      vm.event.Activities = checkout.additional_info.tags.join(', ');
    }

  }
})();
