(function() {
  'use strict';

  angular
    .module('medsquire.user.settings.personal')
    .controller('UserSettingsPersonalController', UserSettingsPersonalController);

  /* @ngInject */
  function UserSettingsPersonalController(MdsqreApi, CoreEnvironment, _UserModel, $log) {
    var vm = this;
    vm.title = 'Dados Pessoais';

    activate();

    ////////////////

    function activate() {
      fetchMe();
    }

    function fetchMe() {
      MdsqreApi.getMe()
      .then(function(meResponse) {
        $log.log('USER', meResponse.data);
        vm.user = meResponse.data;
      })
      .catch(function(error) {
        $log.error(error);
      })
      .finally(function() {
        // body...
      });
    }
  }
})();
