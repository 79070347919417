(function() {
  'use strict';

  angular
    .module('medsquire.events.presence.checkout')
    .factory('PresenceCheckoutService', PresenceCheckoutService);

  /* @ngInject */
  function PresenceCheckoutService(MdsqreRemapper) {
    var service = {
      tags: [],
      monitor: {},
      rating: {},
      checkoutEmergencial: false,

      setEvent: setEvent,
      setTags: setTags,
      setMonitor: setMonitor,
      setCustomMonitor: setCustomMonitor,      
      setRating: setRating,
      setEmergencyCheckout: setEmergencyCheckout,
      getEvent: getEvent,
      getTags: getTags,
      getMonitor: getMonitor,
      getRating: getRating,

      isEmergencyCheckout: isEmergencyCheckout,
      reset: reset
    };
    return service;

    ////////////////

    function setEvent(event) {
      this.event = event;
      return this;
    }

    function setTags(tags) {
      this.tags = tags;
      return this;
    }

    function setMonitor(monitor) {
      this.monitor = monitor;
      return this;
    }

    function setEmergencyCheckout(isEmergency){
      this.checkoutEmergencial = isEmergency;
      return this;
    }

    function isEmergencyCheckout(){
      return this.checkoutEmergencial;
    }

    function setCustomMonitor(monitor) {
      this.monitor = MdsqreRemapper.user(monitor);
      return this;
    }

    function setRating(rating) {
      this.rating = rating;
      return this;
    }

    function getEvent() {
      return this.event;
    }

    function getTags() {
      return this.tags;
    }

    function getMonitor() {
      return this.monitor;
    }

    function getRating() {
      return this.rating;
    }

    function reset() {
      this.serEvent = null;
      this.tags = [];
      this.monitor = {};
      this.rating = {};
      this.checkoutEmergencial = false;
    }
  }
})();
