(function () {
  'use strict';

  angular
    .module('medsquire.interns')
    .config(settingsRoutes)

  function settingsRoutes($stateProvider) {

    $stateProvider.state('int.settings', {
        url: '/settings',
        abstract: true,
        views: {
          'tab-settings': {
            template: '<ion-nav-view name="tab-settings-main"></ion-nav-view>'
          }
        }
      })

      .state('int.settings.home', {
        url: '/home',
        views: {
          'tab-settings-main': {
            templateUrl: 'shared/modules/settings/settings.html',
            controller: 'SettingsController',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.settings.user', {
        url: '/user',
        abstract: true,
        views: {
          'tab-settings-main': {
            template: '<ion-nav-view name="nav-user-settings"></ion-nav-view>'
          }
        }
      })

      .state('int.settings.user.personal', {
        url: '/personal',
        views: {
          'nav-user-settings': {
            templateUrl: 'shared/modules/settings/user/personal/personal.html',
            controller: 'SettingsUserPersonalController',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.settings.user.password_reset', {
        url: '/password_reset',
        views: {
          'nav-user-settings': {
            templateUrl: 'interns/password_reset/password_reset.html',
            controller: 'InternsPasswordResetController',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.settings.preferences', {
        url: '/preferences',
        cache: false,
        views: {
          'tab-settings-main': {
            templateUrl: 'shared/modules/settings/preferences/preferences.html',
            controller: 'SettingsPreferencesController',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.settings.help', {
        url: '/help',
        views: {
          'tab-settings-main': {
            templateUrl: 'shared/modules/settings/help/help.html',
            controller: 'HelpController',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.settings.terms', {
        url: '/terms',
        views: {
          'tab-settings-main': {
            templateUrl: 'interns/terms/terms.html',
            controller: 'InternsTermsController',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.settings.developer', {
        cache: false,
        url: '/developer',
        views: {
          'tab-settings-main': {
            templateUrl: 'shared/modules/settings/developer/developer.html',
            controller: 'DeveloperController',
            controllerAs: 'vm'
          }
        }
      })

  }
})();
