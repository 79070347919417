(function() {
  'use strict';

  angular
    .module('medsquire.events.contestation')
    .controller('EventContestationController', EventContestationController);

  /* @ngInject */
  function EventContestationController($state, $filter, MdsqreApiEvents, MdsqreApiShifts, $ionicPopup, blockUI,
    $log, MdsqreAuth, MdsqreHelpers) {
    var vm = this;
    vm.title = 'Contestação';
    vm.user = {};
    vm.avaliatorRate = 0;
    vm.eventRate = 0;
    vm.event = {};
    vm.labels = {};

    vm.saveContestation = saveContestation;
    vm.resolveContestation = resolveContestation;
    vm.commentContestation = commentContestation;
    vm.getEventState = getEventState;
    vm.MdsqreHelpers = MdsqreHelpers;

    vm.dateFormat = dateFormat;


    activate();

    ////////////////

    function activate() {
      vm.user = MdsqreAuth.getUser();
      if ($state.params.action == 'resolve') {
        vm.labels.ActionTitle = 'Comentário';
        vm.labels.ButtonText = 'Resolver Contestação';
      } else {
        vm.labels.ActionTitle = 'Justificativa';
        vm.labels.ButtonText = 'Contestar Atividade';
      }
      fetchEvent($state.params.eventId);
    }

    function fetchEvent(eventId) {
      MdsqreApiEvents.view(eventId)
      .then(function(eventResponse) {
        vm.event = eventResponse.data;
        vm.avaliatorRate = vm.event.getPresence('checkout', true).additional_info.rating.avaliator.rate;
        vm.eventRate = vm.event.getPresence('checkout', true).additional_info.rating.event.rate;

        var checkin = vm.event.getPresence('checkin', true);
        vm.event.checkinTime = vm.event.getPresence('checkin', true).time.format('D MMM, ddd | HH:mm');

        var checkout = vm.event.getPresence('checkout', true);
        vm.event.checkoutTime = vm.event.getPresence('checkout', true).time.format('D MMM, ddd | HH:mm');
        vm.event.Activities = checkout.additional_info.tags.join(', ');

        getCheckoutInfo();

        console.log(vm.rate);
      })
      .catch(function(error) {
        $log.log(error);
      });
    }

    function saveContestation() {
      var event = vm.event;
      console.log(event);
      if (event.eventable_type !== 'shift_event') {
        $ionicPopup.alert({
          title: 'Erro ao contestar:',
          template: 'O evento não é um plantão',
        });
        return;
      }
      if ($state.params.action == 'resolve') {
        var shift = { id: event.id, contest_solution: vm.contest_reason };
        var actionFunction = MdsqreApiShifts.contestResolve;
      } else {
        var shift = { id: event.id, contest_reason: vm.contest_reason };
        var actionFunction = MdsqreApiShifts.contest;
      }

      blockUI.start();
      actionFunction(shift)
      .then(function(response) {
        if ($state.params.action == 'resolve') {
          return confirmPresence();
        }
        return true;
      })
      .then(function(response) {
        $state.go('*.events.view', { eventId: $state.params.eventId, action: 'contested' });
      })
      .catch(function(error) {
        $ionicPopup.alert({
          title: 'Aconteceu algo errado...',
          template: 'Erro: ' + error.print()
        });
        $state.go('*.events.view', { eventId: $state.params.eventId });
      })
      .finally(function() {
        blockUI.stop();
      });
    }

    function dateFormat(date){
      return moment(date, "YY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
    }

    function getEventState() {
      if (!vm.event.eventable) {
        return { 'state': '', 'class': '' };
      }
      var filter = 'eventStateFilter';
      var state = vm.event.state;
      var css = 'state-default';
      if (vm.event.eventable.state == 'assigned' && vm.event.state != 'in_progress' && vm.event.state != 'finished') {
        filter = 'shiftStateFilter';
        state = vm.event.eventable.state;
      }
      if (vm.event.eventable.state == 'validated') {
        filter = 'eventStateFilter';
        state = vm.event.state;
        css = 'state-ok';
      }
      if (vm.event.eventable.state == 'canceled') {
        filter = 'shiftStateFilter';
        state = vm.event.eventable.state;
        css = 'state-alert';
      }
      if (vm.event.eventable.state == 'contested') {
        filter = 'shiftStateFilter';
        state = vm.event.eventable.state;
        css = 'state-alert';
      }
      return { 'state': $filter(filter)(state), 'class': css };
    }

    function getCheckoutInfo() {
      var checkout = vm.event.getPresence('checkout', true);
      vm.event.checkoutTime = vm.event.getPresence('checkout', true).time.format('D MMM, ddd | HH:mm');
      vm.event.evaluatorName = checkout.additional_info.avaliator.name;
      vm.event.attendantEvaluatorRating = checkout.additional_info.rating.avaliator.rate;
      vm.event.attendantEventRating = checkout.additional_info.rating.event.rate;
      vm.event.Activities = checkout.additional_info.tags.join(', ');
    }

    function confirmPresence() {
      var presence = {
        event_id: $state.params.eventId,
        time: moment().format('YYYY-MM-DD HH:mm:ss'),
        type: 'presence_confirmation',
      };
      presence.additional_info = {
        avaliator: vm.user.toObject(),
        obs: vm.contest_reason,
      };
      return MdsqreApiEvents.presence(presence);
    }







  }
})();
