(function() {
  'use strict';

  angular
    .module('medsquire.events.offers.create')
    .factory('OffersCreateService', OffersCreateService);

  /* @ngInject */
  function OffersCreateService(MdsqreRemapper, MdsqreAuth, $log) {
    var service = {
      event: {},
      type: '',
      recipients: [],
      user: {},

      setEvent: setEvent,
      getEvent: getEvent,
      setType: setType,
      getType: getType,
      setRecipients: setRecipients,
      getRecipients: getRecipients,
      reset: reset,
      report: report,
      setUser: setUser,
      getUser: getUser
    };
    return service;

    ////////////////

    function setUser() {
      this.user = MdsqreAuth.getUser();
    }

    function getUser() {
      return this.user;
    }

    function setEvent(event) {
      this.event = event;
      return this;
    }

    function getEvent() {
      return this.event;
    }

    function setType(offerType) {
      this.type = offerType;
      return this;
    }

    function getType() {
      return this.type;
    }

    function setRecipients(recipients) {
      this.recipients = recipients;
      return this;
    }

    function getRecipients() {
      return this.recipients;
    }

    function reset() {
      this.event = {};
      this.recipients = [];
      this.setUser();
    }

    function report() {
      $log.log('==== OFFER ===========');
      $log.log('Event Offered: ', this.event);
      $log.log('Offer Type: ', this.type);
      $log.log('Recipients: ', this.recipients);
      $log.log('======================');
    }



  }
})();
