(function() {
    'use strict';

    angular
      .module('medsquire.directives.medsquire-event-details')
      .directive('medsquireEventDetails', medsquireEventDetails);

    function medsquireEventDetails($filter, $state) {
      var directive = {

        link: link,
        restrict: 'E',
        replace: true,
        controller: controller,
        scope: {
          event: '=',
          options: '=?',
        },
        templateUrl: 'shared/directives/event-view/event-details/medsquire-event-details.html'
      };
      return directive;

      function controller($scope) {

        if (angular.isUndefined($scope.options)) {
          $scope.options = {};
        }

        $scope.opt = $scope.options;

      }

      function link(scope, element, attrs) {

        scope.formatCurrency = formatCurrency;

        scope.eventStart = scope.event.getStart().format('D MMM, ddd | HH:mm');
        scope.eventEnd = scope.event.getEnd().format('HH:mm');
        scope.hasPaymentInfo = scope.event.hasPaymentInfo();
        scope.paymentDate = scope.event.getPaymentDate('D MMM');

        if (scope.event.hasPaymentInfo()) {

          if (scope.event.canViewActualValues()) {
            scope.showActualValues = true;
            scope.netValue = scope.event.service.total - (scope.event.service.total * 0.275);
            scope.netValueHour = scope.netValue / scope.event.service.summary.qty;
          }

          if (scope.event.canViewAuditValues()) {
            scope.showAuditValues = true;
          }

        }

        function formatCurrency(val) {
          val = parseFloat(val);
          return val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }
      }
    }
  })();
