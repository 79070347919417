(function () {
  'use strict';

  angular
    .module('medsquire.events.offers.create.step1')
    .controller('OffersCreateStep1Controller', OffersCreateStep1Controller);

  /* @ngInject */
  function OffersCreateStep1Controller($state, $scope, $q, $window, MdsqreApiUsers, MdsqreHelpers, MdsqreApiEventOffers, MdsqreAuth, OffersCreateService, $log, $ionicPopup) {
    var vm = this;
    vm.title = 'Oferecer Para';
    vm.recipients = [];
    vm.event = {};
    vm.offer = OffersCreateService;

    vm.setRecipients = setRecipients;
    vm.shareOnWhatsApp = shareOnWhatsApp;

    activate();

    ////////////////

    function activate() {
        vm.context = MdsqreHelpers.getContext();

        vm.user = MdsqreAuth.getUser();

        vm.event = vm.offer.getEvent();
        vm.offerType = vm.offer.getType();
        fetchPossibleRecipients();
        if (vm.offerType == 'transfer') {
          vm.title = 'Passar Para';
          vm.offerTypeLabel = 'PASSA';
        } else if (vm.offerType == 'exchange') {
          vm.title = 'Trocar Com';
          vm.offerTypeLabel = 'TROCA';
        } else if (vm.offerType == 'offer') {
          vm.title = 'Oferecer para';
          vm.offerTypeLabel = 'OFERECE';
        } else if (vm.offerType == 'assign') {
          vm.title = 'Atribuir a';
          vm.offerTypeLabel = 'ATRIBUI';
        }
        //(vm.offerType == 'transfer') ? vm.offerTypeLabel = 'PASSA': vm.offerTypeLabel = 'TROCA';
    }

    function fetchPossibleRecipients() {
      MdsqreApiUsers.list({
          byRole: 'medic',
          byOrganization: vm.event.organization_id
        })
        .then(function (usersResponse) {
          $log.log(usersResponse);
          vm.recipients = usersResponse.data.filter(function (recipient) {
            //var monitorId = getSubjectWithRole("monitor");
            //var auditorId = getSubjectWithRole("auditor");
            //return (recipient.id !== vm.user.id && recipient.id !== monitorId && recipient.id !== auditorId);
            return (recipient.id !== vm.user.id);
          })
        })
        .catch(function (error) {
          $log.log(error);
        });
    }

    function getSubjectWithRole(role){
      var subject = vm.event.subjects.find(function(subject){
        return (subject.pivot.role == role);
      })
      return subject.pivot.user_id;
    }

    function setRecipients(recipient) {
      vm.offer.setRecipients(recipient);
      $state.go('*.events.offers.create.step2');
    }

    function shareOnWhatsApp() {

        var context = MdsqreHelpers.getContext();
        var splitUrl = $window.location.href.split("/");
        var domain = splitUrl[0] + "//" + splitUrl[2]

        var offerVerb = 'TROCO';
        var offerInfinitive = 'TROCAR';
        if (vm.offerType == 'transfer') {
          offerVerb = 'PASSO';
          offerInfinitive = 'PASSAR';
        }

        var offerText = [
          "*" + offerVerb + " ATIVIDADE*\n",
          "*MedSquire*\n\n",
          "*" + vm.user.name + "*\n",
          "precisa " + offerInfinitive + " a atividade abaixo.\n",
          "--------------------------\n",
          "*" + vm.event.getStart().format('D MMM Y, ddd | HH:mm') + "*\n",
          vm.event.location.name + "\n",
          "--------------------------\n",
          "Ver detalhes:\n"
        ].join('');

        var offerUrl = domain + '/#/' + context.short + '/home?route=' + context.short + '.events.offers.view&param=offerId&value=' + offerId;

        window.open("www.3dtotal.com",'_blank');

    }

  }
})();
