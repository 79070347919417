(function() {
  'use strict';

  angular
    .module('medsquire.events.exchanges.list')
    .controller('exchangesListController', exchangesListController);

  /* @ngInject */
  function exchangesListController($q, $scope, $state, $filter, MdsqreApiEvents, MdsqreApiMe, TaskQueueService, CoreEnvironment, $log,
    $sessionStorage, MdsqreAuth) {
    var vm = this;
    vm.title = 'Trocas';
    vm.user = {};
    vm.event = {};
    vm.filteredEvents = [];
    vm.shiftOffersByMe = [];
    vm.shiftOffersForMe = [];

    activate();

    function activate() {
      vm.user = MdsqreAuth.getUser();

      MdsqreApiMe.events()
        .then(function(eventsResponse) {
          console.log(eventsResponse);
          //vm.filteredEvents.push(eventsResponse.data.filter(doesntBelongToMe));
          //vm.filteredEvents.push(eventsResponse.data.filter(belongsToMe));

          vm.filteredEvents.push(eventsResponse.data);

          var i =0;

          for(i=0;i<vm.filteredEvents[0].length && (i < 10);i++){
              var eventData = vm.filteredEvents[0][i];
              var obj =
              { created_at:eventData.created_at,
                dateFormat:"DD/MM/YYYY HH:mm",
                event:eventData
              };
              if(i < 5){
                vm.shiftOffersForMe.push(obj);
              }
              else {
                vm.shiftOffersByMe.push(obj);
              }
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(function() {
          // Stop the ion-refresher from spinning
          $log.log('finally executed');
        });
      //initSlider();
    }
  }

})();
