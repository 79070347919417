(function() {
  'use strict';

  angular
    .module('medsquire.events.exchanges.view')
    .controller('exchangesViewController', exchangesViewController);

  /* @ngInject */
  function exchangesViewController($q, $scope, $state, $filter, MdsqreApiEvents, MdsqreApiMe, TaskQueueService, CoreEnvironment, $log,
    $sessionStorage, MdsqreAuth) {
      var vm = this;
      vm.title = 'Histórico';
      vm.user = {};
      vm.event = {};
      vm.ratings = {};
      vm.ratings.event = {};

      vm.savePresence = savePresence;
      vm.getPresenceTime = getPresenceTime;
      vm.getEventState = getEventState;

      // Fake
      vm.exchange = {};

      vm.exchange.shift = {};

      vm.hasShiftBack = false;

      activate();

      function activate() {
        vm.user = MdsqreAuth.getUser();
        //initSlider();
        fetchEvent($state.params.eventId).then(
          function(response) {
            goToStartingSlider();
          }
        );
      }

      function fetchEvent(eventId) {
          var deferred = $q.defer();
          MdsqreApiEvents.view(eventId)
          .then(function(eventResponse) {
            vm.event = eventResponse.data;
            angular.copy(vm.event, vm.exchange.shift);
            vm.exchange.newMedicName = "Zé Ruela";
            vm.exchange.oldMedicName = "Zé Ruela";
            vm.exchange.offerDate = "25/12/2017 19:00";
            vm.hasShiftBack = Math.random() >= 0.5; //Para variar a Simulação
            if(vm.hasShiftBack){
              vm.exchange.shiftBack = {};
              angular.copy(vm.event, vm.exchange.shiftBack);
              vm.exchange.shiftBack.attendants[0].name = "Zé Ruela";
              var t=vm.t().format('D MMM Y, ddd | HH:mm');
            }

            console.log('EVENT', vm.event);
            deferred.resolve(eventResponse);
          })
          .catch(function(error) {
            $log.log(error);
            deferred.reject(error);
          });
          return deferred.promise;
      }

      function goToStartingSlider() {
        /*if (vm.event.canCheckin() || vm.event.canCheckout() || vm.event.canEvaluate() || vm.event.eventable.state == 'contested') {
          goToSlide(1);
        } else if (vm.event.hasConfirmedPresence()) {
          goToSlide(1);
        } else if ($state.params.action === 'checked_out') {
          goToSlide(1);
        } else if ($state.params.action === 'contested') {
          goToSlide(1);
        }*/
      }

      function initSlider() {
        vm.sliderOptions = {
          effect: 'slide',
          initialSlide: 0,
          onInit: function(slider) {
            vm.slider = slider;
          },
          onSlideChangeEnd: function(slider) {
            vm.activeIndex = slider.activeIndex;
          }
        };
      }

      function getPresenceTime(type) {
        var time = '';
        vm.event.presences.forEach(function(presence) {
          if (presence.type == type) {
            time = presence.time;
          }
        });
        return time;
      }

      function getEventState() {
        if (!vm.event.eventable) {
          return { 'state': '', 'class': '' };
        }
        var filter = 'eventStateFilter';
        var state = vm.event.state;
        var css = 'state-default';
        if (vm.event.eventable.state == 'assigned' && vm.event.state != 'in_progress' && vm.event.state != 'finished') {
          filter = 'shiftStateFilter';
          state = vm.event.eventable.state;
        }
        if (vm.event.eventable.state == 'canceled') {
          filter = 'shiftStateFilter';
          state = vm.event.eventable.state;
          css = 'state-alert';
        }
        return { 'state': $filter(filter)(state), 'class': css };
      }

      function savePresence(type) {
        var presence = {
          event_id: vm.event.id,
          time: moment().format('YYYY-MM-DD HH:mm:ss'),
          type: type,
        };

        MdsqreApiEvents.presence(presence)
          .then(function(presenceResponse) {
            $log.log(presenceResponse);
          })
          .catch(function(error) {
            $log.error(error);
          })
          .finally(function() {
            fetchEvent($state.params.eventId);
          });
      }

      function goToSlide(slideNum) {
        vm.slider.slideTo(2, 500);
      }
    }


  })();
