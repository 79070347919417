(function () {
  'use strict';

  angular
    .module('medsquire.directives.medsquire-offer-info-v2')
    .directive('medsquireOfferInfoV2', medsquireOfferInfoV2);

  function medsquireOfferInfoV2($q, $window, $rootScope, $state, $log, $ionicPopup, $mdsqre,
    MdsqreApiUsers, MdsqreApiEventOffers, MdsqreHelpers, MdsqreRemapper, ExchangesAcceptService, MdsqreApiEvents) {

    var directive = {
      link: link,
      restrict: 'E',
      replace: true,
      controller: controller,
      scope: {
        offer: '=?',
        event: '=?',
        user: '=?',
        options: '=?',
        extras: '='
      },
      templateUrl: 'shared/directives/offer-info-v2/medsquire-offer-info-v2.html'
    };

    return directive;

    function controller($scope) {

      if (!$scope.offer) {
        $scope.offer = $scope.event.offersv2[0];
      }

      if($scope.offer.getFromUserProposals()[0]) {
        if (!$scope.event) {
          $scope.event = $scope.offer.getFromUserProposals()[0].proposable;
        }
      }

      if (!$scope.fromUserProposal) {
        $scope.fromUserProposal = $scope.offer.getFromUserProposals()[0];
      }

      $log.log('OFFER', $scope.offer);

      $scope.opt = setDefaultOptions($scope);

      $scope.messages = getMessages($scope);

      if (!$scope.opt.simplify) {
        getProposingSubjects($scope);
      }

      switch ($scope.offer.type) {
        case 'assign':
          if ($scope.offer.isPublicOffer()) {
            $scope.titleLabel = 'OFERTA / PÚBLICA';
          } else {
            $scope.titleLabel = 'OFERTA / PRIVADA';
          }
          break;
        case 'transfer':
          if ($scope.offer.isPublicOffer()) {
            $scope.titleLabel = 'PASSA / PÚBLICA';
          } else {
            $scope.titleLabel = 'PASSA / PRIVADA';
          }
          break;
          //$scope.titleLabel = 'PASSA';

        case 'exchange':
          if ($scope.offer.isPublicOffer()) {
            $scope.titleLabel = 'TROCA / PÚBLICA';
          } else {
            $scope.titleLabel = 'TROCA / PRIVADA';
          }
          //$scope.titleLabel = 'TROCA';
          break;
      }

      // $scope.myProposal = $scope.offer.getMyProposals()[0];
      // if ($scope.myProposal || $scope.fromUserProposal) {
      //   $scope.canAuthorize = $scope.offer.canAuthorize();
      //   $log.log('CAN AUTH', $scope.canAuthorize);
      // }

    }

    function getProposingSubjects($scope) {
      var proposingSubjects = $scope.offer.getSubjectsWithRole('to_user');
      var foundProposal = false;
      if (proposingSubjects.length > 0) {
        proposingSubjects.forEach(function(subject, i) {
          proposingSubjects[i].proposals = ($scope.offer.proposals.filter(function(proposal) {
            return (
              proposal.by_user_id == subject.id
              && proposal.by_user_id != $scope.offer.by_user_id
              && !proposal.canceled_at
              && !proposal.deleted_at
            );
          }));
          if (proposingSubjects[i].proposals.length > 0) {
            foundProposal = true;
            proposingSubjects[i].messages = getMessages($scope);
          }
        });
        if (foundProposal) {
          $scope.proposingSubjects = proposingSubjects;
        }
      }
      if ($scope.user && $scope.proposingSubjects && $scope.offer.state != 'completed' && $scope.offer.state != 'canceled') {
        $scope.showProposals = true;
      };
      $log.log('PROPOSING SUBJECTS', proposingSubjects);
    }

    function getMessages($scope) {

      var messages = {
        hasDone: '',
        mustDo: '',
        offeredTo: '',
        wantsActivity: ''
      };

      var offer = $scope.offer;

      //alert(offer.to_user_id);
      //alert('USER ID ' + $scope.user.id);


      $log.log('VIEWING AS', offer.viewingAs());


      switch (offer.viewingAs()) {

        case 'originator':
          if (offer.isWaitingConfirmation()) {
            messages.mustDo = 'Aguarda sua aceitação';
          } else if (offer.isWaitingAuthorization()) {
            messages.mustDo = 'Aguarda autorização';
          }
          break;

        case 'subject':
          if (offer.isWaitingConfirmation()) {
            messages.mustDo = 'Aguarda aceitação de ' + offer.from_user.name;
          } else if (offer.isWaitingAuthorization()) {
            messages.mustDo = 'Aguarda autorização';
          }
          break;

        case 'authorizer':
          if (offer.canAuthorize()) {
            messages.mustDo = 'Aguarda sua autorização';
          }
          break;

      }

      if (offer.isPublicOffer()) {
        messages.offeredTo = 'Oferta pública';
      } else {
        if ($scope.user && $scope.user.id == offer.to_user_id) {
          messages.offeredTo = 'Oferecido para você';
        } else {
          messages.offeredTo = 'Oferecido para ' + offer.to_user.name;
        }
      }

      return messages;
    };

    function link(scope, element, attrs) {

      scope.eventId = scope.event.id;
      scope.getSubjectNames = getSubjectNames;

      scope.getActiveSubject = getActiveSubject;
      scope.fetchRecipient = fetchRecipient;
      scope.shareOnWhatsApp = shareOnWhatsApp;

      scope.cancelOffer = cancelOffer;
      scope.acceptOffer = acceptOffer;
      scope.rejectOffer = rejectOffer;
      scope.acceptProposal = acceptProposal;
      scope.rejectProposal = rejectProposal;
      scope.denyTransaction = denyTransaction;
      scope.authorizeTransaction = authorizeTransaction;

      scope.login = login;

      scope.recipients = null;

      $log.log('MESS', scope.messages);

      scope.acceptProposal = acceptProposal;

      scope.getStart = getStart;
      scope.getEnd = getEnd;


      function getStart() {
        return moment(scope.event.start, 'yyyy-dd-mm hh:mm:ss');
      }

      function getEnd() {
        return moment(scope.event.end, 'yyyy-dd-mm hh:mm:ss');
      }

      function getSubjectNames() {
        var subjectNames = '';
        if (scope.offer.subjects) {
          scope.offer.subjects.forEach(function (subject) {
            subjectNames += subject.name + ', ';
          });
        }
        return subjectNames.slice(0, -2);
      }

      function getActiveSubject() {
        var subject;
        if (scope.proposal) {
          var subject = scope.offer.subjects.find(function (subject) {
            return subject.id == scope.proposal.to_attendant_id;
          });
        } else {
          subject = scope.offer.subjects[0];
        }
        return subject;
      }

      function cancelOffer() {
        var confirmPopup = $ionicPopup.confirm({
          title: 'Cancelar',
          template: 'Cancelar a oferta?',
          cancelText: 'Não',
          okText: 'Sim'
        }).then(function (res) {
          if (res) {
            $mdsqre.requester().offersv2(scope.offer.id).cancel().save()
              .then(function (cancelResponse) {
                $log.log(cancelResponse);
                $state.go('*.events.offers.list');
              })
              .catch(function (error) {
                $log.log(error);
              })
          }
        });
      }

      function acceptOffer() {

        $log.log('TYPE', scope.offer.type);

        if (scope.offer.type == 'exchange') {
          startExchangeAccept();
        }

        else if (scope.offer.type == 'transfer' || scope.offer.type == 'assign') {
          $mdsqre.requester().offersv2(scope.offer.id).proposals().save({
            proposals: [{
            type: 'attendant_slot',
            proposable_type: 'App\\User',
            proposable_id: scope.user.id,
            by_user_id: scope.user.id
          }],
            verifications: [{ type: 'confirm'}]
          })
          .then(function(response) {
            console.log(response);
            $state.reload();
          })
          .catch(function(error) {
            $log.log(error);
          });
        }

      }

      function rejectOffer() {
        var confirmPopup = $ionicPopup.confirm({
          title: 'Rejeitar',
          template: 'Rejeitar a oferta?',
          cancelText: 'Não',
          okText: 'Sim'
        }).then(function (res) {
          if (res) {
              $mdsqre.requester().offersv2(scope.offer.id).subjects(scope.user.id).reject().save()
              .then(function (response) {
                $log.log(response);
                $state.go('*.events.offers.list');
              })
              .catch(function (error) {
                $log.log(error);
              })
          }
        });
      }

      function acceptProposal(subjectId) {
        if (scope.offer.isPublicOffer()) {
          $mdsqre.requester().offersv2(scope.offer.id).subjects(subjectId).accept().save({
            verifications: [{ type: 'confirm' }]
          });
          $state.reload();
        } else {
          $mdsqre.requester().offersv2(scope.offer.id).verifications().save({ type: 'confirm' })
          .then(function(confirmationResponse) {
            console.log(confirmationResponse);
            $state.reload();
          })
          .catch(function(error) {
            $log.log(error);
          });
        }
      }

      function rejectProposal(subjectid) {
        var confirmPopup = $ionicPopup.confirm({
          title: 'Rejeitar',
          template: 'Rejeitar a oferta?',
          cancelText: 'Não',
          okText: 'Sim'
        }).then(function (res) {
          if (res) {
              $mdsqre.requester().offersv2(scope.offer.id).subjects(subjectid).reject().save()
              .then(function (response) {
                $log.log(response);
                $state.go('*.events.offers.list');
              })
              .catch(function (error) {
                $log.log(error);
              })
          }
        });
      }

      function authorizeTransaction(subjectId) {
        if (scope.offer.type == 'assign' && scope.offer.isPublicOffer() && subjectId) {
          // public assign is accepted and authorized in one step
          $mdsqre.requester().offersv2(scope.offer.id).subjetcs(subjectId).accept().save({ verifications: [{ type: 'authorize' }]});
        } else {
          $mdsqre.requester().offersv2(scope.offer.id).verifications().save({ type: 'authorize' })
          .then(function(response) {
            $ionicPopup.alert({
              title: 'Autorização',
              template: 'Solicitação autorizada!'
            });
            console.log(response);
            $state.go('*.events.offers.list');
          })
          .catch(function(error) {
            $log.log(error);
          });
        }
      }

      function denyTransaction() {
        $mdsqre.requester().offersv2(scope.offer.id).verifications().save({ type: 'deny' })
        .then(function (response) {
          $ionicPopup.alert({
            title: 'Autorização Negada',
            template: 'Solicitação cancelada!'
          });
          console.log(response);
          $state.go('*.events.list');
        })
        .catch(function (error) {
          $log.log(error);
        });
      }

      function startExchangeAccept() {
        ExchangesAcceptService.reset();
        ExchangesAcceptService.setOffer(scope.offer);
        ExchangesAcceptService.setOfferedEvent(scope.event);
        $state.go('*.events.list');
      }

      function fetchRecipient() {
        var deferred = $q.defer();
        var subject = getActiveSubject();
        MdsqreApiUsers.list({
            role: 'medic',
            id: subject.id
          })
          .then(function (usersResponse) {
            $log.log('USERS', usersResponse);
            scope.recipients = usersResponse.data.filter(function (recpt) {
              return recpt.id == subject.id;
            })
            deferred.resolve(scope.recipients);
          })
          .catch(function (error) {
            $log.log(error);
            deferred.reject(error);
          });
        return deferred.promise;
      }

      function shareOnWhatsApp(verbo) {

        var context = MdsqreHelpers.getContext();
        var splitUrl = $window.location.href.split("/");
        var domain = splitUrl[0] + "//" + splitUrl[2]

        if (domain.includes('localhost')) {
          domain = 'https://app-pro.medsquire.com';
        }

        fetchRecipient().then(function () {

          MdsqreApiEventOffers.view(scope.offer.id)
            .then(function (response) {
              var offer = response.data;
            })
            .catch(function (error) {
              $log.log(error);
            });

          var anoInicio = scope.event.getStart().format('YYYY');
          var mesInicio = scope.event.getStart().format('MM');
          var diaInicio = scope.event.getStart().format('DD');
          var horarioInicio = scope.event.getStart().format('HH:mm');
          var anoFim = scope.event.getEnd().format('YYYY');
          var mesFim = scope.event.getEnd().format('MM');
          var diaFim = scope.event.getEnd().format('DD');
          var horarioFim = scope.event.getEnd().format('HH:mm');

          if (scope.event.service) {
            var valorhora = parseFloat(scope.event.service.price).toFixed(2);
            var quantidade = parseInt(scope.event.service.qty);
            var total = quantidade * valorhora;
          }

          var fone = scope.recipients[0].contact.phone1;
          if (fone.length <= 11) {
            fone = "55" + fone;
          }

          var linkstring = encodeURIComponent(domain + '/#/' + context.short + '/home') + '&phone=' + fone;

          var actionText = "";
          if (verbo == 'CANCELAR')
            actionText = '*OFERTA CANCELADA* %0A--------------------------%0A *PASSO Plantão*: CLÍNICO%20%0A';
          else if (verbo == 'acceptOffer') {
            actionText = 'Ok! ASSUMO seu Plantão: CLÍNICO %0A--------------------------%0A';
          } else if (verbo == 'TROCAR') {
            actionText = 'Trocar Plantão: CLÍNICO %0A--------------------------%0A';
          } else {
            actionText = 'Passar Plantão: CLÍNICO %0A--------------------------%0A';
          }

          var resultUrl = 'https://api.whatsapp.com/send?text=' + actionText +
            '*Quando?* ' + diaInicio + '/' + mesInicio + '/' + anoInicio +
            ' ' + horarioInicio + 'h -' + horarioFim + 'h ' +
            '%0A*Onde?* ' + scope.event.location.name + ' ' +
            '%0A*Quanto* R$' + total + '(' + valorhora + '/h) ' +
            '%0A*Quem paga?* ' + 'Med5' + ' em ' + diaFim + '/' + mesFim + '/' + anoFim +
            '%0A--------------------------%0A*LINK:* ' + linkstring;

          scope.whatsappShareUrl = resultUrl;

        })

      }
    }

    function setDefaultOptions($scope) {

      if (angular.isUndefined($scope.options)) {
        $scope.options = {};
      }

      if (angular.isUndefined($scope.options.stateLabels)) {
        $scope.options.stateLabels = true;
      }

      if (angular.isUndefined($scope.options.pendingLabels)) {
        $scope.options.pendingLabels = true;
      }

      if (angular.isUndefined($scope.options.showSubjects)) {
        $scope.options.showSubjects = false;
      }

      if ($scope.user && $scope.offer.created_by != $scope.user.id) {
        // $scope.options.showSubjects = false;
      }

      return $scope.options;
    }

    function login() {
      $rootScope.$broadcast('medsquire.login.modal', { onSuccess: reloadAfterLogin });
    }

    function reloadAfterLogin() {
      $state.reload();
    }

  }
})();
