(function() {
    'use strict';

    angular
        .module('medsquire.core', [
          'medsquire.core.environment',
          'medsquire.core.bootstrap',
          'medsquire.core.events',
          'medsquire.settings'
        ]);
})();
