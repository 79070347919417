(function() {
  'use strict';

  angular
    .module('medsquire.interns')
    .config(internEventsRoutes)

  function internEventsRoutes($stateProvider) {

    $stateProvider.state('int.events', {
        url: '/events',
        abstract: true,
        cache: false,
        views: {
          'tab-home': {
            template: '<ion-nav-view name="nav-events-view"></ion-nav-view>'
          }
        }
      })

      .state('int.events.offers', {
        url: '/offers',
        abstract: true,
        views: {
          'nav-events-view': {
            template: '<ion-nav-view name="nav-offers-view"></ion-nav-view>'
          }
        }
      })

      .state('int.events.offers.list', {
        url: '/list',
        cache: false,
        views: {
          'nav-offers-view': {
            templateUrl: 'shared/modules/events/offers/list/list.html',
            controller: 'offersListController',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.events.offers.view', {
        url: '/:offerId/view?action',
        cache: false,
        views: {
          'nav-offers-view': {
            templateUrl: 'shared/modules/events/offers/view/view.html',
            controller: 'offersViewController',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.events.offers.viewv2', {
        url: '/:offerId/viewv2?action',
        cache: false,
        views: {
          'nav-offers-view': {
            templateUrl: 'shared/modules/events/offers/viewv2/viewv2.html',
            controller: 'offersViewV2Controller',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.events.offers.create', {
        url: '/create',
        abstract: true,
        views: {
          'nav-offers-view': {
            template: '<ion-nav-view name="nav-offers-create-view"></ion-nav-view>'
          }
        }
      })

      .state('int.events.offers.create.step1', {
        url: '/step1?eventId',
        cache: false,
        views: {
          'nav-offers-create-view': {
            templateUrl: 'shared/modules/events/offers/create/step1/step1.html',
            controller: 'OffersCreateStep1Controller',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.events.offers.create.step2', {
        url: '/step2',
        cache: false,
        views: {
          'nav-offers-create-view': {
            templateUrl: 'shared/modules/events/offers/create/step2/step2.html',
            controller: 'OffersCreateStep2Controller',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.events.comments', {
        url: '/:eventId/comments',
        cache: false,
        views: {
          'nav-events-view': {
            templateUrl: 'shared/modules/events/comments/comments.html',
            controller: 'CommentsController',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.events.list', {
        url: '/list',
        cache: false,
        views: {
          'nav-events-view': {
            templateUrl: 'shared/modules/events/list/list.html',
            controller: 'EventsListController',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.events.view', {
        url: '/:eventId/view?action',
        cache: false,
        views: {
          'nav-events-view': {
            templateUrl: 'shared/modules/events/view/view.html',
            controller: 'EventViewController',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.events.contestation', {
        url: '/:eventId/contestation',
        cache: false,
        abstract: true,
        views: {
          'nav-events-view': {
            template: '<ion-nav-view name="contestation-view"></ion-nav-view>'
          }
        }
      })

      .state('int.events.contestation.create', {
        url: '/create',
        views: {
          'contestation-view': {
            templateUrl: 'shared/modules/events/contestation/create/create.html',
            controller: 'ContestationCreateController',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.events.contestation.view', {
        url: '/:contestationId/view',
        views: {
          'contestation-view': {
            templateUrl: 'shared/modules/events/contestation/view/view.html',
            controller: 'ContestationViewController',
            controllerAs: 'vm'
          }
        }
      })

      /*.state('int.events.quarrel.view', {
        url: '/view',
        views: {
          'quarrel-view': {
            templateUrl: 'shared/modules/events/quarrel/view/view.html',
            controller: 'EventQuarrelViewController',
            controllerAs: 'vm'
          }
        }
      })*/

      .state('int.events.evaluate', {
        url: '/:eventId/evaluate',
        views: {
          'nav-events-view': {
            templateUrl: 'shared/modules/events/presence/evaluate/evaluate.html',
            controller: 'EventPresenceEvaluateController',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.events.checkout', {
        url: '/:eventId/checkout',
        cache: false,
        abstract: true,
        views: {
          'nav-events-view': {
            template: '<ion-nav-view name="checkout-view"></ion-nav-view>'
          }
        }
      })

      .state('int.events.checkout.step1', {
        url: '/step1',
        views: {
          'checkout-view': {
            templateUrl: 'shared/modules/events/presence/checkout/step1/step1.html',
            controller: 'CheckoutStep1Controller',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.events.checkout.step2', {
        url: '/step2',
        views: {
          'checkout-view': {
            templateUrl: 'shared/modules/events/presence/checkout/step2/step2.html',
            controller: 'CheckoutStep2Controller',
            controllerAs: 'vm'
          }
        }
      })

      .state('int.events.checkout.step3', {
        url: '/step3',
        views: {
          'checkout-view': {
            templateUrl: 'shared/modules/events/presence/checkout/step3/step3.html',
            controller: 'CheckoutStep3Controller',
            controllerAs: 'vm'
          }
        }
      });
  }
})();
