(function() {
  'use strict';

  angular
    .module('medsquire.interns.home')
    .config(internsHomeRoutes)

  function internsHomeRoutes($stateProvider) {
    $stateProvider.state('int.home', {
      url: '/home',
      cache: false,
      views: {
        'tab-home': {
          templateUrl: 'interns/home/home.html',
          controller: 'InternsHomeController',
          controllerAs: 'vm'
        }
      }
    });
  }
})();
