(function () {
  'use strict';

  angular
    .module('medsquire.services')
    .factory('BadgesService', BadgesService);

  /* @ngInject */
  function BadgesService() {

    var BadgesService = {};

    var badges = {};

    BadgesService.setBadge = setBadge;
    BadgesService.getBadge = getBadge;
 
    return BadgesService;

    ////////////////

    function setBadge(badge, value) {
      badges[badge] = value;
    }

    function getBadge(badge) {
      //console.log('GETTING BADGE');
      return badges[badge];
    }
  }
})();
