(function () {
  'use strict';

  angular
    .module('medsquire.events.list')
    .controller('EventsListController', EventsListController);

  /* @ngInject */
  function EventsListController($scope, $state, $filter, CoreEnvironment, MdsqreAuth, MdsqreApiMe, $log,
    ExchangesAcceptService, EventsListService, MdsqreHelpers, $ionicPopover) {
    var vm = this;
    vm.title = 'Minha Agenda';
    vm.user = {};
    vm.user.isMonitor = false;
    vm.user.isManager = false;
    vm.calendarEvents = [];
    vm.scheduledEventsList = [];
    vm.finishedEventsList = [];
    vm.offeringEvent = false;
    vm.viewManagerCalendar = false;
    vm.viewMonitorCalendar = false;
    vm.selectedFilter = {};
    vm.selectedMonth = {};


    vm.refresh = refresh;
    vm.fetchEvents = fetchEvents;
    vm.toggleFilterPopover = toggleFilterPopover;
    vm.appyCalendarFilter = appyCalendarFilter;
    vm.filterEventsList = filterEventsList;
    vm.filterDayEventsList = filterDayEventsList;

    activate();

    ////////////////

    function activate() {
      vm.context = MdsqreHelpers.getContext();
      vm.calendarEvents = [];
      vm.scheduledEventsList = [];
      vm.finishedEventsList = [];
      vm.selectedMonth = moment();
      vm.allowedFilters = EventsListService.getAllowedFilters();
      console.log('CURRENT', EventsListService.getCurrentFilter());
      vm.selectedFilter = EventsListService.getCurrentFilter();
      $log.log('ALLOWED FILTERS', vm.allowedFilters);

      vm.user = MdsqreAuth.getUser();
      if (!vm.selectedFilter || !vm.selectedFilter.type) {
        if (vm.user.hasRole('auditor')) {
          vm.user.isManager = true;
          vm.viewManagerCalendar = true;
          if (MdsqreHelpers.getContext().short == 'pro') {
            vm.selectedFilter = EventsListService.setCurrentFilter('auditor');
          } else {
            vm.selectedFilter = EventsListService.setCurrentFilter('auditor_contested');
          }
        } else if (vm.user.hasRole('monitor')) {
          vm.user.isMonitor = true;
          vm.viewMonitorCalendar = true;
          vm.selectedFilter = EventsListService.setCurrentFilter('monitor');
        } else if (vm.user.hasRole('auditor:super') || vm.user.hasRole('manager')) {
          vm.user.isSuperAuditor = true;
          vm.viewSuperAuditorCalendar = true;
          vm.selectedFilter = EventsListService.setCurrentFilter('super_auditor_contested');
        } else {
          vm.selectedFilter = EventsListService.setCurrentFilter('attendant');
        }
      }

      vm.offeringEvent = false;
      if (ExchangesAcceptService.getOffer()) {
        vm.offeringEvent = true;
        vm.selectedFilter = EventsListService.setCurrentFilter('attendant');
      }

      $ionicPopover.fromTemplateUrl('shared/modules/events/list/list-filter.html', {
          scope: $scope
        })
        .then(function (popover) {
          vm.popover = popover;
        });

      appyCalendarFilter(vm.selectedFilter);

      $scope.$on('$destroy', function () {
        $log.log('destroy emited');
        vm.popover.remove();
      });
    }

    function refresh() {
      vm.calendarEvents = [];
      vm.scheduledEventsList = [];
      vm.finishedEventsList = [];
      fetchEvents(vm.selectedMonth);
    }

    function toggleFilterPopover(elementEvent) {
      if (vm.popover.isShown()) {
        vm.popover.hide();
      } else {
        vm.popover.show(elementEvent);
      }
    }

    function appyCalendarFilter(filter) {
      vm.selectedFilter = EventsListService.setCurrentFilter(filter.type);
      vm.title = filter.name;
      fetchEvents(vm.selectedMonth);
      if (vm.popover && vm.popover.isShown()) {
        vm.popover.hide();
      }
    }

    function fetchEvents(selectedMonth) {
      vm.selectedMonth = selectedMonth;


      var filter = vm.selectedFilter;
      if (!filter) {
        vm.selectedFilter = EventsListService.setCurrentFilter('attendant');
      }

      vm.calendarEvents = [];
      vm.scheduledEventsList = [];
      vm.finishedEventsList = [];


      if (filter.type === 'attendant') {
        var attendantFilter = {
          month: vm.selectedMonth
        };
        if (vm.offeringEvent) attendantFilter.offerable = true;
        /*if (vm.offeringEvent) {
          attendantFilter.offerable = true;
          if (MdsqreHelpers.getContext().short === 'uni') {
            attendantFilter.location = 'sector:' + ExchangesAcceptService.getOffer().offerable.location.sector;
          }
        }*/
        EventsListService.fetchAttendantEvents(attendantFilter)
          .then(function (events) {
            vm.calendarEvents = events;
            $log.log('EVENTS', events);
            filterEventsList(vm.selectedMonth);
          })
          .finally(function () {
            $scope.$broadcast('scroll.refreshComplete');
          });
      }
      if (filter.type === 'attendant_contested') {
        EventsListService.fetchAttendantEvents({
            month: vm.selectedMonth,
            contested: true
          })
          .then(function (events) {
            vm.calendarEvents = events;
            filterEventsList(vm.selectedMonth);
          })
          .finally(function () {
            $scope.$broadcast('scroll.refreshComplete');
          });
      }

      if (filter.type === 'monitor') {
        EventsListService.fetchMonitorEvents({
            month: vm.selectedMonth
          })
          .then(function (events) {
            vm.calendarEvents = events;
            filterEventsList(vm.selectedMonth);
          })
          .finally(function () {
            $scope.$broadcast('scroll.refreshComplete');
          });
      }
      if (filter.type === 'monitor_contested') {
        EventsListService.fetchMonitorEvents({
            month: vm.selectedMonth,
            contested: true
          })
          .then(function (events) {
            vm.calendarEvents = events;
            filterEventsList(vm.selectedMonth);
          })
          .finally(function () {
            $scope.$broadcast('scroll.refreshComplete');
          });
      }
      if (filter.type === 'auditor') {
        EventsListService.fetchAuditorEvents({
            month: vm.selectedMonth
          })
          .then(function (events) {
            vm.calendarEvents = events;
            filterEventsList(vm.selectedMonth);
          })
          .finally(function () {
            $scope.$broadcast('scroll.refreshComplete');
          });
      }
      if (filter.type === 'auditor_contested') {
        EventsListService.fetchAuditorEvents({
            month: vm.selectedMonth,
            contested: true
          })
          .then(function (events) {
            vm.calendarEvents = events;
            filterEventsList(vm.selectedMonth);
          })
          .finally(function () {
            $scope.$broadcast('scroll.refreshComplete');
          });
      }
      if (filter.type === 'super_auditor_contested') {
        EventsListService.fetchSuperAuditorEvents({
            month: vm.selectedMonth,
            contested: true
          })
          .then(function (events) {
            vm.calendarEvents = events;
            filterEventsList(vm.selectedMonth);
          })
          .finally(function () {
            $scope.$broadcast('scroll.refreshComplete');
          });
      }
      if (filter.type === 'super_auditor') {
        EventsListService.fetchSuperAuditorEvents({
            month: vm.selectedMonth
          })
          .then(function (events) {
            vm.calendarEvents = events;
            filterEventsList(vm.selectedMonth);
          })
          .finally(function () {
            $scope.$broadcast('scroll.refreshComplete');
          });
      }
    }

    function filterEventsList(month) {

      vm.scheduledEventsList = [];
      vm.finishedEventsList = [];
      var calendarEvents = vm.calendarEvents;
      var filteredEvents = EventsListService.filterEventsList(calendarEvents, {
        month: month
      });
      // workaround to force monitor to select a day, must do this in a cleaner way

      if ((vm.user.hasRole('monitor') && vm.viewMonitorCalendar) || (vm.user.hasRole('auditor') && vm.viewManagerCalendar) || (vm.user.hasRole('auditor:super') && vm.viewSuperAuditorCalendar)) {
        vm.shouldSelectDay = true;
        // should make it load by day from the backend
      } else {
        vm.scheduledEventsList = filteredEvents.scheduled;
        vm.finishedEventsList = filteredEvents.finished;
      }
    }

    function filterDayEventsList(day) {
      vm.scheduledEventsList = [];
      vm.finishedEventsList = [];
      var calendarEvents = vm.calendarEvents;
      var filteredEvents = EventsListService.filterEventsList(calendarEvents, {
        day: day
      });
      vm.scheduledEventsList = filteredEvents.scheduled;
      vm.finishedEventsList = filteredEvents.finished;
    }

    // $scope.$on("$destroy", function () {
    //   if ($state.current.name.indexOf('home') >= 0) {
    //     EventsListService.reset();
    //   }
    // });

  }
})();
