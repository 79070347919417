(function() {
  'use strict';

  angular
    .module('medsquire.address')
    .factory('AddressModel', AddressModel);

  /* @ngInject */
  function AddressModel(_Model) {

    ////////////////

    function AddressModel(data) {
      _Model.call(this, data);
      this._class = 'AddressModel';
      this.googlePlaceId = data.googlePlaceId;
      this.street = data.street;
      this.number = data.number;
      this.district = data.district;
      this.complement = data.complement;
      this.city = data.city;
      this.zip = data.zip;
      this.state = data.state;
      this.country = data.country;
      this.latitude = data.latitude;
      this.longitude = data.longitude;
    }

    AddressModel.prototype = Object.create(_Model.prototype);

    return AddressModel;
  }
})();
