  (function () {
    'use strict';

    angular
      .module('medsquire.events.offers.view')
      .controller('offersViewController', offersViewController);

    /* @ngInject */
    function offersViewController($q, $scope, $state, $filter, MdsqreApiEvents, MdsqreApiOffers,  MdsqreApiEventOffers, MdsqreApiMe, TaskQueueService, CoreEnvironment, $log,
      $sessionStorage, MdsqreAuth, OffersCreateService) {
      var vm = this;
      vm.title = 'Oferta';

      vm.offerLoaded = false;
      vm.userLoaded = false;

      vm.acceptOffer = acceptOffer;
      vm.authorizeOffer = authorizeOffer;
      vm.cancelOffer = cancelOffer;
      vm.rejectOffer = rejectOffer;

      activate();

      ////////////////

      function activate() {
        backNavigationFix();
        vm.user = MdsqreAuth.getUser();
        vm.userLoaded = true;
        fetchOffer($state.params.offerId).then(
          function (response) {
            console.log('OFFER', vm.offer);
            vm.offerLoaded = true;
          }
        );
      }

      function fetchOffer(offerId) {
        var deferred = $q.defer();
        MdsqreApiEventOffers.view(offerId)
          .then(function (response) {

            vm.offer = response.data;

            deferred.resolve(response);
          })
          .catch(function (error) {
            $log.log(error);
            deferred.reject(error);
          });
        return deferred.promise;
      }

      function acceptOffer() {

        MdsqreApiEventOffers.accept({
            offer_id: vm.offer.id,
            to_attendant: vm.user.id
          })
          .then(function (eventResponse) {
            console.log(eventResponse);
            $state.go('*.home');
          })
          .catch(function (error) {
            $log.log(error);
          });
      }

      function authorizeOffer(){
        MdsqreApiOffers.authorizeTransaction({transaction_id: vm.offer.transactions[0].id})
          .then(function (eventResponse) {
            console.log(eventResponse);
            $state.go('*.home');
          })
          .catch(function (error) {
            $log.log(error);
          });

        /*MdsqreApiEventOffers.authorize({
            offer_id: vm.offer.id,
            transaction_id: vm.offer.transactions[0].id
          })
          .then(function (eventResponse) {
            console.log(eventResponse);
            $state.go('*.home');
          })
          .catch(function (error) {
            $log.log(error);
          });*/
      }

      function cancelOffer() {
        MdsqreApiEventOffers.remove(vm.offer.id)
          .then(function (eventResponse) {
            console.log(eventResponse);
            $state.go('*.home');
          })
          .catch(function (error) {
            $log.log(error);
          });
      }

      function rejectOffer() {

        MdsqreApiEventOffers.reject({
            offer_id: vm.offer.id,
            to_attendant: vm.user.id
          })
          .then(function (eventResponse) {
            console.log(eventResponse);
            $state.go('*.home');
          })
          .catch(function (error) {
            $log.log(error);
          });
      }

      function backNavigationFix() {
        if ($state.params.action === 'created_offer') {
          $sessionStorage.customBack = {
            backSteps: -5
          };
        }
      }

    }
  })();
