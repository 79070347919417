(function() {
    'use strict';

    angular
        .module('medsquire.services')
        .factory('LogoutService', LogoutService);

    /* @ngInject */
    function LogoutService(AuthenticationService) {

        var LogoutService = {};
        LogoutService.logout = logout;

        ////////////////

        function logout() {
          AuthenticationService.logout();
        }
    }
})();
