(function() {
    'use strict';

    angular
        .module('medsquire.core')
        .factory('coreService', coreService);

    /* @ngInject */
    function coreService() {
        var service = {
            func: func
        };
        return service;

        ////////////////

        function func() {
        }
    }
})();
