(function() {
    'use strict';

    angular
        .module('medsquire.core.bootstrap')
        .factory('AppBootstrapService', AppBootstrapService);

    /* @ngInject */
    function AppBootstrapService($rootScope, $interval, $log, coreEvents) {
        var heartbeat;
        var service = {
            startHeartbeat: startHeartbeat,
            stopHeartbeat: stopHeartbeat
        };
        return service;

        ////////////////

        function startHeartbeat(time) {
          $log.log('heartbeat started');
          if (typeof time === 'undefined') {
            var time = 10000; // 10 seconds
          }
          heartbeat = $interval(function(count) {
            $log.log('heartbeat ' + count);
            $log.log('emiting '+coreEvents.heartbeat);
            $rootScope.$broadcast(coreEvents.heartbeat, count);
          }, time);
        }

        function stopHeartbeat() {
          if (typeof heartbeat === 'undefined') {
            return;
          }
          $interval.cancel(heartbeat);
        }
    }
})();
