(function() {
  'use strict';

  angular
    .module('medsquire')
    .constant('moment', moment)
    .config(translateConfig)
    .config(ngStorageConfig)
    .config(ionicConfig)
    .config(blockUIConfig)
    .run(bootstrap);

  function translateConfig($translateProvider) {
    $translateProvider.useSanitizeValueStrategy('escape');
    $translateProvider.useStaticFilesLoader({
      files: [{
        prefix: 'assets/lib/i18n/locale.',
        suffix: '.json'
      }]
    });
    $translateProvider.preferredLanguage('pt_BR');
  }

  function ngStorageConfig($localStorageProvider, $sessionStorageProvider) {
    $localStorageProvider.setKeyPrefix('medsquire_');
    $sessionStorageProvider.setKeyPrefix('medsquire_');
  }

  function ionicConfig($ionicConfigProvider) {
    $ionicConfigProvider.tabs.position('bottom');
    $ionicConfigProvider.navBar.alignTitle('center');
    // $ionicConfigProvider.views.maxCache(0);
  }

  function blockUIConfig(blockUIConfig) {
    blockUIConfig.requestFilter = function(config) {
      // block ui on notifications request
      if(config.url.indexOf('api/v1/user/notifications') > -1) {
        return false;
      }
    };
  }



  function bootstrap($ionicPlatform, CoreEnvironment, coreEventsService, $localStorage, MdsqreAuth,MdsqreApi, coreEvents, $window, $log, $state) {

    function checkLogin() {
      if ($localStorage.user) {
        MdsqreApi.getMe().then(function(userResponse) {
          console.log("User is logged in");
        })
        .catch(function(error) {
          if (error.status == 401) {
            delete $localStorage.user;
            delete $localStorage.organization;
            console.log("Clearing user data");
            $window.location.reload();
          };
        });
      };
    }

    // todo: abstract this to a AppBootstrap service
    MdsqreApi.setEnv($window._Medsquire.env);

    coreEventsService.emit(coreEvents.bootstrap);
    $ionicPlatform.ready(function() {

      if ($window.cordova && $window.cordova.plugins && $window.cordova.plugins.Keyboard) {
        cordova.plugins.Keyboard.hideKeyboardAccessoryBar(true);
        cordova.plugins.Keyboard.disableScroll(true);
      }
      if ($window.StatusBar) {
        StatusBar.styleDefault();
      }
      CoreEnvironment.setPlatformInfo($window.ionic.Platform);
      CoreEnvironment.setEnv($window._Medsquire);
      coreEventsService.emit(coreEvents.ready);
      if (!CoreEnvironment.getPlatform().browser) {
        $log.warn('bootstrapping application on device');
        CoreEnvironment.setDevice($window.device);
        coreEventsService.emit(coreEvents.mobileDevice);
      } else {
        $log.warn('bootstrapping application on browser');
      }
    });

    checkLogin();
  }
})();
