(function() {
    'use strict';

    angular
        .module('medsquire.core.bootstrap')
        .run(coreBootstrap);

      function coreBootstrap($rootScope, coreEvents, AppBootstrapService) {
        $rootScope.$on(coreEvents.ready, function(data) {
          AppBootstrapService.startHeartbeat();
        });
      }
})();
