(function () {
  'use strict';

  angular
    .module('medsquire.events.offers.list')
    .controller('offersFiltersController', offersFiltersController);

  /* @ngInject */
  function offersFiltersController($q, $scope, $state, $filter, MdsqreApiEventOffers, MdsqreApiMe, $log,
    $sessionStorage, ExchangesAcceptService, MdsqreAuth) {

    var vm = this;
    vm.title = 'Ofertas';
    vm.dateValue;
    vm.initialDate;
    vm.finalate;

    vm.paySliderChange = paySliderChange;
    vm.sliderOffersChange = sliderOffersChange;



    function addDays(startDate,days){

        var endDate = moment(startDate).add(days, 'days').valueOf();
        return endDate;
    }

    function sliderOffersChange(){
        vm.dateStringOffer = humanFriendlyDate(vm.dateValue);
    }

    function paySliderChange(){
        vm.PayDayString = humanFriendlyDate(vm.payDateValue);
    }

    function humanFriendlyDate(utcDate){
        var r = new Date(parseInt(utcDate)).toLocaleDateString("pt-BR");
        return r;
    }

    activate();

    ////////////////

    function activate() {

      //var startDate = moment(dateTimeString, "DD/MM/YY");
      var today = moment();
      vm.offerListStartDay = today.valueOf(); //moment(today).format("DD/MM/YY");
      vm.offerListEndDay =  addDays(today, 180);
      vm.dateStringOffer = humanFriendlyDate(vm.offerListStartDay.toString());

      vm.payDayRangeStart = today.valueOf(); //moment(today).format("DD/MM/YY");
      vm.payDayRangeEnd =  addDays(today, 180);
      vm.PayDayString = humanFriendlyDate(vm.payDayRangeStart.toString());

      /*ExchangesAcceptService.reset();
      isProfessional();
      getUser();
      getOffersToMe();
      getOffersByMe();
      getOffersToAuthorize();
      getOffers();*/
    }



  }

})();
