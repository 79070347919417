(function() {
  'use strict';

  angular
    .module('medsquire.directives.ion-google-autocomplete')
    .directive('googleAutocompleteSuggestion', googleAutocompleteSuggestion);

  /* @ngInject */
  function googleAutocompleteSuggestion($document, $ionicModal, $ionicTemplateLoader,
    googleAutocompleteService) {
    var directive = {
      link: link,
      restrict: 'A',
      scope: {
        location: '=', //Required
        countryCode: '@', //Optional
        onSelection: '&' //Optional
      },
      templateUrl: 'shared/directives/ion-google-autocomplete/ion-google-autocomplete.html'
    };
    return directive;


    function link($scope, element) {
      $scope.search = {};
      $scope.search.suggestions = [];
      $scope.search.query = '';
      $scope.address = {};
      $scope.lugar = "";
      $scope.showAddressFields = false;


      var userAddress = {
        isComponentFound: function(addrtypes,componentName){
            var result = false;

            for(var i=0;i<addrtypes.length;i++)
            {
              if(addrtypes[i] == componentName)
              {
                result = true;
                break;
              }
            }
            return result;
        },
        getAddrComponent: function(address_components,componentName,valueFormat){
            var addrComponentValue = "";
            addrComponentValue = address_components.find(function(addr){
                return userAddress.isComponentFound(addr.types,componentName);
            });


            if(!addrComponentValue)
              return '';

            if(valueFormat == "long_name")
                return addrComponentValue.long_name;
            else if(valueFormat == "short_name")
                return addrComponentValue.short_name;
            else
                return addrComponentValue;
        }
     };


     $scope.onAddressChoose = function (location) {

     }



     $scope.onAddressSelection = function (location) {

         $scope.address.street = "";
         $scope.address.number = "";
         $scope.address.district = "";
         $scope.address.zip = "";
         $scope.address.city = "";
         $scope.address.state = "";

         var id = location.id;
         var a = location.address_components;
         $scope.address.city = userAddress.getAddrComponent(a,"administrative_area_level_2","long_name");
         $scope.address.district = userAddress.getAddrComponent(a,"sublocality_level_1","long_name");
         $scope.address.number = userAddress.getAddrComponent(a,"street_number","long_name");
         $scope.address.zip = userAddress.getAddrComponent(a,"postal_code","long_name");
         $scope.address.street = userAddress.getAddrComponent(a,"route","long_name");
         $scope.address.country = userAddress.getAddrComponent(a,"country","long_name");
         $scope.address.state = userAddress.getAddrComponent(a,"administrative_area_level_1","short_name");

         /*var tmp = $scope.address.street + ", " +
         $scope.address.number + ", " +
         $scope.address.district + ", " +
         $scope.address.city + ", " +
         $scope.address.state;

         var stripped = tmp.split('undefined').join('');
         stripped = stripped.split(', , ').join(', ');

         $scope.address_string = stripped;*/
     };







      var template = [
        '<ion-modal-view id="selectorModal">',
        '<ion-header-bar class="item-input-inset">',
        '<label class="item-input-wrapper">',
        '<i class="icon ion-search placeholder-icon"></i>',
        '<input id="modalSearchInput" type="search" ng-model="search.query" placeholder="Procurar">',
        '</label>',
        '<button id="closeButton" class="ion-autocomplete-cancel button button-clear button-dark ng-binding" ng-click="close()" translate>Ok</button>',
        '</ion-header-bar>',
        '<ion-content>',
        '<ion-list>',
        '<ion-item ng-show="search.error">',
        '{{search.error}}',
        '</ion-item>',
        '<ion-item ng-repeat="suggestion in search.suggestions" ng-click="choosePlace(suggestion)">',
        '{{suggestion.description}}',
        '</ion-item>',
        '</ion-list>',

        '<div ng-if = "showAddressFields">',
            '<label class="item item-input" name="rua">',
              '<span class="input-label">Rua</span>',
              '<input type="text" placeholder="" ng-model="address.street">',
            '</label>',

            '<label class="item item-input" name="numero" >',
              '<span class="input-label">Num</span>',
              '<input type="text" placeholder="" ng-model="address.number" ng-change="completeLocation()">',
            '</label>',

            '<label class="item item-input" name="complemento">',
              '<span class="input-label">Complemento</span>',
              '<input type="text" placeholder="" ng-model="address.complement">',
            '</label>',

            '<label class="item item-input" name="bairro">',
              '<span class="input-label">Bairro</span>',
              '<input type="text" placeholder="" ng-model="address.district">',
            '</label>',

            '<label class="item item-input" name="cidade">',
              '<span class="input-label">Cidade</span>',
              '<input type="text" placeholder="" ng-model="address.city">',
            '</label>',

            '<label class="item item-input" name="estado">',
              '<span class="input-label">Estado</span>',
              '<input type="text" placeholder="" ng-model="address.state">',
            '</label>',

            '<label class="item item-input" name="cep">',
              '<span class="input-label">Cep</span>',
              '<input type="text" placeholder="" ng-model="address.zip">',
            '</label>',

            '<label class="item item-input" name="pais">',
              '<span class="input-label">País</span>',
              '<input type="text" placeholder="" ng-model="address.country">',
            '</label>',
        '</div>',


        '</ion-content>',
        '</ion-modal-view>'

      ].join('')


      $scope.completeLocation = function(){

        /*$scope.address.city = ;
        $scope.address.district = ;
        $scope.address.number = ;
        $scope.address.complement =;
        $scope.address.zip = ;
        $scope.address.street = ;
        $scope.address.country = ;
        $scope.address.state = ;*/

      }

      $scope.modal = $ionicModal.fromTemplate(template, {
        scope: $scope,
        animation: 'slide-in-up'

      })

      /*$scope.modal = $ionicModal.fromTemplate('autocompletes.html', {
        scope: $scope,
        animation: 'slide-in-up'
      })*/

      var searchInputElement = angular.element($scope.modal.$el.find('input'));

      element[0].addEventListener('focus', function(event) {

        $scope.search.query = '';
        $scope.open();
      });

      $scope.open = function() {

        $scope.modal.show();
        searchInputElement[0].focus();
      };

      $scope.close = function() {
        googleAutocompleteService.getDetails($scope.lugar.place_id).then(function(location) {
          $scope.location = location;
          $scope.onSelection({ location: $scope.address});
        });

        $scope.modal.hide();
      };

      $scope.choosePlace = function(place) {
        $scope.lugar = place;
        googleAutocompleteService.getDetails(place.place_id).then(function(location) {
          $scope.location = location;
          //$scope.close();

          $scope.onAddressSelection(location);

          if ($scope.onSelection !== undefined){
            $scope.onSelection({ location: location });
            $scope.showAddressFields = true;
          }

        });
      };

      $scope.$watch('search.query', function(newValue) {

        if (newValue) {
          googleAutocompleteService.searchAddress(newValue, $scope.countryCode).then(function(result) {

            $scope.search.error = null;
            $scope.search.suggestions = result;
          }, function(status) {

            $scope.search.error = "There was an error :( " + status;
          });
        }
      });
    }
  }
})();
