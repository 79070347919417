(function() {
  'use strict';

  angular
    .module('medsquire.services')
    .factory('helperService', helperService);

  /* @ngInject */
  function helperService() {
    var service = {
      parseDayPeriod: parseDayPeriod,
      getAvatar: getAvatar,
      cleanZip: cleanZip
    };
    return service;

    ////////////////

    function parseDayPeriod(startTime, endTime) {
      if (!startTime || !endTime) throw new Error('Must inform start and end arguments');
      var startTime = moment(startTime);
      var endTime = moment(endTime);
      if (!startTime.isValid() || !endTime.isValid()) throw new Error('Start and end must be valid dates');
      var duration = Math.abs(endTime.diff(startTime, 'hours'));

      var period = null;

      if (duration <= 1) {
        var hour = startTime.hour();
        if (hour > 19 || hour <= 7) period = 'night';
        if (hour <= 19 && hour > 7) period = 'day';
      }

      var dayTime = 0;
      var nightTime = 0;
      for (var i = 1; i < duration; i++) {
        var hour = startTime.add(1, 'h').hour();
        if (hour > 19 || hour <= 7) {
          nightTime++;
          period = 'night';
        }
        if (hour <= 19 && hour > 7) {
          dayTime++;
          period = 'day';
        }
      }
      if (dayTime > nightTime) period = 'day';
      if (dayTime < nightTime) period = 'night';

      return period;
    }

    function getAvatar(email) {
      var avatar = 'assets/img/avatar-placeholder.png';
      if (typeof email !== 'undefined') {
        if (email.indexOf('@gmail') > -1) {
          avatar = 'https://pikmail.herokuapp.com/' + email + '?size=500';
        }
      }
      return avatar;
    }

    function cleanZip(zip) {
      var zip = zip.replace(/\D/g, '');
      return zip;
    }
  }
})();
