(function() {
  'use strict';

  angular
    .module('medsquire')
    .controller('MedsquireController', MedsquireController);

  /* @ngInject */
  function MedsquireController($rootScope, $state, $ionicHistory, $sessionStorage) {
    var vm = this;
    vm.title = 'MedsquireController';

    vm.back = back;

    activate();

    ////////////////

    function activate() {}

    function back() {
      console.log($ionicHistory.viewHistory());
      var customBack = $sessionStorage.customBack;
      if (customBack) {
        if (customBack.backSteps) {
          $ionicHistory.goBack(customBack.backSteps);
          $sessionStorage.customBack = null;
          return;
        }
        $state.go(customBack.route, customBack.params);
        $sessionStorage.customBack = null;
        return;
      }
      $ionicHistory.goBack();
    }
  }
})();
