(function() {
  'use strict';

  angular
    .module('medsquire.chat')
    .controller('ChatController', ChatController);

  /* @ngInject */
  function ChatController() {
    var vm = this;
    vm.title = 'Ajuda Online';

    activate();

    ////////////////

    function activate() {}
  }
})();
