(function () {
  'use strict';

  angular
    .module('medsquire.events.exchanges.accept')
    .factory('ExchangesAcceptService', ExchangesAcceptService);

  /* @ngInject */
  function ExchangesAcceptService(MdsqreAuth, $log) {
    var service = {
      offerSubject: {},
      offerCreator: {},
      offeredEvent: {},
      returnedEvent: {},

      setOfferSubject: setOfferSubject,
      getOfferSubject: getOfferSubject,
      setOfferCreator: setOfferCreator,
      getOfferCreator: getOfferCreator,
      setOffer: setOffer,
      getOffer: getOffer,
      setOfferedEvent: setOfferedEvent,
      getOfferedEvent: getOfferedEvent,
      setReturnedEvent: setReturnedEvent,
      getReturnedEvent: getReturnedEvent,
      reset: reset,
      report: report
    };
    return service;

    ////////////////

    function setOfferSubject() {
      this.offerSubject = MdsqreAuth.getUser();
      return this;
    }

    function getOfferSubject() {
      return this.offerSubject;
    }

    function setOfferCreator(offerCreator) {
      this.offerCreator = offerCreator;
      return this;
    }

    function getOfferCreator() {
      return this.offerCreator;
    }

    function setOffer(offer) {
      this.offer = offer;
      return this;
    }

    function getOffer() {
      return this.offer;
    }

    function setOfferedEvent(offeredEvent) {
      this.offeredEvent = offeredEvent;
      return this;
    }

    function getOfferedEvent() {
      return this.offeredEvent;
    }

    function setReturnedEvent(returnedEvent) {
      this.returnedEvent = returnedEvent;
      return this;
    }

    function getReturnedEvent() {
      return this.returnedEvent;
    }

    function reset() {
      this.offer = null; //{};
      this.offerSubject = null; //{};
      this.offerCreator = null; //{};
      this.offeredEvent = null;  //{};
      this.returnedEvent = null; //{};
    }

    function report() {
      $log.log('==== EXCHANGE ===========');
      $log.log('Offer by: ', this.offerCreator);
      $log.log('Event Offered: ', this.offeredEvent);
      $log.log('Event Returned: ', this.returnedEvent);
      $log.log('=========================');
    }

  }
})();
