(function() {
  'use strict';

  console.log("%c%s","background-color:#80b107; color:white; padding:15px; font-size:28px; font-weight:bold;",'.ENV IS SET TO INTERNS');

  angular
    .module('medsquire.interns', [
      'medsquire.interns.password_reset',
      'medsquire.interns.terms',
      'medsquire.interns.home',
    ]);
})();
