(function() {
  'use strict';

  angular
    .module('medsquire.events.presence.checkout.step3')
    .controller('CheckoutStep3Controller', CheckoutStep3Controller);

  /* @ngInject */
  function CheckoutStep3Controller($q, $state, MdsqreHelpers, MdsqreApiEvents, PresenceCheckoutService, $log,
    $ionicPopup, blockUI, MdsqreAuth, $mdsqre) {
    var vm = this;
    vm.title = 'Checkout';
    vm.ratings = {};

    vm.saveCheckout = saveCheckout;

    activate();

    ////////////////

    function activate() {

      $log.log(PresenceCheckoutService);

      vm.context = MdsqreHelpers.getContext();
      vm.event = PresenceCheckoutService.getEvent();

      if (vm.context.short == 'uni' || vm.context.short == 'int') {
        if (!PresenceCheckoutService.getMonitor().id && !PresenceCheckoutService.getMonitor().name) {
          $ionicPopup.alert({
            title: vm.context.monitor_label + ' não selecionado',
            template: 'É necessário selecionar um ' + vm.context.monitor_label + ' antes de prosseguir'
          });
          $state.go('*.events.view', {
            eventId: $state.params.eventId
          });
        }
      }

      if (!$state.params.eventId || !vm.event) {
        $ionicPopup.alert({
          title: 'Evento não selecionado',
          template: 'É necessário selecionar um evento antes de prosseguir'
        });
        $state.go('*.agenda');
        return;
      }

      if (vm.context.short == 'pro') {
        vm.ratings = { event: { rate: 1 }, self: { rate: 1 } };
      } else {
        vm.ratings = { avaliator: { rate: 1 }, event: { rate: 1 }, self: { rate: 1 } };
      }

      vm.canRateAttendant = vm.event.canRateAttendant();
      vm.canRateAuditor = vm.event.canRateAuditor();
      vm.canRateActivity = vm.event.canRateActivity();

    }

    function saveCheckout() {

      if (PresenceCheckoutService.isEmergencyCheckout() && vm.obs === undefined || vm.obs == "" ){
        $ionicPopup.alert({
          title: 'Não comentado',
          template: 'É necessário comentar!'
        });
        return;
      }

      // if (!PresenceCheckoutService.getMonitor().name) {
      //   alert('Erro: Avaliador não selecionado');
      //   return;
      // }

      if (!$state.params.eventId) {
        alert('Erro: Evento não selecionado');
        return;
      }

      blockUI.start();

      checkoutEvent()
        .then(function(response) {
          $log.log('chaining 3: ', response);
          PresenceCheckoutService.reset();
          $log.log(PresenceCheckoutService);
          $state.go('*.events.view', {
            eventId: $state.params.eventId,
            action: 'checked_out'
          });
        })
        .catch(function(error) {
          $ionicPopup.alert({
            title: 'Aconteceu algo errado...',
            template: 'Erro: ' + error.print()
          });
          $state.go('*.events.view', {
            eventId: $state.params.eventId
          });
        })
        .finally(function() {
          blockUI.stop();
        });

    }

    function checkoutEvent() {

        var deferred = $q.defer();

          var attendant = MdsqreAuth.getUser();
          var avaliator = PresenceCheckoutService.getMonitor();
          if(!angular.isObject(avaliator))
              avaliator = PresenceCheckoutService.getMonitor().toObject();

          var presence = {
            user_id: attendant.id,
            event_id: $state.params.eventId,
            time: moment().format('YYYY-MM-DD HH:mm:ss'),
            type: 'checkout',
          };




          /*presence.ratings = [
            { type: 'event', rate: vm.ratings.event.rate },
            { key: 'event.attendant.evaluation_to_attendant', rate: vm.ratings.self.rate, rateable_type: 'App\\User', rateable_id: attendant.id },
          ];*/

          if ((avaliator.id || avaliator.name) && vm.context.short != 'pro') {

              presence.ratings = [
                { key: 'event.attendant.evaluation_to_attendant', value: vm.ratings.self.rate },
                { key: 'event.attendant.evaluation_to_event', value: vm.ratings.event.rate },
                { key: 'event.attendant.evaluation_to_auditor', value: vm.ratings.avaliator.rate, additional_info: { avaliator: avaliator } },
              ];

              presence.notifiable_type = 'App\\User';
              presence.notifiable_id = PresenceCheckoutService.getMonitor().id;
              if (avaliator.id)
                presence.subjects = [{ user_id: PresenceCheckoutService.getMonitor().id, role: 'monitor' }];

                /*presence.ratings.push({
                  type: 'avaliator_performance',
                  rate: vm.ratings.avaliator.rate,
                  rateable_type: 'App\\User', // very important
                  rateable_id: avaliator.id, // very important
                  additional_info: { avaliator: avaliator }
                });*/

              presence.additional_info = {
                tags: PresenceCheckoutService.getTags(),
                avaliator: avaliator,
                rating: vm.ratings,
                obs: vm.obs,
              };
          } else if (vm.context.short == 'pro') {

              presence.ratings = [
                { key: 'event.attendant.evaluation_to_attendant', value: vm.ratings.self.rate },
                { key: 'event.attendant.evaluation_to_event', value: vm.ratings.event.rate },
              ];

              presence.additional_info = {
                tags: PresenceCheckoutService.getTags(),
                rating: vm.ratings,
                obs: vm.obs,
              };
          }

          console.log("Presence:");
          console.log(presence);

          $mdsqre.requester().events($state.params.eventId).presences().save(presence)
          .then(function (response) {
            console.log(response)
            deferred.resolve(response);
          })
          .catch(function (error) {
            console.error(error)
            deferred.reject(error);
          });

          return deferred.promise;

          /*$mdsqre.requester().events($state.params.eventId).presences().save({
              user_id: attendant.id, // attendant id
              type: presence.type,
              time: presence.time,
              notifiable_id: presence.notifiable_id,     //*****
              notifiable_type: presence.notifiable_type,   //****
              subjects: [
                { user_id: presence.notifiable_id, role: presence.subjects.role }   //****
              ],
              ratings: [
                { key: 'event.attendant.evaluation_to_attendant', value: vm.ratings.self.rate },
                { key: 'event.attendant.evaluation_to_event', value: vm.ratings.event.rate },
                { key: 'event.attendant.evaluation_to_auditor', value: vm.ratings.avaliator.rate, additional_info: presence.ratings.additional_info },
              ],
              additional_info: presence.additional_info
          })
          .then(function (response) {
            console.log(response)
          })
          .catch(function (error) {
            console.error(error)
          });*/

    }

    /*function checkoutEvent() {

      var attendant = MdsqreAuth.getUser(); // todo: should be attendant. inferred that the authenticated user doing checkout is the attendant.

      var avaliator = PresenceCheckoutService.getMonitor();
      if(!angular.isObject(avaliator))
          avaliator = PresenceCheckoutService.getMonitor().toObject();

      var presence = {
        user_id: attendant.id,
        event_id: $state.params.eventId,
        time: moment().format('YYYY-MM-DD HH:mm:ss'),
        type: 'checkout',
      };
      presence.ratings = [
        { type: 'event', rate: vm.ratings.event.rate },
        { type: 'self_attendant', rate: vm.ratings.self.rate, rateable_type: 'App\\User', rateable_id: attendant.id },
      ];
      if ((avaliator.id || avaliator.name) && vm.context.short != 'pro') {
        presence.notifiable_type = 'App\\User';
        presence.notifiable_id = PresenceCheckoutService.getMonitor().id;
        if (avaliator.id) presence.subjects = [{ user_id: PresenceCheckoutService.getMonitor().id, role: 'monitor' }];
        presence.ratings.push({
          type: 'avaliator_performance',
          rate: vm.ratings.avaliator.rate,
          rateable_type: 'App\\User', // very important
          rateable_id: avaliator.id, // very important
          additional_info: { avaliator: avaliator }
        });
        presence.additional_info = {
          tags: PresenceCheckoutService.getTags(),
          avaliator: avaliator,
          rating: vm.ratings,
          obs: vm.obs,
        };
      } else if (vm.context.short == 'pro') {
        presence.additional_info = {
          tags: PresenceCheckoutService.getTags(),
          rating: vm.ratings,
          obs: vm.obs,
        };
      }

      return MdsqreApiEvents.presence(presence);
    }*/

  }
})();
