(function() {
    'use strict';

    angular
        .module('medsquire.core.events')
        .factory('coreEventsService', coreEventsService);

    /* @ngInject */
    function coreEventsService($rootScope, $log) {
        var service = {
            emit: emit
        };
        return service;

        ////////////////

        function emit(event, data) {
          if (typeof data === 'undefined') {
            var data = {};
          }
          $rootScope.$emit(event, data);
          $log.log(event + ' event emited.');
        }
    }
})();
