(function() {
  'use strict';

  angular
    .module('medsquire.events.presence.checkout', [
      'medsquire.events.presence.checkout.step1',
      'medsquire.events.presence.checkout.step2',
      'medsquire.events.presence.checkout.step3',
    ]);
})();
