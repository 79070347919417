(function () {
  'use strict';

  angular
    .module('medsquire.directives.medsquire-offer-info')
    .directive('medsquireOfferInfo', medsquireOfferInfo);

  function medsquireOfferInfo($q, $window, MdsqreApiUsers, $filter, $state, $log, MdsqreApiEventOffers, MdsqreHelpers, ExchangesAcceptService, OffersCreateService, $ionicPopup) {

    var directive = {
      link: link,
      restrict: 'E',
      replace: true,
      controller: controller,
      scope: {
        offer: '=',
        user: '=',
        options: '=?',
        extras: '=',
      },
      templateUrl: 'shared/directives/offer-info/medsquire-offer-info.html'
    };

    return directive;

    function controller($scope) {

      $scope.opt = setDefaultOptions($scope);
      $scope.event = $scope.offer.event;
      //$scope.offer = OffersCreateService;
      //$scope.recipients = $scope.offer.getRecipients();
      $scope.transaction = $scope.offer.getActiveTransaction();
      $scope.myTransaction = $scope.offer.getMyTransaction();
      if ($scope.myTransaction || $scope.transaction) {
        if (!$scope.transaction) {
          $scope.transaction = $scope.myTransaction;
        }
        $scope.canAuthorize = $scope.transaction.canAuthorize();
        $scope.toEvent = $scope.transaction.to_event;
      }

      if ($scope.offer.transaction_type == 'transfer') {
        $scope.titleLabel = 'PASSA';
      } else {
        $scope.titleLabel = 'TROCA';
      }
    }

    function link(scope, element, attrs) {

      scope.getOfferStates = getOfferStates;
      scope.getSubjectNames = getSubjectNames;
      scope.getActiveSubjectName = getActiveSubjectName;
      scope.getActiveSubject = getActiveSubject;
      scope.fetchRecipient = fetchRecipient;
      scope.denyOffer = denyOffer;
      scope.authorizeOffer = authorizeOffer;
      scope.cancelTransaction = cancelTransaction;
      scope.confirmTransaction = confirmTransaction;
      scope.acceptOffer = acceptOffer;
      scope.cancelOffer = cancelOffer;
      scope.rejectOffer = rejectOffer;
      scope.shareOnWhatsApp = shareOnWhatsApp;
      scope.recipients = null;

      // if (scope.offer.transaction_type == 'transfer') {
      //   shareOnWhatsApp("PASSAR");
      // } else {
      //   shareOnWhatsApp("TROCAR");
      // }

      function getOfferStates() {
        var state = '';
        var css = 'state-default';

        if (scope.transaction) {
          scope.toEvent = scope.transaction.to_event;
          if (scope.transaction.isConfirmed()) {
            state = 'AGUARDA AUTORIZAÇÃO';
          } else {
            if (scope.titleLabel = 'PASSA')
              state = 'PASSANDO';
            if (scope.titleLabel = 'TROCA')
              state = 'TROCANDO';
          }
        }

        if (state) {
          return {
            'state': state,
            'class': css
          };
        } else {
          return false;
        }

      }

      function getSubjectNames() {
        var subjectNames = '';
        if (scope.offer.subjects) {
          scope.offer.subjects.forEach(function (subject) {
            subjectNames += subject.name + ', ';
          });
        }
        return subjectNames.slice(0, -2);
      }

      function getActiveSubjectName() {
        var subject = scope.offer.subjects.find(function (subject) {
          return subject.id == scope.transaction.to_attendant_id;
        });
        return subject.name;
      }

      function getActiveSubject() {
        var subject;
        if (scope.transaction) {
          var subject = scope.offer.subjects.find(function (subject) {
            return subject.id == scope.transaction.to_attendant_id;
          });
        } else {
          subject = scope.offer.subjects[0];
        }
        return subject;
      }

      function confirmTransaction() {
        MdsqreApiEventOffers.confirmTransaction({
            id: scope.transaction.id,
            offer_id: scope.offer.id
          }).then(function (eventResponse) {
            console.log(eventResponse);
            $state.reload();
          })
          .catch(function (error) {
            $log.log(error);
          });
      }

      function acceptOffer() {
        if (scope.offer.transaction_type === 'exchange') {
          startExchangeAccept();
        } else if (scope.offer.transaction_type === 'transfer') {
          MdsqreApiEventOffers.accept({
              offer_id: scope.offer.id,
              to_attendant: scope.user.id
            })
            .then(function (eventResponse) {
              //shareOnWhatsApp("ACEITAR");
              console.log(eventResponse);
              $state.reload();
            })
            .catch(function (error) {
              $log.log(error);
            });
        }
      }

      function cancelOffer() {
        var confirmPopup = $ionicPopup.confirm({
          title: 'Cancelar',
          template: 'Cancelar a oferta?',
          cancelText: 'Não',
          okText: 'Sim'
        }).then(function (res) {
          if (res) {
            MdsqreApiEventOffers.remove(scope.offer.id)
              .then(function (eventResponse) {
                console.log(eventResponse);
                //shareOnWhatsApp("CANCELAR");
                $state.go('*.events.offers.list');
              })
              .catch(function (error) {
                $log.log(error);
              });
          }
        });
      }

      function rejectOffer() {
        var confirmPopup = $ionicPopup.confirm({
          title: 'Rejeitar',
          template: 'Rejeitar a oferta?',
          cancelText: 'Não',
          okText: 'Sim'
        }).then(function (res) {
          if (res) {
            MdsqreApiEventOffers.reject({
                offer_id: scope.offer.id,
                to_attendant: scope.user.id
              })
              .then(function (eventResponse) {
                console.log(eventResponse);
                $state.go('*.home');
              })
              .catch(function (error) {
                $log.log(error);
              });
          }
        });
      }

      function startExchangeAccept() {
        ExchangesAcceptService.reset();
        ExchangesAcceptService.setOffer(scope.offer);
        ExchangesAcceptService.setOfferedEvent(scope.event);
        $state.go('*.events.list');
      }

      function fetchRecipient() {

        var deferred = $q.defer();

        var subject = getActiveSubject();
        MdsqreApiUsers.list({
            role: 'medic'
          })
          .then(function (usersResponse) {
            $log.log(usersResponse);

            scope.recipients = usersResponse.data.filter(function (recpt) {
              return recpt.id == subject.id;
            })
            deferred.resolve(scope.recipients);
          })
          .catch(function (error) {
            $log.log(error);
            deferred.reject(error);
          });

        return deferred.promise;

      }


      function shareOnWhatsApp(verbo) {

        var context = MdsqreHelpers.getContext();
        var splitUrl = $window.location.href.split("/");
        var domain = splitUrl[0] + "//" + splitUrl[2]

        if (domain.includes('localhost')) {
          domain = 'https://app-pro.medsquire.com';
        }

        fetchRecipient().then(function () {

          MdsqreApiEventOffers.view(scope.offer.id)
            .then(function (response) {
              var offer = response.data;
            })
            .catch(function (error) {
              $log.log(error);
            });

          var anoInicio = scope.event.getStart().format('YYYY');
          var mesInicio = scope.event.getStart().format('MM');
          var diaInicio = scope.event.getStart().format('DD');
          var horarioInicio = scope.event.getStart().format('HH:mm');
          var anoFim = scope.event.getEnd().format('YYYY');
          var mesFim = scope.event.getEnd().format('MM');
          var diaFim = scope.event.getEnd().format('DD');
          var horarioFim = scope.event.getEnd().format('HH:mm');

          /*if (scope.event.service) {
            var valorhora = parseFloat(scope.event.service.summary.price).toFixed(2);
            var quantidade = parseInt(scope.event.service.summary.qty);
            var total = quantidade * valorhora;
          }*/

          var fone = scope.recipients[0].contact.phone1;
          if (fone.length <= 11) {
            fone = "55" + fone;
          }

          var linkstring = encodeURIComponent(domain + '/#/' + context.short + '/home') + '&phone=' + fone;

          var actionText = "";
          if (verbo == 'CANCELAR')
            actionText = '*OFERTA CANCELADA* %0A--------------------------%0A *PASSO Plantão*: CLÍNICO%20%0A';
          else if (verbo == 'ASSUMIR') {
            actionText = 'Ok! ASSUMO seu Plantão: CLÍNICO %0A--------------------------%0A';
          } else if (verbo == 'TROCAR') {
            actionText = 'Trocar Plantão: CLÍNICO %0A--------------------------%0A';
          } else {
            actionText = 'Passar Plantão: CLÍNICO %0A--------------------------%0A';
          }

          var resultUrl = 'https://api.whatsapp.com/send?text=' + actionText +
            '*Quando?* ' + diaInicio + '/' + mesInicio + '/' + anoInicio +
            ' ' + horarioInicio + 'h -' + horarioFim + 'h ' +
            '%0A*Onde?* ' + scope.event.location.name + ' ' +
            '%0A*Quanto* R$' + total + '(' + valorhora + '/h) ' +
            '%0A*Quem paga?* ' + 'Med5' + ' em ' + diaFim + '/' + mesFim + '/' + anoFim +
            '%0A--------------------------%0A*LINK:* ' + linkstring;

          scope.whatsappShareUrl = resultUrl;

        })

      }

      function cancelTransaction() {
        var confirmPopup = $ionicPopup.confirm({
          title: 'Cancelar',
          template: 'Tem certeza que deseja rejeitar?',
          cancelText: 'Cancelar',
          okText: 'Sim'
        }).then(function (res) {
          if (res) {
            MdsqreApiEventOffers.cancelTransaction({
                id: scope.transaction.id,
                offer_id: scope.offer.id
              }).then(function (eventResponse) {
                $state.go('*.events.offers.list');
                console.log(eventResponse);
              })
              .catch(function (error) {
                $log.log(error);
              });
          }
        });
      }

      function authorizeOffer() {

        MdsqreApiEventOffers.authorize({
            offer_id: scope.offer.id,
            transaction_id: scope.transaction.id
          })
          .then(function (eventResponse) {
            $ionicPopup.alert({
              title: 'Autorização',
              template: 'Solicitação autorizada!'
            });
            console.log(eventResponse);
            $state.reload();
          })
          .catch(function (error) {
            $log.log(error);
          });
      }

      function denyOffer() {

        var confirmPopup = $ionicPopup.confirm({
          title: 'Negar',
          template: 'Tem certeza que deseja negar a oferta?',
          cancelText: 'Cancelar',
          okText: 'Sim'
        }).then(function (res) {
          if (res) {
            MdsqreApiEventOffers.deny({
                offer_id: scope.offer.id,
                transaction_id: scope.transaction.id
              })
              .then(function (eventResponse) {
                console.log(eventResponse);
                $state.reload();
              })
              .catch(function (error) {
                $log.log(error);
              });
          }
        });

      }

      function subjectRejected(subjectId) {
          //return scope.offer.transactions.find(transaction => ((transaction.to_attendant_id == subjectId) && (transaction.state == "rejected")));
          var result = scope.offer.transactions.find(function(transaction){
            return  ((transaction.to_attendant_id == subjectId) && (transaction.state == "rejected"));
          });
          return result;
      }


    }

    function setDefaultOptions($scope) {

      if (angular.isUndefined($scope.options)) {
        $scope.options = {};
      }

      if (angular.isUndefined($scope.options.stateLabels)) {
        $scope.options.stateLabels = true;
      }

      if (angular.isUndefined($scope.options.pendingLabels)) {
        $scope.options.pendingLabels = true;
      }

      if (angular.isUndefined($scope.options.showSubjects)) {
        $scope.options.showSubjects = false;
      }

      if ($scope.offer.created_by != $scope.user.id) {
        $scope.options.showSubjects = false;
      }

      return $scope.options;

    }

  }
})();
