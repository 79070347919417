(function() {
  'use strict';

  angular
    .module('medsquire.auth.login')
    .controller('AuthLoginController', AuthLoginController);

  /* @ngInject */
  function AuthLoginController($state, MdsqreAuth, $ionicPopup, $log, blockUI) {
    var vm = this;
    vm.title = 'Login';

    vm.user = {};

    vm.login = login;

    activate();

    ////////////////

    function activate() {
      vm.user = {};
    }

    function login() {
      blockUI.start({ message: 'Autenticando...' });
      MdsqreAuth.login(vm.user.email, vm.user.password)
        .then(function(response) {
          $state.go('*.home');
        })
        .catch(function(error) {
          $ionicPopup.alert({
            title: 'Email e/ou senha incorretos',
            template: "Verifique o email e a senha digitados e tente novamente"
          });
          $log.log(error);
        })
        .finally(function() {
          blockUI.stop();
        });
    }
  }
})();
