(function() {
  'use strict';

  angular
    .module('medsquire.events.presence', [
      'medsquire.events.presence.checkin',
      'medsquire.events.presence.checkout',
      'medsquire.events.presence.evaluate',
    ]);
})();
