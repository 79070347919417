(function() {
  'use strict';

  angular
    .module('medsquire.settings')
    .controller('SettingsController', SettingsController);

  /* @ngInject */
  function SettingsController($window, $state, $localStorage, MdsqreAuth, MdsqreHelpers, BadgesService, $log) {
    var vm = this;
    vm.title = 'Ajustes';

    vm.logout = logout;
    vm.MdsqreHelpers = MdsqreHelpers;
    vm.badges = BadgesService;

    activate();

    ////////////////

    function activate() {}

    function logout() {
      MdsqreAuth.logout()
        .finally(function() {
          delete $localStorage.user;
          delete $localStorage.organization;
          $state.go('login');
          $window.location.reload();
        });
    }
  }
})();
