(function() {
  'use strict';

  angular
    .module('medsquire.events', [
      'medsquire.events.database',
      'medsquire.events.presence',
      'medsquire.events.list',
      'medsquire.events.view',
      'medsquire.events.offers',
      'medsquire.events.exchanges',
      'medsquire.events.contestation',
      'medsquire.events.comments',
      //'medsquire.events.quarrel',
    ]);
})();
