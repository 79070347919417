(function() {
  'use strict';

  angular
    .module('medsquire.directives.medsquire-event-info')
    .directive('medsquireEventInfo', medsquireEventInfo);

  function medsquireEventInfo($filter, MdsqreHelpers) {
    var directive = {

      link: link,
      restrict: 'E',
      replace: true,
      controller: controller,
      scope: {
        event: '=',
        user: '=',
        options: '=?',
      },
      templateUrl: 'shared/directives/event-info/medsquire-event-info.html'
    };
    return directive;

    function controller($scope) {

      $scope.MdsqreHelpers = MdsqreHelpers;

      if (angular.isUndefined($scope.options)) {
        $scope.options = {};
      }

      if (angular.isUndefined($scope.options.stateLabels)) {
        $scope.options.stateLabels = true;
      }

      if (angular.isUndefined($scope.options.pendingLabels)) {
        $scope.options.pendingLabels = true;
      }

      if (angular.isUndefined($scope.options.extendedView)) {
        $scope.options.extendedView = false;
      }

      if (angular.isUndefined($scope.options.title)) {
        if (!$scope.event.belongsToMe()) {
          $scope.options.title = $scope.event.getAttendants(true);
        } else {
          $scope.options.title = false;
        }
      }

      if (angular.isUndefined($scope.options.titleLabel)) {
        $scope.options.titleLabel = false;
        var pendingOffers = $scope.event.getPendingOffers();
        if (pendingOffers) {
          console.log('PENDING', pendingOffers);
          $scope.titleLabel = getOfferTypeLabel();
        }
      }

      $scope.opt = $scope.options;

    }

    function getOfferTypeLabel(type) {
      switch (type) {
        case 'transfer':
          return 'PASSA';
          break;
        case 'assign':
          if ($scope.offer.visibility == 'public') {
            return 'OFERTA PÚBLICA';
          } else {
            return 'OFERTA PRIVADA';
          }
          break;
        case 'exchange':
          return 'TROCA';
          break;
      }
    }

    function link(scope, element, attrs) {

      scope.getEventState = getEventState;
      scope.formatCurrency = formatCurrency;

      console.log('EVENT INFO', scope.event);

      if (scope.event.hasPaymentInfo()) {
        if (scope.event.canViewActualValues()) {
          scope.showActualValues = true;
        }
        if (scope.event.canViewAuditValues()) {
          scope.showAuditValues = true;
        }
      }

      if (scope.opt.extendedView) {
        scope.canViewCheckoutTime = scope.event.canViewCheckoutTime();
        scope.canViewCheckinTime = scope.canViewCheckoutTime;
        scope.canViewAttendantRatings = scope.event.canViewAttendantRatings();
        scope.attendantSelfRating = scope.event.getRating('attendant','event.attendant.evaluation_to_attendant');
        scope.attendantActivityRating = scope.event.getRating('attendant','event');
        scope.attendantAuditorRating = scope.event.getRating('attendant','auditor');
        scope.attendantMonitorRating = scope.event.getRating('attendant','monitor');
        scope.monitorLabel = MdsqreHelpers.getLabel('monitor', true);
        var monitors = scope.event.getMonitors();
        if (monitors.length > 0) {
          scope.monitorName = monitors[0].name;
        } else {
          scope.event.getCheckoutInfo();
          scope.monitorName = scope.event.monitorName;
        }
        scope.auditorLabel = MdsqreHelpers.getLabel('auditor', true);
        scope.auditorName = scope.event.getAuditors()[0].name;
      }

      function getEventState() {
        if (!scope.event.eventable) {
          return {
            'state': '',
            'class': ''
          };
        }
        var filter = 'eventStateFilter';
        var state = scope.event.state;
        var css = 'state-default';
        if (scope.event.eventable.state == 'assigned' && scope.event.state != 'in_progress' && scope.event.state != 'finished') {
          filter = 'shiftStateFilter';
          state = scope.event.eventable.state;
        }
        if (scope.event.eventable.state == 'validated') {
          filter = 'eventStateFilter';
          state = scope.event.state;
          css = 'state-ok';
        }
        if (scope.event.eventable.state == 'canceled') {
          filter = 'shiftStateFilter';
          state = scope.event.eventable.state;
          css = 'state-alert';
        }
        if (scope.event.isContested()) {
          filter = 'shiftStateFilter';
          state = 'contested';//scope.event.eventable.state;
          css = 'state-alert';
        }
        return {
          'state': $filter(filter)(state),
          'class': css
        };
      }

      function formatCurrency(val) {
        val = parseFloat(val);
        return val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      }

    }
  }
})();
