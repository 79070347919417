(function() {
    'use strict';

    angular
        .module('medsquire.events.exchanges', [
          'medsquire.events.exchanges.list',
          'medsquire.events.exchanges.view',
          'medsquire.events.exchanges.accept'
        ]);
})();
