(function () {
    'use strict';
  
    angular
      .module('medsquire.services')
      .factory('TipsService', TipsService);
  
    /* @ngInject */
    function TipsService() {
  
      var TipsService = {};
  
      var tips = {};
  
      TipsService.setTip = setTip;
      TipsService.getTip = getTip;
   
      return TipsService;
  
      ////////////////
  
      function setTip(context, text) {
        tips[context] = text;
      }
  
      function getTip(context) {
        return tips[context];
      }
    }
  })();
  