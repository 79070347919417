(function() {
    'use strict';

    angular
        .module('medsquire.services')
        .factory('DataService', DataService);

    /* @ngInject */
    function DataService($q, $log) {
        var DataService = {
          _class: 'DataService',
          _api: null,
          _local: null,
          _model: null
        };

        DataService.setClass = setClass;
        DataService.setApiMethod = setApiMethod;
        DataService.setLocalDb = setLocalDb;
        DataService.setModel = setModel;
        DataService.search = search;
        DataService.fromLocal = fromLocal;
        DataService.saveLocal = saveLocal;
        DataService.castModel = castModel;

        return DataService;

        ////////////////

        function setClass(className) {
          this._class = className;
        }

        function setApiMethod(apiMethod) {
          this._api = apiMethod;
        }

        function setLocalDb(localDb) {
          this._local = localDb
        }

        function setModel(modelClass) {
          this._model = modelClass;
        }

        function search(searchObject) {
          $log.warn('should use from child DataService, will deprecate');
          var self = this;
          var deferred = $q.defer();
          self.fromLocal()
          .then(function(response) {
            deferred.resolve(response);
            return deferred.promise;
          })
          .catch(function(error) {
            $log.warn(error);
            self._api.searchView(searchObject)
            .then(function(response) {
              var results = self.castModel(response.data.data, true);
              self.saveLocal(results);
              deferred.resolve(results);
            })
            .catch(function(error) {
              deferred.reject(error);
            });
          })
          return deferred.promise;
        }

        function fromLocal(filter) {
          var self = this;
          var deferred = $q.defer();
          // $log.log(self._local);
          self._local.select({ select: '*', where: filter })
          .then(function(response) {
            // $log.log(response);
            if (response.rows.length > 0) {
              var rows = [];
              for(var i = 0; i < response.rows.length; i++) {
                rows.push(response.rows.item(i));
              }
              $log.warn(rows);
              var results = self.castModel(rows);
              deferred.resolve(results);
            } else {
              deferred.reject({ message: 'no records '});
            }
          })
          .catch(function(error) {
            $log.error(error);
            deferred.reject(error);
          });
          return deferred.promise;
        }

        function saveLocal(data, replace) {
          if (typeof replace === 'undefined') {
            replace = false;
          }
          var self = this;
          data.forEach(function(_data) {
            // $log.log(_data);
            if (replace) {
              self._local.insertOrReplace(_data)
              .catch(function(error) {
                $log.error(error);
              });
            } else {
              self._local.insert(_data)
              .catch(function(error) {
                $log.error(error);
              });
            }
          });
        }

        function castModel(dataList, fromApi) {
          if (typeof fromApi === 'undefined') {
            fromApi = false;
          }
          var self = this;
          var casted = [];
          if (typeof dataList === 'object') {
            for(var data in dataList) {
              var entity = new self._model(dataList[data]);
              if (fromApi) {
                entity.api();
              } else {
                entity.local();
              }
              casted.push(entity);
            }
          } else {
            dataList.forEach(function(data) {
              var entity = new self._model(data);
              if (fromApi) {
                entity.api(dataList[data]);
              } else {
                entity.local(dataList[data]);
              }
              casted.push(entity);
            });
          }
          return casted;
        }
    }
})();
