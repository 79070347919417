(function() {
    'use strict';

    angular
        .module('medsquire.core.environment')
        .factory('CoreEnvironment', CoreEnvironment);

    /* @ngInject */
    function CoreEnvironment($localStorage, _UserModel, $log) {

        var CoreEnvironment = {
          _class: 'CoreEnvironment',
          _environment: {},
          _user: null,
        };

        CoreEnvironment.setEnv = setEnv;
        CoreEnvironment.getEnv = getEnv;
        CoreEnvironment.setPlatformInfo = setPlatformInfo;
        CoreEnvironment.setPlatform = setPlatform;
        CoreEnvironment.getPlatform = getPlatform;
        CoreEnvironment.setDevice = setDevice;
        CoreEnvironment.getDevice = getDevice;
        CoreEnvironment.setUser = setUser;
        CoreEnvironment.getUser = getUser;

        CoreEnvironment.inDevelopment = inDevelopment;

        return CoreEnvironment;

        ////////////////

        function setEnv(env) {
          this._environment.env = env;
          $localStorage._environment = this._environment;
        }

        function setPlatformInfo(info) {
          if (!info) {
            $log.error('must set info param on setPlatformInfo');
            return;
          }

          if (info.platforms.indexOf('browser') > -1) {
            info.isBrowser = true;
          } else {
            info.isBrowser = false;
          }

          this.setPlatform({
            os: info.platform(),
            version: info.version(),
            webview: info.isWebView(),
            browser: info.isBrowser,
            platforms: info.platforms
          });
        }

        function setPlatform(platform) {
          this._environment.platform = platform;
          $localStorage._environment = this._environment;
        }

        function setDevice(device) {
          this._environment.device = device;
          $localStorage._environment = this._environment;
        }

        function setUser(user) {
          this._user = user;
          $localStorage._user = this._user;
        }

        function getEnv() {
          if (!this._environment.env) {
            this._environment.env = $localStorage._environment._env;
          }
          return this._environment.env;
        }

        function getPlatform() {
          if (typeof this._environment.platform === 'undefined') {
            this._environment.platform = $localStorage._environment.platform
          }
          return this._environment.platform;
        }

        function getDevice() {
          if (typeof this._environment.device === 'undefined') {
            this._environment.device = $localStorage._environment.device
          }
          return this._environment.device;
        }

        function inDevelopment() {
          var devEnvs = ['development', 'test', 'debug'];
          if (devEnvs.indexOf(this.getEnv().env) > -1) {
            return true;
          }
          return false;
        }

        function getUser() {
          if (this._user === null) {
            this._user = $localStorage._user;
          }
          var user = new _UserModel(this._user);
          return user;
        }
    }
})();
