(function () {
  'use strict';

  angular
    .module('medsquire.events.offers.viewv2')
    .controller('offersViewV2Controller', offersViewV2Controller);

  /* @ngInject */
  function offersViewV2Controller($q, $state, $log, $sessionStorage, $mdsqre, MdsqreApiOffers, MdsqreApiEventOffers, MdsqreAuth) {

    var vm = this;
    vm.title = 'Oferta';

    vm.offerLoaded = false;

    vm.acceptOffer = acceptOffer;
    vm.authorizeOffer = authorizeOffer;
    vm.cancelOffer = cancelOffer;
    vm.rejectOffer = rejectOffer;

    activate();

    ////////////////

    function activate() {
      backNavigationFix();
      vm.user = MdsqreAuth.getUser();
      fetchOffer($state.params.offerId).then(function (response) {
          vm.offer = response.data;
          vm.showSubjects = (vm.offer.visibility == 'private');
          fetchEvent(vm.offer.proposals[0].proposable_id).then(function (response) {
            vm.event = response.data;
            vm.offerLoaded = true;
          })
        }
      );
    }

    function fetchOffer(offerId) {
      var deferred = $q.defer();
      $mdsqre.requester().offersv2(offerId).view().then(function (response) {
        deferred.resolve(response);
      })
      .catch(function (error) {
        $log.log(error);
        deferred.reject(error);
      });
      return deferred.promise;
    }

    function fetchEvent(eventId) {
      var deferred = $q.defer();
      $mdsqre.requester().events(eventId).view().then(function (response) {
        deferred.resolve(response);
      })
      .catch(function (error) {
        $log.log(error);
        deferred.reject(error);
      });
      return deferred.promise;
    }

    function acceptOffer() {
      $state.go('*.home');
        $mdsqre.requester().offersv2($state.params.offerId).proposals().save({
            proposals: [{
              type: 'attendant_slot', // proposal type
              proposable_type: 'App\\User', // entity type
              proposable_id: vm.user.id, // entity id
              by_user_id: vm.user.id // current logged user (in this case is the same as the entity id)
            }]
        })
        .then(function(proposalResponse) {
            alert('Oferta aceita com sucesso.');
            $state.go('*.home');
        })
        .catch(function(error) {
            alert('Erro ao aceitar proposta.');
        });
    }

    function authorizeOffer(){
      MdsqreApiOffers.authorizeTransaction({transaction_id: vm.offer.transactions[0].id})
        .then(function (eventResponse) {
          console.log(eventResponse);
          $state.go('ssh.home');
        })
        .catch(function (error) {
          $log.log(error);
        });
    }

    function cancelOffer() {
      MdsqreApiEventOffers.remove(vm.offer.id)
        .then(function (eventResponse) {
          console.log(eventResponse);
          $state.go('ssh.home');
        })
        .catch(function (error) {
          $log.log(error);
        });
    }

    function rejectOffer() {

      MdsqreApiEventOffers.reject({
          offer_id: vm.offer.id,
          to_attendant: vm.user.id
        })
        .then(function (eventResponse) {
          console.log(eventResponse);
          $state.go('ssh.home');
        })
        .catch(function (error) {
          $log.log(error);
        });
    }

    function backNavigationFix() {
      if ($state.params.action === 'created_offer') {
        $sessionStorage.customBack = {
          backSteps: -5
        };
      }
    }

  }
})();
