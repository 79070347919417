(function() {
  'use strict';

  angular
    .module('medsquire.interns')
    .config(internsRoutes)
    .run(routeWatcher);

  function internsRoutes($stateProvider) {

    $stateProvider.state('int', {
      url: '/int',
      abstract: true,
      controller: 'InternsController',
      controllerAs: 'vm',
      templateUrl: 'interns/interns-tabs.html',
    });
  }

  /** @ngInject */
  function routeWatcher(MdsqreStateHandler) {
    MdsqreStateHandler.watch();
  }
})();
