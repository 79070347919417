(function() {
  'use strict';

  angular
    .module('medsquire.directives.calendar')
    .directive('medsquireCalendar', medsquireCalendar);

  /* @ngInject */
  function medsquireCalendar($rootScope) {

    var directive = {
      link: link,
      templateUrl: 'shared/directives/calendar/calendar.html',
      restrict: 'E',
      scope: {
        selected: '=selected',
        events: '=events',
        offers: '=offers',
        onEventsChange: '&onEventsChange',
        onMonthChange: '&onMonthChange',
        onMonthSelect: '&onMonthSelect',
        onDaySelect: '&onDaySelect',
      }
    };
    return directive;

    function link(scope, element, attrs) {

      if (scope.events) {
        scope.$watch('events', function(value) {
          // rebuild month when events change
          scope.month = scope.selected.clone();
          var start = scope.selected.clone();
          start.date(1);
          _removeTime(start.day(0));
          _buildMonth(scope, start, scope.month);
          scope.onEventsChange({ month: scope.month });
        });
      }

      if (scope.offers) {
        scope.$watch("offers", function(newValue,oldValue) {
            // rebuild month when offers change
            scope.month = scope.selected.clone();
            var start = scope.selected.clone();
            start.date(1);
            _removeTime(start.day(0));
            _buildMonth(scope, start, scope.month);
        });
      }

      $rootScope.$on('MONTH_CHANGED', function(event, month) {
        scope.month = month.clone();
        var start = month.clone();
        start.date(1);
        _removeTime(start.day(0));
        _buildMonth(scope, start, scope.month);
      })

      scope.current = function() {
        scope.selected = moment();
        scope.onMonthSelect({ month: scope.selected });
      };

      scope.showFullMonth = function() {
        scope.onMonthSelect({ month: scope.selected });
      }

      scope.select = function(day) {
        scope.selected = day.date;
        scope.onDaySelect({ day: scope.selected });
      };

      scope.next = function() {
        var next = scope.month.clone();
        _removeTime(next.month(next.month() + 1).date(1));
        scope.month.month(scope.month.month() + 1);
        _buildMonth(scope, next, scope.month);
        scope.onMonthChange({ month: scope.month });
      };

      scope.previous = function() {
        var previous = scope.month.clone();
        _removeTime(previous.month(previous.month() - 1).date(1));
        scope.month.month(scope.month.month() - 1);
        _buildMonth(scope, previous, scope.month);
        scope.onMonthChange({ month: scope.month });
      };

    }

    function _removeTime(date) {
      return date.day(0).hour(0).minute(0).second(0).millisecond(0);
    }

    function _buildMonth(scope, start, month) {
      scope.weeks = [];
      var done = false;
      var date = start.clone();
      var monthIndex = date.month();
      var count = 0;
      while (!done) {
        scope.weeks.push({ days: _buildWeek(date.clone(), month, scope) });
        date.add(1, "w");
        if (count++ > 2 && monthIndex !== date.month()) {
          done = true;
        }
        monthIndex = date.month();
      }
    }

    function _buildWeek(date, month, scope) {
      var days = [];
      for (var i = 0; i < 7; i++) {
        var dayName = date;
        var day = {
          name: dayName.add({ hours: 3 }).format("dd").substring(0, 1),
          number: date.date(),
          isCurrentMonth: date.month() === month.month(),
          isToday: date.isSame(new Date(moment()), "day"),
          date: date
        };

        if(scope.offers)
          var findEvent = hasOfferEvent(scope, day.date);
        else
          var findEvent = hasEvent(scope, day.date);

        day.hasEvent = findEvent.hasEvent;
        day.hasAlert = findEvent.hasContested || findEvent.hasAction;
        day.hasContested = findEvent.hasContested;
        day.hasAction = findEvent.hasAction;
        day.isPast = day.date < moment();
        days.push(day);
        date = date.clone();
        date.add(1, "d");
      }
      return days;
    }

    function hasEvent(scope, date) {
      var result = { hasEvent: false, hasContested: false, hasAction: false };
      scope.events.forEach(function(event) {
        var eventStartDay = moment(event.start).startOf('day');
        var eventEndDay = moment(event.end).startOf('day');
        var calendarDate = date.startOf('day');
        //if (calendarDate >= eventStartDay && calendarDate <= eventEndDay) {
        if(calendarDate.isSame(new Date(eventStartDay), "day")){
          result.hasEvent = true;
          if (event.isContested()) {
            result.hasContested = true;
          }
          if (event.hasPendingAction()) {
            result.hasAction = true;
          }
        }
      });
      return result;
    }

    function hasOfferEvent(scope, date) {
      var result = { hasEvent: false, hasContested: false, hasAction: false };
      scope.offers.forEach(function(offer) {
        var event = offer.getFromUserProposals()[0].proposable;
        if (event) {
          var eventStartDay = moment(event.start).startOf('day');
          var eventEndDay = moment(event.end).startOf('day');
          var calendarDate = date.startOf('day');
          //if (calendarDate >= eventStartDay && calendarDate <= eventEndDay) {
          if(calendarDate.isSame(new Date(eventStartDay), "day")){
            result.hasEvent = true;
          }
          if (offer.hasPendingAction()) {
            result.hasAction = true;
          }
        }
      });
      return result;
    }

  }
})();
