(function() {
    'use strict';

    angular
        .module('medsquire.directives', [
          'medsquire.directives.calendar',
          'medsquire.directives.ionsearch',
          'medsquire.directives.ion-google-autocomplete',
          'medsquire.directives.medsquire-event-info',
          'medsquire.directives.medsquire-event-details',
          'medsquire.directives.medsquire-event-status',
          'medsquire.directives.medsquire-event-attendants',
          'medsquire.directives.medsquire-event-finances',
          'medsquire.directives.medsquire-offer-info',
          'medsquire.directives.medsquire-offer-info-v2',
          'tabSlideBox',
        ]);
})();
