(function() {
  'use strict';

  angular
    .module('medsquire.interns.home')
    .controller('InternsHomeController', InternsHomeController);

  /* @ngInject */
  function InternsHomeController() {
    var vm = this;
    vm.title = 'MedSquire';

    activate();

    ////////////////

    function activate() {}
  }
})();
