(function() {
    'use strict';

    angular
        .module('medsquire.settings.user', [
          'medsquire.settings.user.personal',
            //'medsquire.settings.user.business',
          // 'medsquire.settings.user.professional',
          // 'medsquire.settings.user.banking',
            //'medsquire.settings.user.documents',
            //'medsquire.settings.user.contractors'
          ]);
})();
