(function () {
  'use strict';

  angular
    .module('medsquire.events.offers.create.step2')
    .controller('OffersCreateStep2Controller', OffersCreateStep2Controller);

  /* @ngInject */
  function OffersCreateStep2Controller($window, $state, $log, $ionicPopup, $mdsqre,
    MdsqreAuth, MdsqreHelpers, OffersCreateService) {

    var vm = this;
    vm.title = 'Confirma?';
    vm.offer = OffersCreateService;
    vm.offerEvent = offerEvent;
    vm.updateSubjects = updateSubjects;

    vm.confirm = confirm;
    vm.cancel = cancel;

    vm.subjects = {};

    activate();

    ////////////////

    function activate() {
      vm.offer.report();
      vm.user = MdsqreAuth.getUser();
      vm.recipients = vm.offer.getRecipients();
      validate();
      vm.offerType = vm.offer.getType();

      //alert(vm.offerType);

      vm.offerTypeLabel = getOfferTypeLabel(vm.offerType);
      vm.event = vm.offer.getEvent();
      vm.eventIds = [];
      vm.eventIds.push(vm.event.id);

      setDefaultSubjects(vm.event);
    }

    function setupRecipients() {
      var recips = [];
      var recipType = "";
      vm.recipients.forEach (
        function (recip) {
          if (recip.name == undefined || recip.name == "group") {
            recipType = "group";
          } else {
            recipType = "user";
          }
          recips.push({
            type: recipType,
            id: recip.id
          });
        })

        return recips;
    }

    function getChosenAttendant() {
        return vm.recipients[0];
    }


    function getOfferTypeLabel(type) {
      switch (type) {
        case 'transfer':
          return 'PASSA';
          break;
        case 'assign':
          if (vm.offer && vm.offer.visibility == 'public') {
            return 'OFERTA PÚBLICA';
          } else {
            return 'OFERTA PRIVADA';
          }
          break;
        case 'exchange':
          return 'TROCA';
          break;
      }
    }

    function validate() {
      if (vm.recipients.length < 1) {
        $ionicPopup.alert({
          title: 'Destinatário não selecionado',
          template: 'É necessário selecionar um destinatário antes de prosseguir'
        });
        $state.go('*.events.offers.create.step1', {
          eventId: $state.params.eventId
        });
        return;
      }
      if (!vm.offer.getEvent().id) {
        $ionicPopup.alert({
          title: 'Evento não selecionado',
          template: 'É necessário selecionar um evento antes de prosseguir'
        });
        $state.go('*.agenda');
        return;
      }
    }

    function setDefaultSubjects(event) {
      /*var attendant = event.subjects.find(function(subject) {
        return subject.pivot.role === 'attendant';
      });*/



      //var recipient = setupRecipients()[0];
      var newAttendant = getChosenAttendant();

      /*var attendant = event.subjects.find(function(subject) {
        return subject.pivot.role === 'attendant';
      });*/
      var monitor = event.subjects.find(function(subject) {
        return subject.pivot.role === 'monitor';
      });
      var auditor = event.subjects.find(function(subject) {
        return subject.pivot.role === 'auditor';
      });
      vm.subjects.attendant = newAttendant;
      vm.subjects.monitor = monitor;
      vm.subjects.auditor = auditor;
    }


    function offerEvent() {

      var recipient = setupRecipients()[0];
      var subjects = [];
      var offerv2 = {};

      subjects.push({ user_id: recipient.id, role: 'to_user' });
      vm.visibility = 'private';

       offerv2 = {
            type: 'assign', // or something else
            visibility: vm.visibility,
            from_user_id: vm.user.id, //MdsqreAuth.getUser().id,
            to_user_id: recipient.id, //vm.selectedAttendant.id,
            proposals: [
              {
                type: 'attendant_slot',
                proposable_type: 'App\\Event',
                proposable_id: vm.event.id,
                by_user_id: vm.user.id //MdsqreAuth.getUser().id
              }
            ],
            subjects: subjects,
            verifications: []
        };

        if (vm.visibility == 'private') {
          offerv2.verifications.push({ type: 'confirm' });
          offerv2.verifications.push({ type: 'authorize' });
        }

        $mdsqre.requester().offersv2().save(offerv2)
              .then(function(offerResponse) {
                vm.offer.report();
                vm.offerid  = offerResponse.data.data.id;
                $state.go('*.events.offers.viewv2', {
                  offerId: vm.offerid,
                  action: 'created_offer'
                });
              })
              .catch(function(error) {
                alert('Não foi possível oferecer a atividade: ' + error.print());
              });
    }



    /*if (MdsqreHelpers.getContext().short == 'pro') {
      if (MdsqreAuth.getUser().hasRole('auditor')) {
        filters.push({ name: 'Contestados', type: 'auditor_contested' });
        filters.push({ name: 'Auditor', type: 'auditor' });
      }
      if (MdsqreAuth.getUser().hasRole('monitor')) {
        filters.push({ name: 'Contestados', type: 'monitor_contested' });
        filters.push({ name: 'Gestor', type: 'monitor' });
      }
      if (MdsqreAuth.getUser().hasRole('auditor:super') || MdsqreAuth.getUser().hasRole('manager')) {
        filters.push({ name: 'Contestados', type: 'super_auditor_contested' });
        filters.push({ name: 'Todos', type: 'super_auditor' });
      }
    }*/




    function updateSubjects() {

          //var recipient = setupRecipients()[0];

          var data = { subjects: [], event_ids: vm.eventIds };
          //ata.subjects.push({ user_id: recipient.id, role: 'attendant' });
          if (vm.subjects.attendant) data.subjects.push({ user_id: vm.subjects.attendant.id, role: 'attendant' });
          if (vm.subjects.monitor) data.subjects.push({ user_id: vm.subjects.monitor.id, role: 'monitor' });
          if (vm.subjects.auditor) data.subjects.push({ user_id: vm.subjects.auditor.id, role: 'auditor' });
          console.log('DATA', data);
          $mdsqre.requester().events().subjects().update(data)
          .then(function(eventResponse) {
            alert('Os atendentes das atividades foram modificados.');
            //$uibModalInstance.close({ reload: true });
          })
          .catch(function(error) {
            $log.error(error);
            alert("Não foi possível modificar os atendentes." + error.print());
          });
    }



    function createOffer() {

        var recipient = setupRecipients()[0];
        var confObj = {};
        var verifications = [];

        if (recipient.type == "user") {
          if (vm.offerType == 'transfer' || vm.offerType == 'assign') {
            verifications = [{ type: 'confirm'}];
          }

          confObj = {
            type: vm.offerType, //exchange, transfer, assign
            from_user_id: vm.user.id,
            to_user_id: recipient.id,
            additional_info: { comment: vm.comment },
            visibility: 'private',
            verifications: verifications,
            proposals: [{
              type: 'attendant_slot',
              proposable_type: 'App\\Event',
              proposable_id: vm.event.id,
              by_user_id: vm.user.id
            }],
            subjects: [
              { user_id: recipient.id, role: 'to_user' }
          ]}
        }

        if (recipient.type === "group"){
          confObj = {
            type: vm.offerType,
            from_user_id: vm.user.id,
            additional_info: { comment: vm.comment },
            visibility: 'public',//'protected',
            proposals: [{
              type: 'attendant_slot',
              proposable_type: 'App\\Event',
              proposable_id: vm.event.id,
              by_user_id: vm.user.id
            }]
          }
        }

        $mdsqre.requester().offersv2().save(
          confObj
        )
        .then(function(response) {
          vm.offer.report();
          vm.offerid  = response.data.data.id;
          $state.go('*.events.offers.viewv2', {
            offerId: vm.offerid,
            action: 'created_offer'
          });
        })
        .catch(function (error) {
          $ionicPopup.alert({
            title: 'Aconteceu algo errado...',
            template: 'Erro: ' + error.print()
          });
          $state.go('*.events.view', {
            eventId: vm.offer.getEvent().id
          });
        })
        .finally(function () {
          vm.offer.reset();
        });
    }

    function confirm() {
      /*if(vm.offer.event.offersv2.length > 0)
        counteroffer();
      else*/
        createOffer();
        // offerEvent();
    }

    function cancel() {
      $state.go('*.events.view', {
        eventId: vm.offer.getEvent().id,
        action: 'canceled_offer'
      });
      vm.offer.reset();
    }

    /*function shareOnWhatsApp() {

        var context = MdsqreHelpers.getContext();
        var splitUrl = $window.location.href.split("/");
        var domain = splitUrl[0] + "//" + splitUrl[2]

        var offerVerb = 'TROCO';
        var offerInfinitive = 'TROCAR';
        if (vm.offerType == 'transfer') {
          offerVerb = 'PASSO';
          offerInfinitive = 'PASSAR';
        }
        if(domain.includes('localhost')){
            domain = 'https://app-pro.medsquire.com';
        }
        //http://localhost:8100/#/pro/home?route=pro.events.offers.view&param=offerId&value=undefined
        var anoInicio = vm.event.getStart().format('YYYY');
        var mesInicio = vm.event.getStart().format('MM');
        var diaInicio = vm.event.getStart().format('DD');
        var horarioInicio = vm.event.getStart().format('HH:mm');
        var anoFim = vm.event.getEnd().format('YYYY');
        var mesFim = vm.event.getEnd().format('MM');
        var diaFim = vm.event.getEnd().format('DD');
        var horarioFim = vm.event.getEnd().format('HH:mm');
        var valorhora = parseFloat(vm.event.service.summary.price).toFixed(2);
        var quantidade = parseInt(vm.event.service.summary.qty);
        var total = quantidade * valorhora;

        var fone = vm.recipients[0].contact.phone1;
        if(fone.length <= 11)
        {
          fone = "55" + fone;
        }

        //var offerUrl = 'https://app-pro.medsquire.com/#/pro/home&phone=' + fone;
        var linkstring = encodeURIComponent(domain + '/#/'  + context.short + '/home') + '&phone=' + fone;

        //fone = "5551984451325";

        var apiurlstring = 'https://api.whatsapp.com/send?text=*' + offerVerb + ' Plantão*: CLÍNICO%20%0A' +
        '--------------------------%0A*Quando?* ' +  diaInicio+'/'+mesInicio+'/'+anoInicio +
        ' ' + horarioInicio + 'h -' + horarioFim + 'h ' +
        '%0A*Onde?* ' + vm.event.location.name + ' ' +
        '%0A*Quanto* R$' + total + '(' + valorhora + '/h) ' +
        '%0A*Quem paga?* ' + 'Med5' + ' em ' + diaFim + '/' + mesFim + '/' + anoFim +
        '%0A--------------------------%0A*LINK:*' +  linkstring;//,'_blank';

        //location.href = apiurlstring;
        window.open(apiurlstring, '_blank');
        console.log(location.href);
    }*/


    /*function offerEvent() {
        var recipient = setupRecipients()[0];
        var subjects = [];
        subjects.push({ user_id: recipient.id, role: 'to_user' });

        //if (!event) {
        //  alert('Problema ao carregar evento no modal');
        //  return;
        //}

        var offerv2 = {
            type: 'assign', // or something else
            visibility: vm.visibility,
            from_user_id: vm.user.id, //MdsqreAuth.getUser().id,
            to_user_id: recipient.id, //vm.selectedAttendant.id,
            proposals: [
              {
                type: 'attendant_slot',
                proposable_type: 'App\\Event',
                proposable_id: event.id,
                by_user_id: vm.user.id //MdsqreAuth.getUser().id
              }
            ],
            subjects: subjects,
            verifications: []
        };

        if (vm.visibility == 'private') {
          offerv2.verifications.push({ type: 'confirm' });
          offerv2.verifications.push({ type: 'authorize' });
        }
  ​
        $mdsqre.requester().offersv2().save(offerv2)
        .then(function(offerResponse) {
          vm.offer.report();
          vm.offerid  = response.data.data.id;
          $state.go('*.events.offers.viewv2', {
            offerId: vm.offerid,
            action: 'created_offer'
          });
        })
        .catch(function(error) {
          //alert('Não foi possível oferecer a atividade: ' + error.print());
        });
    }*/



  }
})();
