(function () {
  'use strict';

  angular
    .module('medsquire.directives.medsquire-event-status')
    .directive('medsquireEventStatus', medsquireEventStatus);

  function medsquireEventStatus($state, $log, $q, $mdsqre, MdsqreHelpers, MdsqreAuth, PresenceCheckoutService, $ionicModal, $ionicPopup) {

    var directive = {
      link: link,
      restrict: 'E',
      scope: {
        event: '=',
        user: '=?',
        onChange: '&onChange',
      },
      templateUrl: 'shared/directives/event-view/event-status/medsquire-event-status.html'
    };
    return directive;

    function link(scope, element, attrs) {

      scope.context = MdsqreHelpers.getContext();

      scope.trimCommentAt = 150;

      scope.checkinClicked = checkinClicked;
      scope.checkoutClicked = checkoutClicked;
      scope.MdsqreHelpers = MdsqreHelpers;
      scope.showFullComment = showFullComment;
      scope.openModal = openModal;
      scope.closeModal = closeModal;
      scope.commentsClicked = commentsClicked;
      scope.timeTocheckout = timeTocheckout;

      scope.getRating = getRating;
      scope.getAttendantRatings = getAttendantRatings;






      scope.attendantSelfRating = scope.event.getRating('attendant','event.attendant.evaluation_to_attendant');
      scope.attendantActivityRating = scope.event.getRating('attendant','event.attendant.evaluation_to_event');
      scope.attendantAuditorRating = scope.event.getRating('attendant','event.attendant.evaluation_to_auditor');
      scope.attendantAvaliatorRating = scope.event.getRating('attendant','event.attendant.evaluation_to_auditor');
      scope.auditorAttendantRating = scope.event.getRating('auditor','event.auditor.evaluation_to_attendant');
      scope.auditorActivityRating = scope.event.getRating('auditor','event.auditor.evaluation_to_event');
      scope.monitorAttendantRating = scope.event.getRating('monitor','event.auditor.evaluation_to_attendant');
      scope.monitorActivityRating = scope.event.getRating('monitor','event.auditor.evaluation_to_event');
      scope.canViewAttendantRatings = scope.event.canViewAttendantRatings();
      scope.canViewAuditorRatings = scope.event.canViewAuditorRatings();
      scope.canViewMonitorRatings = scope.event.canViewMonitorRatings();
      scope.canViewCheckoutTime = scope.event.canViewCheckoutTime();
      scope.canViewCheckoutComment = scope.event.canViewCheckoutComment();
      scope.canViewValidationComment = scope.event.canViewValidationComment();
      scope.canViewCheckinTime = scope.canViewCheckoutTime;
      scope.isValidated = scope.event.eventable.isValidated();
      scope.attendantLabel = MdsqreHelpers.getLabel('attendant', true);
      scope.attendantNames = scope.event.getAttendants(true);
      scope.evaluatorName = scope.event.monitorName;
      scope.showWaitingValidationMsg = MdsqreHelpers.getContext().short == 'pro' && scope.event.hasCheckout() && !scope.event.hasConfirmedPresence() && !scope.event.isContested() && ( scope.event.isMyRole('attendant') || scope.event.isMyRole('monitor') );

      $ionicModal.fromTemplateUrl('comment-modal.html', {
        scope: scope,
        animation: 'slide-in-up'
      }).then(function (modal) {
        scope.commentModal = modal;
      });

      scope.$watch('event', function (value) {
        scope.event = value;
      });

      activate();

      function activate() {
        checkOffer();
        scope.contestation = scope.event.getActiveContestation();
        if (scope.contestation) {
          setContestationStrings();
        }

        if (scope.event.canViewComments()) {
          fetchLatestComment($state.params.eventId).then(function() {
            if (scope.event.eventable.isValidated() && !scope.latestComment) {
              scope.canReallyViewComments = false;
            } else {
              scope.canReallyViewComments = scope.event.canViewComments();
            }
          });
        }

        scope.canEvaluate = scope.event.canEvaluate();
      }

      function timeTocheckout(){
        var now = moment();
        var finish = moment(scope.event.end,"YYYY/MM/DD hh:mm:ss");
        if(now.isAfter( moment(finish).add(1,"hours"))){
          return true;
        }
        else {
            return false;
        }
      }

      function checkinClicked() {
        scope.onChange({
          type: 'checkin'
        });
      }

      function checkoutClicked() {
        PresenceCheckoutService.setEvent(scope.event);
        if (scope.context.short == "uni" || scope.context.short == "int") {
          $state.go('*.events.checkout.step1', {
            eventId: $state.params.eventId
          });
        } else if (scope.context.short == "pro") {
          $state.go('*.events.checkout.step3', {
            eventId: $state.params.eventId
          });
        }
      }

      function commentsClicked() {
        // if (scope.event.eventable.state == 'validated') {
        //   $ionicPopup.alert({
        //     title: 'Comentar',
        //     template: "Não é possível comentar em atividades validadas"
        //   });
        //   return;
        // }

        $state.go('*.events.comments', {
          eventId: $state.params.eventId
        });
      }

      function showFullComment() {
        if (scope.event.getPresence('checkout').additional_info.obs.length > scope.trimCommentAt) {
          scope.commentModal.show();
        }
      };

      function openModal() {
        if (scope.canViewAttendantRatings) {
          scope.commentModal.show();
        }
      };

      function closeModal() {
        scope.commentModal.hide();
      };

      scope.$on('$destroy', function () {
        scope.commentModal.remove();
      });
      scope.$on('modal.hidden', function () {});
      scope.$on('modal.removed', function () {});

      function checkOffer() {
        scope.showOfferStatus = scope.event.hasPendingOffersV2();
        if (scope.showOfferStatus) {
          scope.offer = scope.event.getPendingOffersV2()[0];
          switch (scope.offer.type) {

            case 'exchange':
              if (scope.offer.belongsToMe()) {
                if (scope.offer.getFromUserProposals()[0].proposable_id == scope.event.id) {
                  scope.offerStatusTitle = 'Você ofereceu para trocar';
                } else {
                  scope.offerStatusTitle = scope.offer.from_user.name + ' ofereceu em uma troca';
                }
              } else {
                if (scope.offer.getFromUserProposals()[0].proposable_id == scope.event.id) {
                  scope.offerStatusTitle = scope.offer.from_user.name + ' ofereceu para trocar';
                } else {
                  scope.offerStatusTitle = 'você ofereceu em uma troca';
                }
              }
              scope.offerStatusText = 'Confira os detalhes da Oferta';
              scope.offerStatusIcon = 'ico-in_exchange';
              break;

            case 'transfer':
              if (scope.offer.belongsToMe()) {
                scope.offerStatusTitle = 'Você ofereceu para passar';
              } else {
                scope.offerStatusTitle = scope.offer.from_user.name + ' ofereceu para passar';
              }
              scope.offerStatusText = 'Confira os detalhes da Oferta';
              scope.offerStatusIcon = 'ico-in_transfer';
              break;

            case 'assign':
              if (scope.offer.belongsToMe()) {
                scope.offerStatusTitle = 'Você ofereceu esta atividade';
              } else {
                scope.offerStatusTitle = scope.offer.from_user.name + ' ofereceu esta atividade';
              }
              scope.offerStatusText = 'Confira os detalhes da Oferta';
              scope.offerStatusIcon = 'ico-in_transfer';
              break;
          }
        }
      }

      function fetchLatestComment(eventId) {
        var deferred = $q.defer();
        $mdsqre.requester().events(eventId).comments().list()
          .then(function (commentsResponse) {
            $log.log('COMMENTS', commentsResponse);
            if (commentsResponse.data) {
              scope.latestComment = commentsResponse.data.slice(-1).pop();
            }
            deferred.resolve(commentsResponse);
          })
          .catch(function (err) {
            $log.log('ERROR', err);
          });
        return deferred.promise;
      }

      function setContestationStrings() {
        if (scope.contestation.proposal_at) {
          if (scope.contestation.isMyRole('attendant')) {
            scope.contestationTitle = 'CONTESTADO';
            scope.contestationDescription = 'Foi feita uma proposta de solução. Avalie e responda.';
            var remainingTime = scope.contestation.getRemainingTime();
            scope.contestationTimer = remainingTime;
          } else if (scope.user.hasRole('auditor:super')) {
            scope.contestationTitle = 'CONTESTADO';
            scope.contestationDescription = 'Foi feita uma proposta de solução. Aguarde a resposta.';
          }
        } else {
          if (scope.contestation.isMyRole('attendant')) {
            scope.contestationTitle = 'CONTESTADO';
            scope.contestationDescription = 'Aguarde uma proposta de solução.';
          } else if (scope.user.hasRole('auditor:super')) {
            scope.contestationTitle = 'CONTESTADO';
            scope.contestationDescription = 'Faça uma proposta de solução.';
            var remainingTime = scope.contestation.getRemainingTime();
            scope.contestationTimer = remainingTime;
          }
        }

        //### HACK! ###
        if (scope.contestation.additional_info && scope.contestation.additional_info.system) {
          scope.contestation.creator.name = 'Sistema';
        }
      }




      function getRating(creator, type) {
        var ratings = null;
        switch (creator) {
          case 'attendant':
            ratings = getAttendantRatings();
            break;
          case 'monitor':
            ratings = getMonitorRatings();
            break;
          case 'auditor':
            ratings = getAuditorRatings();
            break;
        }
        if (!ratings || !ratings.length) {
          return null;
        }
        var ratingFound = ratings.filter(function (rating) {
          return rating.type == type;
        });

        if (!ratingFound[0]) {
          return null;
        }

        return ratingFound[0].rate;
      }

      function getAttendantRatings () {
        // todo: warning, could have many attendants. should by filtered
        // by id argument eventually instead of index 0.
        var attendant = scope.event.getAttendants()[0];
        if (!attendant) return [];
        var ratings = scope.event.ratings.filter(function (rating) {
          return rating.created_by == attendant.id;
        });

        return ratings;
      };

      function getMonitorRatings () {
        // todo: warning, could have many monitors. should by filtered
        // by id argument eventually instead of index 0.
        var monitor = scope.event.getMonitors()[0];
        if (!monitor) return [];
        var ratings = scope.event.ratings.filter(function (rating) {
          return rating.created_by == monitor.id;
        });

        return ratings;
      };

      function getAuditorRatings () {
        // todo: warning, could have many auditors. should by filtered
        // by id argument eventually instead of index 0.
        var auditor = scope.event.getAuditors()[0];
        if (!auditor) return [];
        var ratings = scope.event.ratings.filter(function (rating) {
          return rating.created_by == auditor.id;
        });

        return ratings;
      };



    }
  }
})();
