(function() {
  'use strict';

  angular
    .module('medsquire.auth.magiclink')
    .controller('AuthMagiclinkController', AuthMagiclinkController);

  /* @ngInject */
  function AuthMagiclinkController($state, MdsqreAuth, $ionicPopup, $log, $sessionStorage, blockUI) {
    var vm = this;
    vm.title = 'MagiclinkAuthenticateController';

    activate();

    ////////////////

    function activate() {
      var authToken = $sessionStorage.url_params.auth_token;
      var userId = $sessionStorage.url_params.user_id;
      if (!authToken || !userId) {
        $state.go('login');
        return;
      }
      authenticate(authToken, userId);
    }

    function authenticate(authToken, userId) {
      blockUI.start({ message: 'Autenticando...' });
      MdsqreAuth.loginWithMagicLink(authToken, userId)
        .then(function(response) {
          $state.go('*.home');
        })
        .catch(function(error) {
          $ionicPopup.alert({
            title: 'Link inválido/vencido.',
            template: 'Faça login com sua senha.'
          });
          $state.go('login');
        })
        .finally(function() {
          $sessionStorage.url_params.auth_token = null;
          $sessionStorage.url_params.user_id = null;
          blockUI.stop();
        });
    }
  }
})();
