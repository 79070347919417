(function() {
  'use strict';

  var loadModules = [

    ///////////////////////////////////////////////////
    // CORE MODULES: DO NOT CHANGE THE LOADING ORDER //
    ///////////////////////////////////////////////////
    'ionic',
    'environment',
    'pascalprecht.translate',
    'ngCordova',
    'ngStorage',
    'ngCookies',
    'ngQueue',
    'blockUI',
    'ng-mdsqre',
    'ionic.rating',
    'ngFileUpload',

    'medsquire.core',
    'medsquire.services',
    'medsquire.auth',
    'medsquire.queue',
    'medsquire.models',
    ///////////////////////////////////////////////////
    // CORE MODULES: DO NOT CHANGE THE LOADING ORDER //
    ///////////////////////////////////////////////////

    // Add application new modules here
    'medsquire.directives',
    'medsquire.settings',
    'medsquire.medics',
    'medsquire.events',
    'medsquire.chat',
    'medsquire.user',
    'medsquire.address',
    'ui.mask'

    // add your modules here


  ];

  if (window._Medsquire.context === 'professional') loadModules.push('medsquire.pro');
  if (window._Medsquire.context === 'interns') loadModules.push('medsquire.interns'); // should be short
  if (window._Medsquire.context === 'university') loadModules.push('medsquire.uni');

  angular
    .module('medsquire', loadModules);
})();
