(function() {
  'use strict';

  angular
    .module('medsquire.auth', [
      'medsquire.auth.login',
      'medsquire.auth.magiclink',
      'medsquire.auth.confirm',
    ]);
})();
